import React, { useEffect, useReducer } from 'react'
import { CommentContext } from './commentContext'
import { commentReducer, initialCommentState } from './commentReducer'
import { CommentThread } from '../../types/comments'
import { fetchThreads } from '../../api/comments'
import { useMapContext } from '../map/mapContext'
import { useSupabaseContext } from '../supabase/supabaseContext'
import * as Sentry from '@sentry/react'
import { LngLat } from 'react-map-gl'

type CommentProviderProps = {
    children: React.ReactNode
}

const CommentProvider = ({ children }: CommentProviderProps) => {
    const mapContext = useMapContext()
    const [state, dispatch] = useReducer(commentReducer, initialCommentState)
    const { client } = useSupabaseContext()

    // Load initial threads
    useEffect(() => {
        const mapId = mapContext.state.current?.metadata?.id
        // reset the state when changing maps
        if (state.mapId !== mapId) {
            dispatch({ type: 'SET_MAP_ID', mapId })
        }

        const loadThreads = async () => {
            const threads = await fetchThreads(mapId, client)
            dispatch({ type: 'SET_THREADS', threads })
        }

        loadThreads().catch((error) => {
            Sentry.captureException(error)
        })

        // Set up polling interval
        const pollInterval = setInterval(loadThreads, 10_000)

        return () => clearInterval(pollInterval)
    }, [mapContext.state.current?.metadata?.id])

    const selectThread = (threadId: string | null) => {
        dispatch({ type: 'SET_SELECTED_THREAD', threadId })
    }

    const addThread = (thread: CommentThread) => {
        dispatch({ type: 'ADD_THREAD', thread })
    }

    const updateThread = (thread: CommentThread) => {
        dispatch({ type: 'UPDATE_THREAD', thread })
    }

    const toggleCommentMode = () => {
        dispatch({ type: 'TOGGLE_COMMENT_MODE' })
    }

    const setCommentMode = (isCommentMode: boolean) => {
        dispatch({ type: 'SET_COMMENT_MODE', isCommentMode })
    }

    const openNewThread = (location: LngLat) => {
        dispatch({
            type: 'SET_DRAFT_THREAD',
            draft: { location, title: '', comment: '' },
        })
    }

    return (
        <CommentContext.Provider
            value={{
                state,
                dispatch,
                selectThread,
                addThread,
                updateThread,
                toggleCommentMode,
                setCommentMode,
                openNewThread: openNewThread,
            }}
        >
            {children}
        </CommentContext.Provider>
    )
}

export default CommentProvider
