import { Dataset } from 'types/dataset'

export function boundsStringToArray(
    boundsString: string
): [number, number, number, number] {
    if (!boundsString) {
        // Return whole globe bounds if null
        return [-180, -90, 180, 90]
    }
    // Bounds is a postgres string in the form of
    // 'BOX(xmin ymin, xmax ymax)'
    const parsedBounds = boundsString
        .replace('BOX(', '')
        .replace(')', '')
        .replace(',', ' ')
        .split(' ')
        .map(parseFloat)
    if (parsedBounds.length !== 4) {
        throw new Error('Invalid bounds string: ' + boundsString)
    }
    return parsedBounds as [number, number, number, number]
}

export function formatDate(date: Date): string {
    const hasTime =
        date.getUTCHours() !== 0 ||
        date.getUTCMinutes() !== 0 ||
        date.getUTCSeconds() !== 0
    if (hasTime) {
        return `${date.toISOString().split('T')[0]} ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`
    }
    return date.toISOString().split('T')[0]
}

export function formatDateTimeForDisplay(date: Date) {
    let locale = 'en-US'
    if (typeof navigator !== 'undefined' && navigator.language) {
        locale = navigator.language
    }

    const formatter = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    })

    return formatter.format(date)
}

export function filterDatasetsInView(
    datasets: Dataset[],
    bounds: [number, number, number, number]
) {
    return datasets.filter((dataset) => {
        const datasetBounds = dataset?.bounds
        if (!datasetBounds) return true
        const boundsString = boundsStringToArray(datasetBounds)
        // If dataset has no bounds, include it
        if (!boundsString) return true

        const [west, south, east, north] = boundsString
        const [viewWest, viewSouth, viewEast, viewNorth] = bounds

        // Check if bounds intersect
        // One box is to the left of the other
        if (east < viewWest || viewEast < west) return false

        // One box is above the other
        if (south > viewNorth || viewSouth > north) return false

        // Bounds must intersect
        return true
    })
}

export function isFastTiler(dataset: Dataset) {
    return (
        dataset.cacheKey != null &&
        dataset.rasterOverviewsStartZoom != null &&
        (dataset.vizType === 'continuous_singleband_raster' ||
            dataset.vizType === 'continuous_multiband_raster' ||
            dataset.vizType === 'binned_raster')
    )
}

const formatArray = (arr: string[] | undefined) => {
    if (!arr || arr.length <= 1) return JSON.stringify(arr)
    return (
        '[\n        ' +
        arr.map((item) => JSON.stringify(item)).join(',\n        ') +
        ',\n    ]'
    )
}

export function generateCodeForDefinition(dataset: Dataset): string {
    const definition = dataset.definition
    const className = dataset.className

    if (className === 'ZarrDataset') {
        const parts = []
        parts.push(`    store="${definition.store}"`)

        if (definition.rename) {
            parts.push(`    rename=${JSON.stringify(definition.rename)}`)
        }

        if (definition.kw_args && Object.keys(definition.kw_args).length > 0) {
            parts.push(`    **${JSON.stringify(definition.kw_args)}`)
        }

        return `from earthscale.datasets.raster import ZarrDataset

dataset = ZarrDataset(
${parts.join(',\n')}
)`
    }

    if (className === 'ImageDataset') {
        const bands = definition.bands as string[]
        const parts = []
        parts.push(
            `    glob_url=${Array.isArray(definition.glob_url) ? formatArray(definition.glob_url) : JSON.stringify(definition.glob_url)}`
        )

        if (definition.bands) {
            parts.push(`    bands=${formatArray(bands)}`)
        }

        if (definition.groupby) {
            parts.push(`    groupby="${definition.groupby}"`)
        }

        if (definition.filename_date_pattern) {
            parts.push(
                `    filename_date_pattern="${definition.filename_date_pattern}"`
            )
        }

        if (definition.filename_band_pattern) {
            parts.push(
                `    filename_band_pattern=${JSON.stringify(definition.filename_band_pattern)}`
            )
        }

        if (definition.kw_args && Object.keys(definition.kw_args).length > 0) {
            parts.push(`    **${JSON.stringify(definition.kw_args)}`)
        }

        return `from earthscale.datasets.raster import ImageDataset

dataset = ImageDataset(
${parts.join(',\n')}
)`
    }

    if (className === 'STACDataset') {
        const bands = definition.bands as string[]
        const parts = []
        parts.push(`    items=${JSON.stringify(definition.items)}`)

        if (definition.bands) {
            parts.push(`    bands=${formatArray(bands)}`)
        }

        if (definition.groupby) {
            parts.push(`    groupby="${definition.groupby}"`)
        }

        if (definition.kw_args && Object.keys(definition.kw_args).length > 0) {
            parts.push(`    **${JSON.stringify(definition.kw_args)}`)
        }

        return `from earthscale.datasets.raster import STACDataset

dataset = STACDataset(
${parts.join(',\n')}
)`
    }

    if (className === 'VectorDataset') {
        const parts = []

        if (definition.url) {
            parts.push(`    url="${definition.url}"`)
        }

        if (definition.kw_args && Object.keys(definition.kw_args).length > 0) {
            parts.push(`    **${JSON.stringify(definition.kw_args)}`)
        }

        return `from earthscale.datasets import VectorDataset

dataset = VectorDataset(
${parts.join(',\n')}
)`
    }

    return '# Dataset definition not available'
}

export function isTextInputActive(): boolean {
    // Check if we're in a browser environment
    if (typeof document === 'undefined') return false

    const activeElement = document.activeElement
    if (!activeElement) return false

    return (
        // Basic form elements
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement ||
        activeElement instanceof HTMLSelectElement ||
        // Contenteditable elements
        activeElement.hasAttribute('contenteditable') ||
        // Specific input types
        (activeElement instanceof HTMLInputElement &&
            (activeElement.type === 'text' ||
                activeElement.type === 'number' ||
                activeElement.type === 'password' ||
                activeElement.type === 'email' ||
                activeElement.type === 'search' ||
                activeElement.type === 'tel' ||
                activeElement.type === 'url')) ||
        // ARIA textbox role
        activeElement.getAttribute('role') === 'textbox'
    )
}
