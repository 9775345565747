import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import * as React from 'react'
import { Dataset } from '../../types/dataset'
import { useMapContext } from '../../context/map/mapContext'

type DimensionSelectorProps = {
    dataset: Dataset
    dimension: {
        name: string
        values: any[]
    }
}

function DimensionSelector({ dataset, dimension }: DimensionSelectorProps) {
    const { dispatch } = useMapContext()

    const handleChange = (event: any) => {
        dispatch({
            type: 'SET_SELECTED_DIMENSIONS',
            datasetVersionId: dataset.id,
            dimensions: {
                ...dataset.selectedDimensions,
                [dimension.name]: event.target.value,
            },
        })
    }

    return (
        <FormControl
            size="small"
            sx={{
                marginBottom: '10px',
                marginTop: '10px',
                width: '100%',
                maxWidth: '260px',
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <InputLabel
                id={`${dimension.name}-label`}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {dimension.name.charAt(0).toUpperCase() +
                    dimension.name.slice(1)}
            </InputLabel>
            <Select
                labelId={`${dimension.name}-label`}
                value={dataset.selectedDimensions[dimension.name] ?? ''}
                label={dimension.name}
                onChange={(e) => {
                    e.stopPropagation()
                    handleChange(e)
                }}
                onClick={(e) => e.stopPropagation()}
                sx={{
                    '& fieldset': {
                        borderColor: 'primary.main',
                        marginRight: '6px',
                    },
                    '& .MuiSelect-select': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                }}
            >
                {dimension.values.map((value, index) => (
                    <MenuItem
                        key={index}
                        value={index}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {value instanceof Date
                            ? value.toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              }).replace(',', '')
                            : value.toString()}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default DimensionSelector
