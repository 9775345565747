import {
    DatasetClass,
    SUPPORTED_VECTOR_EXTENSIONS,
    SUPPORTED_IMAGE_EXTENSIONS,
    SUPPORTED_ZARR_EXTENSIONS,
} from '../types/datasetRegistration'

export function inferDatasetClass(url: string): DatasetClass | null {
    const extension = url.split('.').pop()?.toLowerCase()
    if (!extension) return null

    if (SUPPORTED_ZARR_EXTENSIONS.includes(`.${extension}`)) {
        return 'ZarrDataset'
    }

    if (SUPPORTED_IMAGE_EXTENSIONS.includes(`.${extension}`)) {
        return 'ImageDataset'
    }

    if (SUPPORTED_VECTOR_EXTENSIONS.includes(`.${extension}`)) {
        return 'VectorDataset'
    }

    return null
}
