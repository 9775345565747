import { Dataset } from '../../types/dataset'
import { LocalDataset } from 'types/localDataset'
import * as Sentry from '@sentry/react'
import {
    addLocalDataset,
    convertLocalDatasetToDataset,
    lookupGeoJsonCrs,
    reprojectGeoJson,
} from '../../api/localDataset'
import { filterDatasetsInView } from '../../utils'


function filterDatasets(
    datasets: Dataset[], searchTerm: string, filterToView: boolean, viewportBounds: [number, number, number, number]
) {
    let filtered_datasets = datasets
    if (searchTerm != '') {
        filtered_datasets = filtered_datasets.filter((dataset) =>
            dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }

    if (filterToView) {
        filtered_datasets = filterDatasetsInView(filtered_datasets, viewportBounds)
    }

    return filtered_datasets
}

function sortCatalogDatasets(datasets: Dataset[]) {
    return [...datasets].sort((a, b) => {
        if (
            a.status === 'processing_failed' &&
            b.status !== 'processing_failed'
        ) {
            return -1
        }
        if (
            a.status !== 'processing_failed' &&
            b.status === 'processing_failed'
        ) {
            return 1
        }
        if (a.status === 'processing' && b.status !== 'processing') {
            return -1
        }
        if (a.status !== 'processing' && b.status === 'processing') {
            return 1
        }
        if (a.status === "not_started" && b.status !== "not_started") {
            return -1
        }
        if (a.status !== "not_started" && b.status === "not_started") {
            return 1
        }
        return a.name.localeCompare(b.name)
    })
}

const handleFileDropAsync = (
    state, backendClient, changeMap, setError, trackUploadLocalGeojson, file: File
) => {
    const reader = new FileReader()
    reader.onload = async (event: ProgressEvent<FileReader>) => {
        try {
            if (file.size > 10 * 1024 * 1024) {
                const fileSizeInMb = file.size / (1024 * 1024)
                throw new Error(
                    `File ${file.name} too large, the maximum file size we're supporting is ` +
                        `10mb. The file has ${fileSizeInMb.toFixed(2)}mb.`
                )
            }

            const filename = file.name
            const mapId = state.current.metadata.id
            try {
                const content = JSON.parse(event.target.result as string)
                if (content.name) {
                    delete content.name
                }
                const crsProj4String = await lookupGeoJsonCrs(
                    content,
                    backendClient
                )
                const reprojectedContent = reprojectGeoJson(
                    content,
                    crsProj4String
                )
                if (reprojectedContent.crs) {
                    delete reprojectedContent.crs
                }
                const localDataset: LocalDataset = {
                    mapId: mapId,
                    filename: filename,
                    createdAt: new Date(file.lastModified).toISOString(),
                    geometry: reprojectedContent,
                }
                convertLocalDatasetToDataset(localDataset)
                addLocalDataset(localDataset)
                trackUploadLocalGeojson()
            } catch (error) {
                Sentry.captureException(error)
                throw new Error('The file might not be valid geojson.')
            }
            changeMap(mapId)
        } catch (error) {
            Sentry.captureException(error)
            setError(
                `Failed to add file ${file.name}, error: ${error.message}`
            )
        }
    }
    reader.readAsText(file)
}

const isLatLngString = (text: string): [number, number] | null => {
    // Match formats like "12.345, -67.890" or "12.345,-67.890" or "12.345 -67.890"
    const matches = text.match(/^(-?\d+\.?\d*)[,\s]+(-?\d+\.?\d*)$/)
    if (!matches) return null

    const lat = parseFloat(matches[1])
    const lng = parseFloat(matches[2])

    // Validate coordinates are in valid ranges
    if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
        return [lat, lng]
    }
    return null
}

export { sortCatalogDatasets, handleFileDropAsync, filterDatasets, isLatLngString }
