import React, { useState } from 'react'
import {
    Menu,
    MenuItem,
    Button,
    Typography,
    Stack,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
} from '@mui/material'
import { MapMetadata } from '../../types/map'
import { useMapContext } from '../../context/map/mapContext'
import { fetchMapsMetadata } from '../../api/map'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import Box from '@mui/material/Box'
import Lottie from 'react-lottie-player'
import * as loadingSpinner from '../../assets/loadingSpinnner/white-on-transparent.json'
import { Add, Delete } from '@mui/icons-material'
import * as Sentry from '@sentry/react'
import SearchBar from '../SearchBar/SearchBar'
import ErrorDialog from '../ErrorDialog/ErrorDialog'

interface MapSelectorProps {
    anchorEl: HTMLElement | null
    setAnchorEl: (anchorEl: HTMLElement | null) => void
}

const MapSelector: React.FC<MapSelectorProps> = ({ anchorEl, setAnchorEl }) => {
    const { client, logout } = useSupabaseContext()
    const [allMaps, setAllMaps] = useState<MapMetadata[]>([])
    const [mapToDelete, setMapToDelete] = useState<MapMetadata | null>(null)
    const [isDefaultMapErrorMessage, setIsDefaultMapErrorMessage] =
        useState<boolean>(false)
    const [errorDialogOpen, setErrorDialogOpen] = useState(false)
    const { createNewMap, changeMap, deleteMap } = useMapContext()
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredMaps, setFilteredMaps] = useState<MapMetadata[]>([])
    const sortedMaps = allMaps.sort((a, b) => a.name.localeCompare(b.name))

    // Update filtered maps when search term or all maps change
    React.useEffect(() => {
        const filtered = sortedMaps.filter((map) =>
            map.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredMaps(filtered)
    }, [searchTerm, sortedMaps])

    // Fetch maps
    React.useEffect(() => {
        if (allMaps.length == 0) {
            fetchMapsMetadata(client)
                .then((data) => {
                    setAllMaps(data)
                })
                .catch((e) => {
                    Sentry.captureException(e)
                    setErrorDialogOpen(true)
                })
        }
    })

    const handleClose = (map?: MapMetadata) => {
        setAnchorEl(null)
        if (map) {
            changeMap(map.id)
        }
    }

    const handleMapDelete = async (map: MapMetadata) => {
        if (!map) {
            return
        }
        try {
            await deleteMap(map.id)
            setAllMaps(allMaps.filter((m) => m.id !== map.id))
            setMapToDelete(null)
        } catch (e) {
            if (e.code == '23503') {
                setIsDefaultMapErrorMessage(true)
            } else {
                throw e
            }
        }
    }

    return (
        <>
            <ErrorDialog
                open={errorDialogOpen}
                message="Sorry, we are having trouble loading your maps. Please log in and try again."
                onClose={() => setErrorDialogOpen(false)}
                onLogout={logout}
            />
            {mapToDelete && (
                <Dialog
                    open={true}
                    onClose={() => handleMapDelete(mapToDelete)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Delete Map "{mapToDelete.name}"?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to permanently delete the map
                            "{mapToDelete.name}"? This action cannot be undone.
                            {isDefaultMapErrorMessage && (
                                <Typography color="error" component={'span'}>
                                    <br />
                                    <br />
                                    This map is the default map of a user and
                                    cannot be deleted.
                                </Typography>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setMapToDelete(null)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleMapDelete(mapToDelete)
                            }}
                            autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Menu
                id="map-selector-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    handleClose()
                    setAllMaps([])
                    setSearchTerm('')
                }}
                disableAutoFocusItem={true}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: '500px',
                            overflowY: 'auto',
                            // width: '400px',
                            padding: '8px',
                        },
                    },
                }}
            >
                <Box
                    sx={{ padding: '0 8px 6px 8px' }}
                    onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                            handleClose()
                        } else {
                            e.stopPropagation()
                        }
                    }}
                >
                    <SearchBar
                        placeholder=""
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                        onSubmit={() => {}}
                        onClick={() => {}}
                        showSearchIcon={true}
                        disabled={false}
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="right"
                    marginBottom="14px"
                    width={'100%'}
                >
                    <Button
                        variant="outlined"
                        onClick={() => {
                            createNewMap()
                            setAnchorEl(null)
                        }}
                        endIcon={<Add fontSize="small" />}
                        size="small"
                        sx={{ marginRight: '8px' }}
                    >
                        Create new map
                    </Button>
                </Box>
                <Divider />
                {allMaps.length === 0 && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Lottie
                            loop
                            animationData={loadingSpinner}
                            play
                            style={{
                                width: 70,
                                height: 70,
                            }}
                        />
                    </Box>
                )}
                {filteredMaps.map((map) => (
                    <MenuItem
                        key={map.id}
                        onClick={() => {
                            handleClose(map)
                        }}
                        divider={true}
                        sx={{ borderRadius: '8px' }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            width={'100%'}
                            alignItems={'center'}
                        >
                            <Typography variant="body1">{map.name}</Typography>
                            <Box flexGrow={1} />
                            <Tooltip title={'Delete map'} arrow>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setMapToDelete(map)
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default MapSelector
