import React, { Context, createContext, useContext } from 'react'
import { MapAction, MapState } from './mapReducer'
import { Dataset, VizType } from '../../types/dataset'
import { VizParams } from '../../types/viz'

type MapContextType = {
    state: MapState
    dispatch: React.Dispatch<MapAction>
    createNewMap: () => void
    changeMap: (mapId: string) => void
    deleteMap: (mapId: string) => Promise<void>
    updateMapMetadata: (name: string) => void
    addDatasetToMap: (dataset: Dataset, isVisible: boolean) => void
    removeDatasetFromMap: (datasetId: string) => void
    selectEarthEngineVisualization: (
        datasetVersionId: string,
        idx: number
    ) => void
    updateVizParams: (
        dataset: Dataset,
        vizParams: VizParams,
        vizType: VizType
    ) => void
    updateLayerOrder: (
        layerOrder: {
            layerOrder: number
            datasetVersionId: string
        }[]
    ) => void
    lastDatasetRef: React.MutableRefObject<HTMLDivElement> | null
    deleteDatasetFromCatalog: (datasetId: string) => void
    clearMap: () => void
    setViewMode: (viewMode: 'map' | 'catalog') => void
}

export const MapContext = createContext<MapContextType>(null)

export const useMapContext = () => useContext(MapContext)
