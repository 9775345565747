import * as React from 'react'
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@mui/material'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Dataset } from '../../types/dataset'
import DatasetList from '../DatasetList/DatasetList'
import VizParamsEditor from '../VizParamsEditor/VizParamsEditor'
import { useMapContext } from '../../context/map/mapContext'

interface DatasetsDisplayProps {
    viewMode: 'map' | 'catalog'
    datasetBeingEdited: Dataset | undefined
    mapDatasets: Dataset[]
    catalogDatasets: Dataset[]
    recentlyUpdatedCatalogDatasets: Dataset[]
    flyToDatasetBounds: (dataset: Dataset) => void
    datasetListMaxHeightCatalog: string | number
    datasetListMaxHeightMap: string | number
}

interface CatalogSectionProps {
    sectionText: string
    secondaryAction: React.ReactNode
    datasets: Dataset[]
    flyToDatasetBounds: (dataset: Dataset) => void
    startOpen?: boolean
}

const CatalogSection = ({
    sectionText,
    secondaryAction,
    datasets,
    flyToDatasetBounds,
    startOpen = true,
}: CatalogSectionProps) => {
    const [open, setOpen] = React.useState(startOpen)
    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <List disablePadding>
            <ListItem dense disablePadding secondaryAction={secondaryAction}>
                <ListItemButton
                    onClick={handleClick}
                    sx={{ borderRadius: '8px', padding: '8px' }}
                >
                    <ListItemIcon
                        style={{
                            minWidth: '0px',
                            paddingRight: '6px',
                            paddingBottom: '4px',
                        }}
                    >
                        {open ? <ExpandMore /> : <ChevronRight />}
                    </ListItemIcon>
                    <ListItemText primary={sectionText} />
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ marginLeft: '10px' }}>
                    <DatasetList
                        datasets={datasets}
                        flyToDatasetBounds={flyToDatasetBounds}
                        showVizSettings={false}
                        showSelector={true}
                        showRemoveFromMap={false}
                        disableNonActive={false}
                        reorderable={false}
                    />
                </Box>
            </Collapse>
        </List>
    )
}

const DatasetsDisplay: React.FC<DatasetsDisplayProps> = ({
    viewMode,
    datasetBeingEdited,
    mapDatasets,
    catalogDatasets,
    recentlyUpdatedCatalogDatasets,
    flyToDatasetBounds,
    datasetListMaxHeightCatalog,
    datasetListMaxHeightMap,
}) => {
    const { state, lastDatasetRef } = useMapContext()

    return (
        <Box
            sx={{
                maxHeight:
                    viewMode === 'catalog'
                        ? datasetListMaxHeightCatalog
                        : datasetListMaxHeightMap,
                overflowY: 'auto',
            }}
            id="datasetsDisplay"
        >
            {viewMode === 'map' && (
                <>
                    {datasetBeingEdited && (
                        <VizParamsEditor
                            dataset={datasetBeingEdited}
                            flyToDatasetBounds={flyToDatasetBounds}
                        />
                    )}
                    {!datasetBeingEdited &&
                        state.current.datasets.length > 0 && (
                            <div>
                                <DatasetList
                                    datasets={mapDatasets}
                                    flyToDatasetBounds={flyToDatasetBounds}
                                    showVizSettings={true}
                                    showSelector={false}
                                    showRemoveFromMap={true}
                                    disableNonActive={true}
                                    reorderable={true}
                                />
                                <div ref={lastDatasetRef} />
                            </div>
                        )}
                </>
            )}

            {viewMode === 'catalog' && (
                <>
                    {recentlyUpdatedCatalogDatasets.length > 0 && (
                        <CatalogSection
                            sectionText={'Recently added or updated'}
                            secondaryAction={null}
                            datasets={recentlyUpdatedCatalogDatasets}
                            flyToDatasetBounds={flyToDatasetBounds}
                            startOpen={true}
                        />
                    )}
                    <DatasetList
                        datasets={catalogDatasets}
                        flyToDatasetBounds={flyToDatasetBounds}
                        showVizSettings={false}
                        showSelector={true}
                        showRemoveFromMap={false}
                        disableNonActive={false}
                        reorderable={false}
                    />
                </>
            )}
        </Box>
    )
}

export default DatasetsDisplay
