import React from 'react';
import { Alert, Snackbar, SnackbarOrigin, SnackbarProps } from '@mui/material';

// Fix the POSITIONS object with proper literal types
const POSITIONS: Record<string, SnackbarOrigin> = {
  info: { vertical: 'top', horizontal: 'center' },
  success: { vertical: 'bottom', horizontal: 'right' },
  error: { vertical: 'bottom', horizontal: 'right' },
  warning: { vertical: 'top', horizontal: 'center' },
};

export interface AppAlertProps extends Omit<SnackbarProps, 'children'> {
  open: boolean;
  message: React.ReactNode;
  onClose: () => void;
  severity: 'info' | 'success' | 'error' | 'warning';
  autoHideDuration?: number;
  variant?: 'standard' | 'filled';
  width?: string | number;
}

export const AppAlert = ({
  open,
  message,
  onClose,
  severity = 'info',
  autoHideDuration = 6000,
  variant = 'standard',
  width = severity === 'info' ? '70%' : '100%',
  anchorOrigin = POSITIONS[severity],
  ...otherProps
}: AppAlertProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      sx={{ marginBottom: '20px', zIndex: (theme) => theme.zIndex.modal + 1 }}
      {...otherProps}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant={variant}
        sx={{ width: width }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

// Usage examples:
export const InfoAlert = (props: Omit<AppAlertProps, 'severity'>) => (
  <AppAlert {...props} severity="info" />
);

export const SuccessAlert = (props: Omit<AppAlertProps, 'severity'>) => (
  <AppAlert {...props} severity="success" variant="filled" />
);

export const ErrorAlert = (props: Omit<AppAlertProps, 'severity'>) => (
  <AppAlert {...props} severity="error" variant="filled" />
);
