/* The Workspace contains (1) the map of datasets (2) a sidebar controlling which datasets to view and how to visualize them. */
import * as React from 'react'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'
import Sidebar from '../../components/Sidebar/Sidebar'
import MapView from '../../components/MapView/MapView'
import WorkspaceStyle from './Workspace.module.css'
import { MapRef } from 'react-map-gl'
import { viewport } from '@placemarkio/geo-viewport'
import { boundsStringToArray, isTextInputActive } from '../../utils'
import { Dataset } from '../../types/dataset'
import PixelInfoView from '../../components/PixelInfoView/PixelInfoView'
import CommentModeBox from '../../components/CommentModeBox/CommentModeBox'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import DatasetDetails from '../../components/DatasetDetails/DatasetDetails'
import { useParams } from 'react-router-dom'
import { useMapContext } from '../../context/map/mapContext'
import ShareBox from '../../components/ShareBox/ShareBox'
import CacheManager from '../../context/cache'
import { useActivityContext } from '../../context/activity/activityContext'
import CommentThreadBox from '../../components/CommentThreadBox/CommentThreadBox'
import NewThreadBox from '../../components/NewThreadBox/NewThreadBox'
import { useCommentContext } from '../../context/comment/commentContext'
import FeedbackButton from '../../components/FeedbackButton/FeedbackButton'
import GlobeViewButton from '../../components/GlobeViewButton/GlobeViewButton'
import { fetchDatasets, subscribeToDatasetChanges } from '../../api/dataset'
import OnboardingTour from '../../components/OnboardingTour/OnboardingTour'

type ActiveDetailPanel = 'dataset' | 'pixel' | 'comment' | 'newThread' | null

const flyPadding = 300 // Some padding so you can see the bounds when flying/jumping

function Workspace() {
    const supabaseContext = useSupabaseContext()
    const user = supabaseContext.user

    const { id } = useParams() // Map ID for /map/:id routing
    const { changeMap, state: mapState, dispatch } = useMapContext()
    const { state: commentState, setCommentMode } = useCommentContext()
    const mapRef = React.useRef<MapRef>(null)

    const [activeDetailPanel, setActiveDetailPanel] =
        React.useState<ActiveDetailPanel>(null)
    const initLocation = mapState.initialViewState
        ? mapState.initialViewState.bbox
        : JSON.parse(CacheManager.getItem('lastLocation'))

    React.useEffect(() => {
        if (commentState.draftThread) {
            setActiveDetailPanel('newThread')
        } else if (commentState.selectedThreadId) {
            setActiveDetailPanel('comment')
        } else if (mapState.clickedLngLat) {
            setActiveDetailPanel('pixel')
        } else if (mapState.detailsDatasetVersionId) {
            setActiveDetailPanel('dataset')
        } else {
            setActiveDetailPanel(null)
        }
    }, [
        mapState.detailsDatasetVersionId,
        commentState.selectedThreadId,
        commentState.draftThread,
        mapState.clickedLngLat,
    ])

    React.useEffect(() => {
        if (id) {
            changeMap(id)
        } else if (user.default_map_id) {
            changeMap(user.default_map_id)
        }
    }, [id, user.default_map_id])

    const fetchCatalog = async (client) => {
        fetchDatasets(supabaseContext.client).then((datasets) => {
            dispatch({
                type: 'SET_CATALOG',
                datasets: datasets,
            })
        })
    }

    // Fetch once at the start and subscribe to changes
    React.useEffect(() => {
        fetchCatalog(supabaseContext.client)
        subscribeToDatasetChanges(supabaseContext.client, (payload) => {
            fetchCatalog(supabaseContext.client)
        })
    }, [])

    const areBoundsValid4326 = (bounds: [number, number, number, number]) => {
        if (bounds.length !== 4) {
            return false
        }
        if (
            isNaN(bounds[0]) ||
            isNaN(bounds[1]) ||
            isNaN(bounds[2]) ||
            isNaN(bounds[3])
        ) {
            return false
        }

        const width = bounds[2] - bounds[0]
        const height = bounds[3] - bounds[1]

        // allow 10 degree overlap
        if (Math.abs(width) > 370 || Math.abs(height) > 190) {
            return false
        }

        return true
    }

    const flyToBounds = (bounds: [number, number, number, number]) => {
        if (!areBoundsValid4326(bounds)) {
            console.error(
                'Bounds are not valid EPSG:4326, defaulting to global bounds',
                bounds
            )
            bounds = [-180, -85.05112878, 180, 85.05112878]
        }
        if (mapRef.current) {
            const map = mapRef.current
            const canvas = map.getCanvas()

            // Some padding so you can see the bounds
            const vp = viewport(bounds, [
                parseInt(canvas.style['width']) - flyPadding,
                parseInt(canvas.style['height']) - flyPadding,
            ])
            if (vp.zoom == null || isNaN(vp.zoom)) {
                vp.zoom = 1
            }
            mapRef.current.flyTo({
                center: vp.center,
                zoom: vp.zoom,
                duration: 1000,
                essential: true,
            })
        }
    }

    const flyToDatasetBounds = (dataset: Dataset) => {
        // Bounds are only available for ready datasets
        if (dataset.status === 'ready') {
            const bounds = boundsStringToArray(dataset.bounds)
            flyToBounds(bounds)
        }
    }

    React.useEffect(() => {
        const handleBeforeUnload = () => {
            if (mapRef.current) {
                const bounds = mapRef.current.getBounds()
                const boundsArray = [
                    bounds.getWest(),
                    bounds.getSouth(),
                    bounds.getEast(),
                    bounds.getNorth(),
                ]
                CacheManager.setItem(
                    'lastLocation',
                    JSON.stringify(boundsArray)
                )
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [mapRef])

    const selectedDataset =
        mapState.current?.datasets.find(
            (d) => d.id === mapState.selectedDatasetVersionId
        ) ||
        mapState.catalog.find((d) => d.id === mapState.selectedDatasetVersionId)

    const { trackVisibilityToggle } = useActivityContext()

    const handleKeyPress = React.useCallback(
        (event: KeyboardEvent) => {
            switch (event.key) {
                case 'Escape':
                    if (commentState.isCommentMode) {
                        setCommentMode(false)
                    }
                    break
                case 'v':
                    if (isTextInputActive() || !selectedDataset) break
                    dispatch({
                        type: 'TOGGLE_DATASET_VISIBILITY',
                        datasetVersionId: selectedDataset.id,
                    })
                    trackVisibilityToggle(selectedDataset.id)
                    break
                case 'z':
                    if (isTextInputActive() || !selectedDataset) break
                    flyToDatasetBounds(selectedDataset)
                    break
                case 'a':
                    if (isTextInputActive() || !selectedDataset) break
                    if (selectedDataset?.selectedDimensions?.['time'] > 0) {
                        dispatch({
                            type: 'SET_SELECTED_DIMENSIONS',
                            datasetVersionId: selectedDataset.id,
                            dimensions: {
                                ...selectedDataset.selectedDimensions,
                                time:
                                    selectedDataset.selectedDimensions['time'] -
                                    1,
                            },
                        })
                    }
                    break
                case 'd':
                    if (isTextInputActive() || !selectedDataset) break
                    const timeDim =
                        selectedDataset.dimensionInfo.dimensions.find(
                            (dim) => dim.name === 'time'
                        )
                    if (
                        selectedDataset?.selectedDimensions?.['time'] <
                        timeDim?.values.length - 1
                    ) {
                        dispatch({
                            type: 'SET_SELECTED_DIMENSIONS',
                            datasetVersionId: selectedDataset.id,
                            dimensions: {
                                ...selectedDataset.selectedDimensions,
                                time:
                                    selectedDataset.selectedDimensions['time'] +
                                    1,
                            },
                        })
                    }
                    break
            }
        },
        [selectedDataset]
    )

    React.useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress)

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }
    }, [handleKeyPress])

    const disableOnboarding =
        import.meta.env.EARTHSCALE_DISABLE_ONBOARDING === 'true' ||
        user.onboarding_completed

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    position: 'fixed',
                    top: '16px',
                    zIndex: 1000,
                    right: '16px',
                    transform: {
                        xs: 'scale(0.6)',
                        sm: 'scale(0.7)',
                        md: 'scale(1)',
                    },
                    transformOrigin: 'right top', // This ensures scaling happens from the right side
                }}
            >
                <GlobeViewButton />
                <ShareBox mapRef={mapRef} />
                <CommentModeBox />
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    position: 'fixed',
                    bottom: '36px',
                    right: '8px',
                    zIndex: 1000,
                    transform: {
                        xs: 'scale(0.6)',
                        sm: 'scale(0.7)',
                        md: 'scale(0.8)',
                    },
                    transformOrigin: 'right bottom', // This ensures scaling happens from the right side
                }}
            >
                <FeedbackButton />
            </Stack>
            <Box className={WorkspaceStyle.sidebarBox}>
                <Sidebar
                    flyToDatasetBounds={flyToDatasetBounds}
                    mapRef={mapRef}
                />
            </Box>
            <Box className={WorkspaceStyle.map}>
                <MapView mapRef={mapRef} initLocation={initLocation} />
            </Box>
            <Box className={WorkspaceStyle.details}>
                <Stack spacing={1}>
                    {activeDetailPanel === 'dataset' && (
                        <DatasetDetails
                            setActiveDetailPanel={setActiveDetailPanel}
                            flyToDatasetBounds={flyToDatasetBounds}
                        />
                    )}
                    {activeDetailPanel === 'pixel' && (
                        <PixelInfoView
                            setActiveDetailPanel={setActiveDetailPanel}
                        />
                    )}
                    {activeDetailPanel === 'comment' &&
                        commentState.selectedThreadId && <CommentThreadBox />}
                    {activeDetailPanel === 'newThread' &&
                        commentState.draftThread && <NewThreadBox />}
                </Stack>
            </Box>
            {!disableOnboarding && <OnboardingTour />}
        </>
    )
}

export default Workspace
