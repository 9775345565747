import { createContext, useContext } from 'react'
import { BackendClient } from '../../api/backend'

export const BackendContext = createContext<BackendClient | null>(null)

export const useBackendContext = () => {
    const client = useContext(BackendContext)
    if (!client) {
        throw new Error(
            'No backend client available. Make sure you are using BackendProvider and are logged in.'
        )
    }
    return client
}
