import React from 'react'
import { IconButton, Link, Typography } from '@mui/material'
import logo from '../../assets/earthscale-logo-light.svg'
import Box from '@mui/material/Box'
import SidebarStyle from './Sidebar.module.css'
import UserMenu from './UserMenu'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { UserAvatar } from '../UserAvatar/UserAvatar'

const avatarSize = 24

function Header() {
    const [userMenuAnchorEl, setUserMenuAnchorEl] =
        React.useState<null | HTMLElement>(null)
    const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget)
    }

    const { userProfile } = useSupabaseContext()

    return (
        <>
            <Box className={SidebarStyle.headerBox}>
                <img
                    src={logo as string}
                    alt="Logo"
                    className={SidebarStyle.logo}
                />

                <Box className={SidebarStyle.headerRightGroup}>
                    <Typography
                        variant="body2"
                        component="div"
                        className={SidebarStyle.docsLink}
                    >
                        <Link
                            href="https://earthscale-ai-earthscale-sdk-demo.readthedocs-hosted.com/en/latest/"
                            color="inherit"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Documentation
                        </Link>
                    </Typography>
                    <Box className={SidebarStyle.userButton}>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleUserMenuOpen}
                        >
                            <UserAvatar
                                userProfile={userProfile}
                                size={avatarSize}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <UserMenu
                anchorEl={userMenuAnchorEl}
                onClose={() => setUserMenuAnchorEl(null)}
            />
        </>
    )
}

export default Header
