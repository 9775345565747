import { Comment, CommentThread } from '../types/comments'
import { UserProfile } from '../types/user'
import { SupabaseClient } from '@supabase/supabase-js'
import * as Sentry from '@sentry/react'
import { fetchUserProfiles } from './user'
import { LngLat } from 'react-map-gl'

export async function fetchThreads(
    mapId: string,
    supabaseClient: SupabaseClient
): Promise<CommentThread[]> {
    const threadsQuery = await supabaseClient
        .from('comment_threads')
        .select('*,comments(*)')
        .eq('map_id', mapId)
    const { data, error } = await threadsQuery
    if (error) {
        console.error(error)
        Sentry.captureException(error)
        return []
    }
    // collect all user ids of threads and their comments
    const userIds = data.reduce((acc, thread) => {
        acc.add(thread.user_id)
        thread.comments.forEach((comment) => acc.add(comment.user_id))
        return acc
    }, new Set<string>())

    const users = await fetchUserProfiles(supabaseClient, Array.from(userIds))
    const usersById = users.reduce(
        (acc, user) => {
            acc[user.id] = user
            return acc
        },
        {} as Record<string, UserProfile>
    )
    const threads = data.map((thread) => threadFromDb(thread, usersById))
    return threads
}

function threadFromDb(
    thread: any,
    usersById: Record<string, UserProfile>
): CommentThread {
    return {
        id: thread.id,
        title: thread.title,
        user: usersById[thread.user_id],
        createdAt: new Date(thread.created_at),
        updatedAt: new Date(thread.updated_at),
        location: thread.location,
        comments:
            thread.comments?.map((comment) =>
                commentFromDb(comment, usersById)
            ) || [],
    }
}

function commentFromDb(
    comment: any,
    usersById: Record<string, UserProfile>
): Comment {
    return {
        id: comment.id,
        user: usersById[comment.user_id],
        threadId: comment.comment_thread_id,
        content: comment.content,
        createdAt: new Date(comment.created_at),
        updatedAt: new Date(comment.updated_at),
    }
}

export async function updateThreadTitle(
    threadId: string,
    title: string,
    client: SupabaseClient,
    user: UserProfile
): Promise<CommentThread> {
    const { data, status } = await client
        .from('comment_threads')
        .update({ title: title })
        .eq('id', threadId)
        .select()
    if (status !== 200) {
        let error = new Error(`Failed to update thread, status code: ${status}`)
        Sentry.captureException(error)
        throw error
    }
    if (!data || data.length === 0 || !data[0].id) {
        let error = new Error('Failed to update thread, no data returned')
        Sentry.captureException(error)
        throw error
    }
    const usersById = { [user.id]: user }
    const thread = threadFromDb(data[0], usersById)
    return thread
}

export async function addThread(
    mapId: string,
    location: LngLat,
    content: string,
    title: string,
    client: SupabaseClient,
    user: UserProfile
): Promise<CommentThread> {
    const { data, status } = await client
        .from('comment_threads')
        .insert({
            title: title,
            user_id: user.id,
            org_id: user.org_id,
            map_id: mapId,
            location: {
                type: 'Point',
                coordinates: [location.lng, location.lat],
            },
        })
        .select()
    if (status !== 201) {
        let error = new Error(`Failed to add thread, status code: ${status}`)
        Sentry.captureException(error)
        throw error
    }
    if (!data || data.length === 0 || !data[0].id) {
        let error = new Error('Failed to add thread, no data returned')
        Sentry.captureException(error)
        throw error
    }
    const usersById = { [user.id]: user }
    const thread = threadFromDb(data[0], usersById)
    const comment = await addComment(thread.id, content, client, user)
    thread.comments.push(comment)
    return thread
}

export async function addComment(
    threadId: string,
    content: string,
    client: SupabaseClient,
    user: UserProfile
): Promise<Comment> {
    const { data, status } = await client
        .from('comments')
        .insert({
            user_id: user.id,
            org_id: user.org_id,
            content: content,
            comment_thread_id: threadId,
        })
        .select()
    if (status !== 201) {
        let error = new Error(`Failed to add comment, status code: ${status}`)
        Sentry.captureException(error)
        throw error
    }
    if (!data || data.length === 0 || !data[0].id) {
        let error = new Error('Failed to add comment, no data returned')
        Sentry.captureException(error)
        throw error
    }
    const usersById = { [user.id]: user }
    const comment = commentFromDb(data[0], usersById)
    return comment
}

export async function updateComment(
    commentId: string,
    content: string,
    client: SupabaseClient,
    user: UserProfile
): Promise<Comment> {
    const { data, status } = await client
        .from('comments')
        .update({ content: content })
        .eq('id', commentId)
        .select()
    if (status !== 200) {
        let error = new Error(
            `Failed to update comment, status code: ${status}`
        )
        Sentry.captureException(error)
        throw error
    }
    if (!data || data.length === 0 || !data[0].id) {
        let error = new Error('Failed to update comment, no data returned')
        Sentry.captureException(error)
        throw error
    }
    const usersById = { [user.id]: user }
    const comment = commentFromDb(data[0], usersById)
    return comment
}
