import React, { useState } from 'react'
import {
    Box,
    IconButton,
    TextField,
    Tooltip,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material'
import { ArrowDropDown, DeleteSweep } from '@mui/icons-material'
import MapSelector from './MapSelector'
import { useMapContext } from '../../context/map/mapContext'
import SidebarStyle from './Sidebar.module.css'
import mapPin from '../../assets/icons/map-pin.svg'

const MapBox = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [clearDialogOpen, setClearDialogOpen] = useState(false)
    const { state, updateMapMetadata, clearMap } = useMapContext()
    const theme = useTheme()

    const currentMap = state.current.metadata

    const handleMapSelectorButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMapNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateMapMetadata(event.target.value)
    }

    const handleClearMap = () => {
        clearMap()
        setClearDialogOpen(false)
    }

    return (
        <>
            <Box
                className={SidebarStyle.mapBox}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <img
                    src={mapPin as string}
                    alt="Map Pin"
                    className={SidebarStyle.mapPin}
                    style={{ margin: 'auto 0', marginTop: '6px' }}
                />
                <Box sx={{ flex: 1 }}>
                    <TextField
                        value={currentMap.name}
                        onChange={handleMapNameChange}
                        multiline={true}
                        InputProps={{
                            style: {
                                ...theme.typography.h5,
                                padding: '5px',
                                borderRadius: '8px',
                                border: 'none',
                            },
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                    />
                </Box>
                <Tooltip title="Clear all layers from map" arrow>
                    <IconButton
                        onClick={() => setClearDialogOpen(true)}
                        color="primary"
                    >
                        <DeleteSweep />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Select map" arrow>
                    <IconButton onClick={handleMapSelectorButtonClick}>
                        <ArrowDropDown color="primary" fontSize="large" />
                    </IconButton>
                </Tooltip>
                <MapSelector anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            </Box>

            <Dialog
                open={clearDialogOpen}
                onClose={() => setClearDialogOpen(false)}
                aria-labelledby="clear-dialog-title"
                aria-describedby="clear-dialog-description"
            >
                <DialogTitle id="clear-dialog-title">
                    Clear Map "{currentMap.name}"?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="clear-dialog-description">
                        Are you sure you want to remove all layers from this
                        map? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setClearDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleClearMap} color="error" autoFocus>
                        Clear Map
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MapBox
