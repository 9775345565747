import React from 'react'
import { Avatar, AvatarProps, SxProps, Theme } from '@mui/material'
import { UserProfile } from '../../types/user'
import { OverrideProps } from '@mui/material/OverridableComponent'
import { AvatarTypeMap } from '@mui/material/Avatar/Avatar'

function isValidPictureUrl(url: string | null): boolean {
    if (!url) {
        return false
    }
    return (
        url.startsWith('http://') ||
        url.startsWith('https://') ||
        url.startsWith('/')
    )
}

function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

function stringAvatar(userName: string, size: string | number) {
    const names = userName.split(' ')
    return {
        sx: {
            bgcolor: stringToColor(userName),
            width: size,
            height: size,
            fontSize: `calc(${size} / 2)`,
        },
        children: names[0][0] + (names.length > 1 ? names[1][0] : ''),
    }
}

export function UserAvatar(props: {
    userProfile: UserProfile
    size: number | string
}) {
    return (
        <>
            {isValidPictureUrl(props.userProfile.picture_url) ? (
                <Avatar
                    alt={props.userProfile.name}
                    src={props.userProfile.picture_url}
                    sx={{ width: props.size, height: props.size }}
                />
            ) : (
                <Avatar
                    {...stringAvatar(props.userProfile.name, props.size)}
                    alt={props.userProfile.name}
                />
            )}
        </>
    )
}
