import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import DatasetDetailsStyle from './DatasetDetails.module.css'
import * as Sentry from '@sentry/react'
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    Paper,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { useMapContext } from '../../context/map/mapContext'
import { Close, ContentCopy } from '@mui/icons-material'
import { boundsStringToArray } from '../../utils'
import { createTileServerURL } from '../../api/tiler'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { Dataset } from 'types/dataset'
import ZoomButton from '../../components/DatasetCard/ZoomButton'
import { useBackendContext } from '../../context/backend/backendContext'
import { GetDatasetVersionResponse } from '../../api/backend'
import { useActivityContext } from '../../context/activity/activityContext'
import { SuccessAlert } from '../Alerts/Alerts'

function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

function formatBBoxAsWKT(bbox: string): string {
    const bboxArray = boundsStringToArray(bbox)
    return `POLYGON((${bboxArray[0]} ${bboxArray[1]}, ${bboxArray[2]} ${bboxArray[1]}, ${bboxArray[2]} ${bboxArray[3]}, ${bboxArray[0]} ${bboxArray[3]}, ${bboxArray[0]} ${bboxArray[1]}))`
}

function Header({
    datasetName,
    datasetType,
    onClose,
    zoomButton,
}: {
    datasetName: string
    datasetType: string
    onClose: () => void
    zoomButton: React.ReactNode
}) {
    return (
        <Box className={DatasetDetailsStyle.header}>
            <Box className={DatasetDetailsStyle.name}>
                <Typography variant="h5">{datasetName}</Typography>
                <Typography variant="body2">
                    {capitalize(datasetType) + ' Dataset'}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {zoomButton}

                <IconButton onClick={onClose}>
                    <Close fontSize="medium" />
                </IconButton>
            </Box>
        </Box>
    )
}

function Footer({
    createdAt,
    updatedAt,
}: {
    createdAt: string
    updatedAt: string
}) {
    return (
        <Box className={DatasetDetailsStyle.footer}>
            <TableContainer>
                <Table
                    size="small"
                    sx={{ '& td, & th': { border: 0, padding: '0' } }}
                >
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Typography variant="body2">Created</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body2">
                                    {new Date(createdAt).toLocaleDateString()}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body2">
                                    {new Date(createdAt).toLocaleTimeString()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Typography variant="body2">
                                    Last Updated
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body2">
                                    {new Date(updatedAt).toLocaleDateString()}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body2">
                                    {new Date(updatedAt).toLocaleTimeString()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

function OptimizationStatus({
    datasetDetails,
    setDatasetDetails,
}: {
    datasetDetails: GetDatasetVersionResponse | null
    setDatasetDetails: (
        datasetDetails: GetDatasetVersionResponse | null
    ) => void
}) {
    const [confirmOptimizationDialogOpen, setConfirmOptimizationDialogOpen] =
        useState(false)
    const hasOverviews = datasetDetails?.has_overviews
    const { state } = useMapContext()
    const backendClient = useBackendContext()
    const { user } = useSupabaseContext()

    const handleStartOptimization = () => {
        setConfirmOptimizationDialogOpen(false)
        if (!state.detailsDatasetVersionId) {
            return
        }
        const startOverviewsGeneration = async () => {
            try {
                // optimistically update the dataset details
                setDatasetDetails({
                    ...datasetDetails,
                    overviews_processing_status: {
                        status: 'pending',
                        updated_at: new Date().toISOString(),
                        started_at: null,
                        created_by_user: user?.id,
                    },
                })
                await backendClient.startOverviewsGeneration(
                    state.detailsDatasetVersionId
                )
            } catch (error) {
                Sentry.captureException(error)
            }
        }
        startOverviewsGeneration()
    }

    if (hasOverviews) {
        return (
            <Stack direction="row" alignItems="baseline" gap={1}>
                <Typography variant="body2">Yes</Typography>
            </Stack>
        )
    }

    const status = datasetDetails?.overviews_processing_status?.status
    if (status) {
        const startedAt =
            datasetDetails?.overviews_processing_status?.started_at
        if (status === 'pending' || status === 'running') {
            const prefix = status === 'pending' ? 'Pending' : 'Running'
            const tooltipText = startedAt
                ? `${prefix} since ${new Date(startedAt).toLocaleString()}`
                : `${prefix}...`

            return (
                <Tooltip title={tooltipText}>
                    <Stack direction="row" alignItems="baseline" gap={1}>
                        <CircularProgress size={12} />
                        <Typography variant="body2">Optimizing...</Typography>
                    </Stack>
                </Tooltip>
            )
        }
    }

    const buttonText =
        status === 'error' ? 'Failed, restart?' : 'Start Optimization'

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                onClick={() => setConfirmOptimizationDialogOpen(true)}
            >
                {buttonText}
            </Button>
            <Dialog
                open={confirmOptimizationDialogOpen}
                onClose={() => setConfirmOptimizationDialogOpen(false)}
                aria-labelledby="optimization-dialog-title"
                aria-describedby="optimization-dialog-description"
            >
                <DialogTitle id="optimization-dialog-title">
                    Start Dataset Optimization?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Optimizing a large dataset for faster rendering may
                        incur additional costs and take several minutes to
                        complete.
                    </Typography>
                    {datasetDetails?.uncompressed_size_bytes && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Estimated dataset size:{' '}
                            {(
                                datasetDetails.uncompressed_size_bytes /
                                1024 /
                                1024 /
                                1024
                            ).toFixed(2)}{' '}
                            GB
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmOptimizationDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleStartOptimization}
                        variant="contained"
                        autoFocus
                    >
                        Start Optimization
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function MetadataTable({
    dataset,
    datasetDetails,
    setDatasetDetails,
    tileUrl,
}: {
    dataset: Dataset
    datasetDetails: GetDatasetVersionResponse | null
    setDatasetDetails: (
        datasetDetails: GetDatasetVersionResponse | null
    ) => void
    tileUrl: string | null
}) {
    const [copyBBoxOpen, setCopyBBoxOpen] = useState(false)
    const [copyUrlOpen, setCopyUrlOpen] = useState(false)
    const { trackCopyTileUrl } = useActivityContext()
    const handleCloseBBox = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return
        setCopyBBoxOpen(false)
    }

    const handleCloseUrl = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return
        setCopyUrlOpen(false)
        trackCopyTileUrl(dataset.id)
    }

    return (
        <TableContainer>
            <Table size="small" className={DatasetDetailsStyle.metadataTable}>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography variant="body1">Extent</Typography>
                        </TableCell>
                        <TableCell>
                            <div className={DatasetDetailsStyle.extent}>
                                <div>
                                    [
                                    {boundsStringToArray(
                                        dataset.bounds
                                    )[0].toFixed(2)}
                                    ,
                                </div>
                                <div>
                                    {boundsStringToArray(
                                        dataset.bounds
                                    )[1].toFixed(2)}
                                    ,
                                </div>
                                <div>
                                    {boundsStringToArray(
                                        dataset.bounds
                                    )[2].toFixed(2)}
                                    ,
                                </div>
                                <div>
                                    {boundsStringToArray(
                                        dataset.bounds
                                    )[3].toFixed(2)}
                                    ]
                                </div>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{ width: '48px' }}>
                            <Tooltip title={'Copy to clipboard (as WKT)'}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            formatBBoxAsWKT(dataset.bounds)
                                        )
                                        setCopyBBoxOpen(true)
                                    }}
                                >
                                    <ContentCopy fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    {tileUrl && (
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Typography
                                    variant="body1"
                                    whiteSpace={'nowrap'}
                                >
                                    Tile URL
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    maxWidth: 0,
                                    '& > p': {
                                        overflowX: 'auto',
                                        whiteSpace: 'nowrap',
                                        scrollbarWidth: 'none',
                                        '&::-webkit-scrollbar': {
                                            height: '8px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            // backgroundColor: 'rgba(0,0,0,0.2)',
                                            borderRadius: '4px',
                                        },
                                    },
                                }}
                            >
                                <Typography variant="body2">
                                    {tileUrl}
                                </Typography>
                            </TableCell>
                            <TableCell align="right" sx={{ width: '48px' }}>
                                <Tooltip title={'Copy URL to clipboard'}>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                tileUrl
                                            )
                                            setCopyUrlOpen(true)
                                            trackCopyTileUrl(dataset.id)
                                        }}
                                    >
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )}

                    {dataset.type === 'raster' &&
                        dataset.className != 'EarthEngineDataset' &&
                        dataset.className != 'TileServerDataset' &&
                        datasetDetails && (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography variant="body1">
                                        Optimized?
                                    </Typography>
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <OptimizationStatus
                                        datasetDetails={datasetDetails}
                                        setDatasetDetails={setDatasetDetails}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
            <SuccessAlert
                open={copyBBoxOpen}
                autoHideDuration={3000}
                variant="filled"
                message="Successfully copied extent as WKT to clipboard"
                onClose={handleCloseBBox}
            />
            <SuccessAlert
                open={copyUrlOpen}
                autoHideDuration={3000}
                variant="filled"
                message="Successfully copied tiles URL to clipboard"
                onClose={handleCloseUrl}
            />
        </TableContainer>
    )
}

function DefinitionTable({ dataset }: { dataset: Dataset }) {
    const definition = dataset.definition
    if (!definition || Object.keys(definition).length === 0) {
        return null
    }

    const formatValue = (value: any): string | React.ReactNode => {
        if (Array.isArray(value)) {
            return JSON.stringify(value)
        } else if (typeof value === 'object') {
            return JSON.stringify(value)
        } else if (
            typeof value === 'string' &&
            value.match(/^(gs|s3|https?):\/\//)
        ) {
            return (
                <Link href={value} target="_blank" rel="noopener noreferrer">
                    {value}
                </Link>
            )
        }
        return String(value)
    }

    // Filter out null/undefined values
    const nonNullEntries = Object.entries(definition).filter(
        ([_, value]) => value != null
    )

    return (
        <Box
            sx={{
                padding: 1,
            }}
        >
            <Typography variant="body1" sx={{ marginBottom: 1, marginTop: 1 }}>
                Definition ({dataset.className}):
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{ fontWeight: 'bold', width: '30%' }}
                            >
                                Parameter
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nonNullEntries.map(([key, value]) => (
                            <TableRow
                                key={key}
                                sx={{
                                    borderTop:
                                        '1px solid rgba(224, 224, 224, 1)',
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        width: '30%',
                                    }}
                                >
                                    {key}
                                </TableCell>
                                <TableCell>{formatValue(value)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

function AttributesTable({
    datasetAttributes,
}: {
    datasetAttributes: { [key: string]: string }
}) {
    if (!datasetAttributes || Object.keys(datasetAttributes).length === 0) {
        return null
    }

    return (
        <Box sx={{ padding: '4px 8px' }}>
            <Typography variant="body1">Attributes:</Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{ fontWeight: 'bold', width: '30%' }}
                            >
                                Key
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(datasetAttributes).map(
                            ([key, value]) => (
                                <TableRow
                                    key={key}
                                    sx={{
                                        borderTop:
                                            '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            width: '30%',
                                        }}
                                    >
                                        {key}
                                    </TableCell>
                                    <TableCell>
                                        {typeof value === 'object' ? (
                                            JSON.stringify(value)
                                        ) : typeof value === 'string' &&
                                          value.match(/^https?:\/\//) ? (
                                            <Link
                                                href={value}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {value}
                                            </Link>
                                        ) : (
                                            String(value)
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

function DatasetDetails({
    setActiveDetailPanel,
    flyToDatasetBounds,
}: {
    setActiveDetailPanel: (
        panel: 'dataset' | 'pixel' | 'comment' | 'newThread'
    ) => void
    flyToDatasetBounds: (dataset: Dataset) => void
}) {
    const { state, dispatch } = useMapContext()
    const backendClient = useBackendContext()
    const [datasetDetails, setDatasetDetails] =
        useState<GetDatasetVersionResponse | null>(null)
    const [isInitialLoading, setIsInitialLoading] = useState(true)
    const { user } = useSupabaseContext()

    const olddetailsDatasetVersionId = useRef(state.detailsDatasetVersionId)

    useEffect(() => {
        if (
            olddetailsDatasetVersionId.current !== state.detailsDatasetVersionId
        ) {
            setIsInitialLoading(true)
            olddetailsDatasetVersionId.current = state.detailsDatasetVersionId
        }
        const fetchDatasetDetails = async () => {
            if (
                backendClient == null ||
                state.detailsDatasetVersionId == null
            ) {
                setDatasetDetails(null)
                return
            }
            try {
                const data = await backendClient.getDatasetVersion(
                    state.detailsDatasetVersionId
                )
                setDatasetDetails(data)
            } catch (error) {
                Sentry.captureException(error, {
                    tags: {
                        action: 'getDatasetVersion',
                        datasetVersionId: state.detailsDatasetVersionId,
                    },
                })
            } finally {
                setIsInitialLoading(false)
            }
        }

        fetchDatasetDetails()

        const intervalId = setInterval(() => {
            fetchDatasetDetails()
        }, 5000)

        return () => clearInterval(intervalId)
    }, [state.detailsDatasetVersionId, backendClient])

    if (!state.detailsDatasetVersionId) {
        return null
    }

    const allDatasets = [...state.current.datasets, ...state.catalog]
    const dataset = allDatasets.find(
        (dataset) => dataset.id === state.detailsDatasetVersionId
    )
    if (!dataset) {
        return null
    }

    const handleClose = () => {
        setActiveDetailPanel(null)
        dispatch({
            type: 'SET_DETAILS_DATASET',
            datasetVersionId: null,
        })
    }

    const zoomButton = (
        <ZoomButton flyToDatasetBounds={flyToDatasetBounds} dataset={dataset} />
    )

    return (
        <Paper
            className={DatasetDetailsStyle.outerBox}
            key={dataset.id + 'datasetDetails'}
        >
            <Header
                datasetName={dataset.name}
                datasetType={dataset.type}
                onClose={handleClose}
                zoomButton={zoomButton}
            />

            <Divider />
            {isInitialLoading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress />
                </Box>
            ) : dataset.status === 'ready' ? (
                <>
                    <Box className={DatasetDetailsStyle.details}>
                        <DefinitionTable dataset={dataset} />
                        <MetadataTable
                            dataset={dataset}
                            datasetDetails={datasetDetails}
                            setDatasetDetails={setDatasetDetails}
                            tileUrl={
                                dataset.source !== 'local'
                                    ? createTileServerURL(
                                          dataset,
                                          dataset.selectedDimensions,
                                          user
                                      )
                                    : null
                            }
                        />
                        <AttributesTable
                            datasetAttributes={dataset.attributes}
                        />
                    </Box>

                    <Footer
                        createdAt={dataset.createdAt}
                        updatedAt={dataset.updatedAt}
                    />
                </>
            ) : dataset.error ? (
                <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" color="error">
                            Error
                        </Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        {dataset.error}
                    </Typography>
                </>
            ) : null}
        </Paper>
    )
}

export default DatasetDetails
