import * as React from 'react'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useTheme } from '@mui/material'
import CacheManager from '../../context/cache'
import { Provider } from '@supabase/supabase-js'
import AuthLayout from '../../components/AuthLayout/AuthLayout'
import styles from '../../components/AuthLayout/AuthLayout.module.css'

const providers: Provider[] = document.location.hostname.includes('proxy')
    ? []
    : ['google']

function Login({ supabaseClient, mapRef }) {
    // Clear cache on first render
    React.useEffect(() => {
        CacheManager.invalidateCache()
    }, [])

    const theme = useTheme()

    return (
        <AuthLayout mapRef={mapRef}>
            <div className={styles.supabaseAuth}>
                <Auth
                    supabaseClient={supabaseClient}
                    providers={providers}
                    redirectTo={`${window.location.protocol}//${window.location.hostname}`}
                    localization={{
                        variables: {
                            sign_in: {
                                button_label: 'SIGN IN',
                            },
                        },
                    }}
                    appearance={{
                        theme: ThemeSupa,
                        variables: {
                            // Variables here: https://github.com/supabase-community/auth-ui/blob/main/packages/shared/src/theming/Themes.ts
                            default: {
                                colors: {
                                    brand: theme.palette.primary.main,
                                    brandAccent: '#D5E1D6',
                                    brandButtonText:
                                        theme.palette.primary.contrastText,
                                    inputText: theme.palette.primary.main,
                                    messageTextDanger:
                                        theme.palette.warning.main,
                                    messageBackgroundDanger: 'transparent',
                                    defaultButtonText:
                                        theme.palette.primary.main,
                                    defaultButtonBackground: 'transparent',
                                    defaultButtonBackgroundHover: '#FFFFFF20',
                                    dividerBackground: theme.palette.divider,
                                },
                                fonts: {
                                    bodyFontFamily:
                                        theme.typography.body1.fontFamily,
                                    buttonFontFamily:
                                        theme.typography.h6.fontFamily,
                                    inputFontFamily:
                                        theme.typography.body1.fontFamily,
                                    labelFontFamily:
                                        theme.typography.body1.fontFamily,
                                },
                                fontSizes: {
                                    baseButtonSize: '15px,',
                                },
                                space: {
                                    inputPadding: '16px',
                                    buttonPadding: '16px',
                                },
                                radii: {
                                    borderRadiusButton: '4px',
                                    buttonBorderRadius: '4px',
                                    inputBorderRadius: '4px',
                                },
                            },
                        },
                    }}
                    view={'sign_in'}
                    showLinks={false}
                />
            </div>
        </AuthLayout>
    )
}

export default Login
