import { Tile } from './MapView'
import { Layer, Source } from 'react-map-gl'
import React from 'react'

export function boundsToPolygon(
    bounds: [number, number, number, number]
): number[][][] {
    return [
        [
            [bounds[0], bounds[1]],
            [bounds[0], bounds[3]],
            [bounds[2], bounds[3]],
            [bounds[2], bounds[1]],
            [bounds[0], bounds[1]],
        ],
    ]
}

export const TILES_BEING_LOADED_LAYER_ID = 'tiles-being-loaded-layer'
const TILE_LOADING_VISUALIZATION_DELAY_MS = 10

/* From: https://gis.stackexchange.com/questions/390199/getting-latlngbounds-for-tile-based-on-its-tile-coordinate-using-leaflet */
export function getLatLngForTile(
    xtile: number,
    ytile: number,
    zoom: number
): [number, number] {
    let n = Math.pow(2.0, zoom)
    let lng = (xtile / n) * 360.0 - 180.0
    let lat_rad = Math.atan(Math.sinh(Math.PI * (1 - (2.0 * ytile) / n)))
    let lat = (180.0 * lat_rad) / Math.PI
    return [lat, lng]
}

function getBoundsForTile(
    xtile: number,
    ytile: number,
    zoom: number
): [number, number, number, number] {
    const northWest = getLatLngForTile(xtile, ytile, zoom)
    const southEast = getLatLngForTile(xtile + 1, ytile + 1, zoom)
    return [northWest[1], northWest[0], southEast[1], southEast[0]]
}

export function TileLoadingLayer({
    tilesBeingLoaded,
}: {
    tilesBeingLoaded: Tile[]
}) {
    // Filter tiles that have been loading for more than N milliseconds
    const [longLoadingTiles, setlongLoadingTiles] = React.useState<Tile[]>([])

    React.useEffect(() => {
        if (tilesBeingLoaded.length === 0) {
            setlongLoadingTiles([])
            return
        }
        const longestLoadTime = Math.max(...tilesBeingLoaded.map(tile => Date.now() - tile.startTime))
        const remainingDelay = Math.max(0, TILE_LOADING_VISUALIZATION_DELAY_MS - longestLoadTime)

        const timeoutId = setTimeout(() => {
            setlongLoadingTiles(tilesBeingLoaded)
        }, remainingDelay)

        return () => clearTimeout(timeoutId)
    }, [tilesBeingLoaded])

    if (longLoadingTiles.length === 0) {
        return null
    }

    return (
        <Source
            id="tiles-being-loaded-source"
            type="geojson"
            data={{
                type: 'FeatureCollection',
                features: longLoadingTiles.map((tile) => ({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Polygon',
                        coordinates: boundsToPolygon(
                            getBoundsForTile(tile.x, tile.y, tile.z)
                        ),
                    },
                })),
            }}
        >
            <Layer
                id={`${TILES_BEING_LOADED_LAYER_ID}`}
                type="fill"
                paint={{
                    'fill-color': '#B0C4DE', // Light Steel Blue (pale blue gray)
                    'fill-opacity': 0.35,
                }}
            />
        </Source>
    )
}
