import * as React from 'react';
import { Box, Stack, Tooltip, IconButton } from '@mui/material';
import { CenterFocusStrong } from '@mui/icons-material';
import SearchBar from '../SearchBar/SearchBar';

interface DatasetSearchBarProps {
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    filterToView: boolean;
    setFilterToView: (filter: boolean) => void;
}

const DatasetSearchBar: React.FC<DatasetSearchBarProps> = ({
    searchTerm,
    setSearchTerm,
    filterToView,
    setFilterToView
}) => {
    return (
        <Box sx={{ padding: '8px 8px 0px 8px', mb: 1 }}>
            <Stack direction="row" spacing={1}>
                <SearchBar
                    searchTerm={searchTerm}
                    onSearchChange={setSearchTerm}
                    placeholder={`Search datasets`}
                    onSubmit={() => {}}
                    showSearchIcon={true}
                    disabled={false}
                    onClick={() => {}}
                />
                <Tooltip
                    title={
                        filterToView
                            ? 'Toggle to show all datasets'
                            : 'Toggle to only show datasets in current view'
                    }
                >
                    <IconButton
                        onClick={() => {setFilterToView(!filterToView)}}
                        color={
                            filterToView
                                ? 'secondary'
                                : 'primary'
                        }
                        size="small"
                    >
                        <CenterFocusStrong />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Box>
    );
};

export default DatasetSearchBar;
