import * as React from 'react'
import { useState, useEffect } from 'react'
import { Dataset } from '../../types/dataset'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Snackbar,
    Alert,
} from '@mui/material'
import DatasetCardStyle from './DatasetCard.module.css'
import VisibilityButton from './VisibilityButton'
import { Info, Warning } from '@mui/icons-material'
import DateSlider from './DateSlider'
import { useMapContext } from '../../context/map/mapContext'
import { fetchDatasets, PROCESSING_STATES } from '../../api/dataset'
import DimensionSelector from './DimensionSelector.js'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline'
import ViewListIcon from '@mui/icons-material/ViewList'
import DatasetTypeIcon from './DatasetTypeIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddToMapButton from './AddToMapButton'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { createTileServerURL } from '../../api/tiler'
import QGISInstructionsDialog from './QGISInstructionsDialog'
import { boundsStringToArray } from '../../utils'
import { InfoAlert } from '../Alerts/Alerts'

type DatasetCardProps = {
    dataset: Dataset
    flyToDatasetBounds: (dataset: Dataset) => void
    showVizSettings: boolean
    showRemoveFromMap: boolean
    showAddToMap: boolean
}

function DatasetCard({
    dataset,
    flyToDatasetBounds,
    showVizSettings,
    showRemoveFromMap,
    showAddToMap,
}: DatasetCardProps) {
    const {
        state: { viewportBounds, selectedDatasetVersionId },
        deleteDatasetFromCatalog,
        removeDatasetFromMap,
        addDatasetToMap,
        selectEarthEngineVisualization,
        dispatch,
    } = useMapContext()

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [removeFromMapDialogOpen, setRemoveFromMapDialogOpen] =
        React.useState(false)
    const [datasetToDelete, setDatasetToDelete] = useState<Dataset | null>(null)

    const [useTimeSlider, setUseTimeSlider] = React.useState(true)

    const visibilityButton = VisibilityButton({
        dataset,
        className: DatasetCardStyle.icon,
    })

    const addToMapButton = showAddToMap && <AddToMapButton dataset={dataset} />

    let secondaryText = null
    if (dataset.source == 'local') {
        secondaryText =
            'Local Vector Dataset' + ' - Only visible to you and not persisted'
    }

    const handleDeleteDatasetClose = () => {
        setDeleteDialogOpen(false)
        setDatasetToDelete(null)
    }

    const handleRemoveFromMapDialogClose = () => {
        setRemoveFromMapDialogOpen(false)
    }

    const handleRemoveFromMap = () => {
        removeDatasetFromMap(dataset.id)
        setRemoveFromMapDialogOpen(false)
    }

    // TODO: Filter dimensions to only include those present in the visualization bands
    const getRelevantDimensions = () => {
        if (!dataset.dimensionInfo?.dimensions) return []
        return dataset.dimensionInfo.dimensions
    }

    const relevantDimensions = getRelevantDimensions()
    const hasTimeDimension = relevantDimensions.find(
        (dim) => dim.name === 'time'
    )
    const nonTimeDimensions = relevantDimensions.filter(
        (dim) => dim.name !== 'time'
    )
    const hasAnyDimensions = relevantDimensions.length > 0

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
        null
    )
    const isMenuOpen = Boolean(menuAnchorEl)

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setMenuAnchorEl(event.currentTarget)
    }

    const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setMenuAnchorEl(null)
    }

    const [qgisDialogOpen, setQgisDialogOpen] = useState(false)
    const { client, user } = useSupabaseContext()

    const isLatestVersion = dataset?.latestDatasetVersionId == null
    const tooltipText = isLatestVersion
        ? ''
        : 'There is a newer version of this dataset. Click to update.'
    const updateDatasetToLatestVersion = async () => {
        console.log(dataset.id, dataset.latestDatasetVersionId)
        if (dataset?.latestDatasetVersionId == null) {
            return
        }
        const latestDatasetVersions = await fetchDatasets(client, [
            dataset?.latestDatasetVersionId,
        ])

        if (latestDatasetVersions.length == 1) {
            const latestDatasetVersion = latestDatasetVersions[0]
            await removeDatasetFromMap(dataset.id)
            await addDatasetToMap(latestDatasetVersion, dataset.isVisible)
        }
    }

    const [showNotification, setShowNotification] = useState(false)

    // Track previous visibility state
    const prevVisibleRef = React.useRef(dataset.isVisible)

    useEffect(() => {
        if (!(dataset.bounds && viewportBounds)) return
        const datasetBounds = boundsStringToArray(dataset.bounds)
        const overlap = !(
            viewportBounds[0] > datasetBounds[2] ||
            viewportBounds[2] < datasetBounds[0] ||
            viewportBounds[1] > datasetBounds[3] ||
            viewportBounds[3] < datasetBounds[1]
        )
        // If visibility changed from false to true and !overlap, show notification
        if (dataset.isVisible && !prevVisibleRef.current && !overlap) {
            setShowNotification(true)
        }
        else if (showNotification && (!dataset.isVisible || overlap || selectedDatasetVersionId !== dataset.id)) {
            setShowNotification(false)
        }

        prevVisibleRef.current = dataset.isVisible
    }, [dataset.isVisible, viewportBounds, selectedDatasetVersionId])

    return (
        <>
            {/*Remove dataset from map dialog*/}
            <Dialog
                open={removeFromMapDialogOpen}
                onClose={handleRemoveFromMapDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    "{dataset.name}" will be deleted from this map. It will
                    remain in the catalog.
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleRemoveFromMapDialogClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveFromMap} autoFocus>
                        Remove from this map
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Delete dataset dialog*/}
            {datasetToDelete && (
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDatasetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Delete Dataset "{datasetToDelete.name}" from Catalog?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to permanently delete the
                            dataset "{datasetToDelete.name}" from the catalog?
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteDatasetClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                deleteDatasetFromCatalog(datasetToDelete.id)
                                handleDeleteDatasetClose()
                            }}
                            autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <QGISInstructionsDialog
                open={qgisDialogOpen}
                onClose={() => setQgisDialogOpen(false)}
                dataset={dataset}
                tileUrl={createTileServerURL(
                    dataset,
                    dataset.selectedDimensions,
                    user
                )}
            />
            <Box
                className={DatasetCardStyle.baseCard}
                onClick={() => {
                    flyToDatasetBounds(dataset)
                }}
                onClickCapture={() => {
                    dispatch({
                        type: 'SET_SELECTED_DATASET',
                        datasetVersionId: dataset.id,
                    })
                }}
            >
                <DatasetTypeIcon dataset={dataset} />
                <Stack width={'100%'}>
                    <Box className={DatasetCardStyle.cardLine}>
                        <ListItemText
                            primary={dataset.name}
                            secondary={secondaryText}
                            primaryTypographyProps={{ variant: 'h6' }}
                            secondaryTypographyProps={{ variant: 'body2' }}
                            className={DatasetCardStyle.cardText}
                            style={{
                                wordBreak: 'break-word',
                                marginRight: 0,
                                paddingRight: 0,
                            }}
                        />{' '}
                        <Box className={DatasetCardStyle.buttonBox}>
                            {dataset.status === 'ready' ? (
                                <>
                                    <IconButton
                                        onClick={handleMenuClick}
                                        className={DatasetCardStyle.hoverIcon}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={menuAnchorEl}
                                        open={isMenuOpen}
                                        onClose={handleMenuClose}
                                    >
                                        {!showRemoveFromMap && (
                                            <MenuItem
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setDeleteDialogOpen(true)
                                                    setDatasetToDelete(dataset)
                                                    handleMenuClose(e)
                                                }}
                                            >
                                                <ListItemText primary="Delete from Catalog" />
                                            </MenuItem>
                                        )}
                                        {showRemoveFromMap && (
                                            <MenuItem
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setRemoveFromMapDialogOpen(
                                                        true
                                                    )
                                                    handleMenuClose(e)
                                                }}
                                            >
                                                <ListItemText primary="Remove from Map" />
                                            </MenuItem>
                                        )}
                                        {dataset.status === 'ready' &&
                                            showVizSettings &&
                                            (dataset.className !==
                                            'EarthEngineDataset' ? (
                                                <MenuItem
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        dispatch({
                                                            type: 'TOGGLE_DATASET_EDITING',
                                                            datasetVersionId:
                                                                dataset.id,
                                                        })
                                                        handleMenuClose(e)
                                                    }}
                                                >
                                                    <ListItemText primary="Edit Visualization" />
                                                </MenuItem>
                                            ) : (
                                                dataset.earthEngineVisualizations.map(
                                                    (viz, index) => (
                                                        <MenuItem
                                                            key={viz.id}
                                                            onClick={(e) => {
                                                                selectEarthEngineVisualization(
                                                                    dataset.id,
                                                                    index
                                                                )
                                                                handleMenuClose(
                                                                    e
                                                                )
                                                            }}
                                                            selected={
                                                                index ===
                                                                dataset.selectedEarthEngineVisualizationIndex
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    viz.name
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )
                                            ))}
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch({
                                                    type: 'SET_DETAILS_DATASET',
                                                    datasetVersionId:
                                                        dataset.id,
                                                })
                                                handleMenuClose(e)
                                            }}
                                        >
                                            <ListItemText primary="View Details" />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                flyToDatasetBounds(dataset)
                                                handleMenuClose(e)
                                            }}
                                        >
                                            <ListItemText primary="Zoom to Fit (z)" />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setQgisDialogOpen(true)
                                                handleMenuClose(e)
                                            }}
                                        >
                                            <ListItemText primary="Open in QGIS" />
                                        </MenuItem>
                                    </Menu>
                                    {!isLatestVersion && (
                                        <Tooltip title={tooltipText}>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    updateDatasetToLatestVersion()
                                                }}
                                            >
                                                <Info
                                                    className={
                                                        DatasetCardStyle.warningIcon
                                                    }
                                                    color="warning"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {visibilityButton}
                                    {addToMapButton}
                                </>
                            ) : (
                                <>
                                    <IconButton
                                        onClick={handleMenuClick}
                                        className={DatasetCardStyle.hoverIcon}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={menuAnchorEl}
                                        open={isMenuOpen}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setDeleteDialogOpen(true)
                                                setDatasetToDelete(dataset)
                                                handleMenuClose(e)
                                            }}
                                        >
                                            <ListItemText primary="Delete from Catalog" />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch({
                                                    type: 'SET_DETAILS_DATASET',
                                                    datasetVersionId:
                                                        dataset.id,
                                                })
                                                handleMenuClose(e)
                                            }}
                                        >
                                            <ListItemText primary="View Details" />
                                        </MenuItem>
                                    </Menu>
                                    {PROCESSING_STATES.includes(
                                        dataset.status
                                    ) && (
                                        <Tooltip title={'Processing'}>
                                            <CircularProgress
                                                size={'20px'}
                                                sx={{
                                                    mr: '6px',
                                                    ml: '9px',
                                                }}
                                                color="primary"
                                            />
                                        </Tooltip>
                                    )}
                                    {dataset.status === 'processing_failed' && (
                                        <Tooltip title={'Processing failed'}>
                                            <Warning
                                                sx={{
                                                    mr: '6px',
                                                    ml: '9px',
                                                }}
                                                color="error"
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                    {hasAnyDimensions && (
                        <Box
                            sx={{
                                paddingRight: '2px',
                                marginBottom: '2px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            <Divider
                                sx={{
                                    marginBottom: '2x',
                                    marginTop: '2px',
                                    marginRight: '6px',
                                }}
                            />

                            {hasTimeDimension && (
                                <>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        {useTimeSlider ? (
                                            <DateSlider dataset={dataset} />
                                        ) : (
                                            <DimensionSelector
                                                dimension={dataset.dimensionInfo.dimensions.find(
                                                    (dim) => dim.name === 'time'
                                                )}
                                                dataset={dataset}
                                            />
                                        )}
                                        <Tooltip
                                            title={
                                                useTimeSlider
                                                    ? 'Switch to dropdown selector'
                                                    : 'Switch to time slider'
                                            }
                                            placement="right"
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setUseTimeSlider(
                                                        !useTimeSlider
                                                    )
                                                }}
                                            >
                                                {useTimeSlider ? (
                                                    <ViewListIcon />
                                                ) : (
                                                    <ViewTimelineIcon />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </>
                            )}
                            {nonTimeDimensions &&
                                nonTimeDimensions.length > 0 &&
                                nonTimeDimensions.map((dim) => (
                                    <DimensionSelector
                                        key={dim.name}
                                        dimension={dim}
                                        dataset={dataset}
                                    />
                                ))}
                        </Box>
                    )}
                </Stack>
            </Box>
            {/* Notification */}
            <InfoAlert
                open={showNotification}
                onClose={() => setShowNotification(false)}
                message={`Dataset "${dataset.name}" is outside the current view. Click the dataset card or use keyboard shortcut (z) to zoom to it.`}
            />
        </>
    )
}

export default DatasetCard
