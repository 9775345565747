export type DatasetClass =
    | 'ZarrDataset'
    | 'ImageDataset'
    | 'VectorDataset'
    | 'STACDataset'

export interface BaseDatasetConfig {
    name: string
    url: string
}

export interface ZarrDatasetConfig extends BaseDatasetConfig {
    class: 'ZarrDataset'
    rename?: Record<string, string>
}

export type GroupBy =
    | 'one_plane'
    | 'time'
    | 'solar_day'
    | 'id'
    | 'time.year'
    | 'time.month'
    | 'time.day'
    | 'time.hour'
    | 'time.minute'
    | 'time.second'
    | 'time.millisecond'
    | 'time.microsecond'
export interface ImageDatasetConfig extends BaseDatasetConfig {
    class: 'ImageDataset'
    bands?: string[]
    filename_date_pattern?: string
    filename_band_pattern?: Record<string, string>
    groupby?: GroupBy
}

export interface VectorDatasetConfig extends BaseDatasetConfig {
    class: 'VectorDataset'
    start_date_field?: string
    end_date_field?: string
}

export type DatasetConfig =
    | ZarrDatasetConfig
    | ImageDatasetConfig
    | VectorDatasetConfig

export const SUPPORTED_VECTOR_EXTENSIONS = [
    '.geojson',
    '.fgb',
    '.parquet',
    '.gpkg',
]
export const SUPPORTED_IMAGE_EXTENSIONS = ['.tif', '.tiff', '.jp2']
export const SUPPORTED_ZARR_EXTENSIONS = ['.zarr']
