import React from 'react'
import { Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'

type UserMenuProps = {
    anchorEl: HTMLElement | null
    onClose: () => void
}

function UserMenu({ anchorEl, onClose }: UserMenuProps) {
    const { logout, userProfile } = useSupabaseContext()

    const redirectToChangePassword = () => {
        document.location.href = '/user/change-password'
    }

    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
            <MenuItem disabled>
                <Stack direction="column" spacing={0}>
                    <Typography variant="body1">{userProfile.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {userProfile.email}
                    </Typography>
                </Stack>
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
            <MenuItem onClick={redirectToChangePassword}>
                Change Password
            </MenuItem>
        </Menu>
    )
}

export default UserMenu
