import React from 'react'
import { ReactNode, useMemo } from 'react'
import { useSupabaseContext } from '../supabase/supabaseContext'
import { BackendClient } from '../../api/backend'
import { BackendContext } from './backendContext'

export default function BackendProvider({ children }: { children: ReactNode }) {
    const { client, user } = useSupabaseContext()

    const backendClient = useMemo(() => {
        if (!user) {
            return null
        }
        const backendUrl = import.meta.env.EARTHSCALE_BACKEND_URL as string
        return new BackendClient(backendUrl, client)
    }, [user])

    if (!backendClient) {
        return null
    }

    return (
        <BackendContext.Provider value={backendClient}>
            {children}
        </BackendContext.Provider>
    )
}
