/* Map layer displaying all visible dataset. */
import React from 'react'
import { Layer, MapRef, Marker, Source } from 'react-map-gl'
import { MarkerEvent } from 'react-map-gl/src/types/events'
import { Dataset } from '../../types/dataset'
import { boundsStringToArray, isFastTiler } from '../../utils'
import { VectorVizParams } from '../../types/viz'
import { createTileServerURL } from '../../api/tiler'
import { Tile } from './MapView'
import { useMapContext } from '../../context/map/mapContext'
import { useTheme } from '@mui/material'
import CommentThreadLayer from '../../components/CommentThreadLayer/CommentThreadLayer'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import {
    TileLoadingLayer,
    TILES_BEING_LOADED_LAYER_ID,
} from './TileLoadingLayer'
import { RasterFillLayer } from './RasterFillLayer/RasterFillLayer'
import { dimensionsToString, layerID, sourceId } from './utils'
import { Place } from '@mui/icons-material'
import { MultiPolygon } from 'geojson'
import mapboxgl, { Map } from 'mapbox-gl'
import { _ } from 'lodash'

type DatasetLayerProps = {
    dataset: Dataset
    mapRef: React.RefObject<MapRef>
}

type VisualizationsLayerProps = {
    currentZoom: number
    mapRef: React.RefObject<MapRef>
    tilesBeingLoaded: Tile[]
}

const MAX_VECTOR_ZOOM = 15

function VectorDatasetLayer({ dataset, mapRef }: DatasetLayerProps) {
    const { user } = useSupabaseContext()
    if (!dataset.vizParams) {
        return null
    }

    const vizParams: VectorVizParams = dataset.vizParams as VectorVizParams
    const mode = vizParams.mode

    type GeometryConfig = {
        [key in 'point' | 'line' | 'polygon']: {
            type: 'circle' | 'line' | 'fill'
            filter: [
                '==',
                ['geometry-type'],
                (
                    | 'Point'
                    | 'LineString'
                    | 'Polygon'
                    | 'MultiLineString'
                    | 'MultiPolygon'
                ),
            ]
            paint: {
                'circle-color'?: string
                'circle-opacity'?: number
                'circle-radius'?: number
                'line-color'?: string
                'line-width'?: number
                'line-opacity'?: number
                'fill-color'?: string
                'fill-opacity'?: number
            }
        }
    }

    const geometryConfig: GeometryConfig = {
        point: {
            type: 'circle',
            filter: ['==', ['geometry-type'], 'Point'],
            paint: {
                'circle-color': vizParams.color,
                'circle-opacity': dataset.opacity / 100,
                'circle-radius': vizParams.width || 5,
            },
        },
        line: {
            type: 'line',
            filter: [
                // @ts-ignore
                'any',
                // @ts-ignore
                ['==', ['geometry-type'], 'LineString'],
                // @ts-ignore
                ['==', ['geometry-type'], 'MultiLineString'],
            ],
            paint: {
                'line-color': vizParams.color,
                'line-width': vizParams.width,
                'line-opacity': dataset.opacity / 100,
            },
        },
        polygon: {
            type: mode === 'fill' ? 'fill' : 'line',
            filter: ['==', ['geometry-type'], 'Polygon'],
            paint:
                mode === 'fill'
                    ? {
                          'fill-color': vizParams.color,
                          'fill-opacity': dataset.opacity / 100,
                      }
                    : {
                          'line-color': vizParams.color,
                          'line-width': vizParams.width,
                          'line-opacity': dataset.opacity / 100,
                      },
        },
    }

    const createLayer = (geometryType: keyof typeof geometryConfig) => {
        const config = geometryConfig[geometryType]
        return (
            <Layer
                id={`${layerID(dataset)}-${geometryType}`}
                type={config.type}
                {...(dataset.source !== 'local'
                    ? { 'source-layer': 'default' }
                    : {})}
                filter={config.filter}
                paint={config.paint}
            />
        )
    }

    if (dataset.source === 'local' && dataset.localDataset) {
        return (
            <Source
                id={sourceId(dataset)}
                type="geojson"
                data={dataset.localDataset.geometry}
                key={sourceId(dataset)}
            >
                {createLayer('point')}
                {createLayer('line')}
                {createLayer('polygon')}
            </Source>
        )
    }

    return (
        <Source
            id={sourceId(dataset)}
            type="vector"
            tiles={[
                createTileServerURL(dataset, dataset.selectedDimensions, user),
            ]}
            bounds={boundsStringToArray(dataset.bounds)}
            key={sourceId(dataset)}
            minzoom={dataset.minZoom || 0}
            // TODO: there appears to be a bug in overscaling here that causes
            // edge effects past this level
            // https://github.com/mapbox/mapbox-gl-js/issues/1205
            maxzoom={Math.min(dataset.maxZoom || MAX_VECTOR_ZOOM, MAX_VECTOR_ZOOM)}
        >
            {createLayer('point')}
            {createLayer('line')}
            {createLayer('polygon')}
        </Source>
    )
}

function RasterDatasetLayer({ dataset, mapRef }: DatasetLayerProps) {
    const [
        [lastSelectedDimensions, currentSelectedDimensions],
        setSelectedDimensions,
    ] = React.useState([null, dataset.selectedDimensions])
    const [renderLastSelectedDimension, setRenderLastSelectedDimension] =
        React.useState(false)

    const { user } = useSupabaseContext()
    const theme = useTheme()

    const isFast = isFastTiler(dataset)
    const minZoom = isFast ? 0 : dataset.minZoom || 0
    const maxZoom = isFast
        ? dataset.rasterOverviewsStartZoom
        : dataset.maxZoom || 22
    const bounds = boundsStringToArray(dataset.bounds)

    function removeRasterLayerAndSource(
        map: Map,
        dataset: Dataset,
        dimensions: { [key: string]: number }
    ) {
        if (dimensions == null) {
            return
        }
        if (!map) {
            return
        }

        if (map.getLayer(layerID(dataset, dimensionsToString(dimensions)))) {
            map.removeLayer(layerID(dataset, dimensionsToString(dimensions)))
        }
        if (map.getSource(sourceId(dataset, dimensionsToString(dimensions)))) {
            map.removeSource(sourceId(dataset, dimensionsToString(dimensions)))
        }
    }

    // Logic to handle the dimension update transitions
    React.useEffect(() => {
        // To avoid errors on first mount
        if (_.isEqual(dataset.selectedDimensions, currentSelectedDimensions))
            return

        setRenderLastSelectedDimension(true)

        const oldLastSelectedDimensions = lastSelectedDimensions
        const newLastSelectedDimensions = currentSelectedDimensions
        const newCurrentSelectedDimensions = dataset.selectedDimensions

        setSelectedDimensions([
            newLastSelectedDimensions,
            newCurrentSelectedDimensions,
        ])

        const map = mapRef.current?.getMap()
        if (oldLastSelectedDimensions != null) {
            removeRasterLayerAndSource(map, dataset, oldLastSelectedDimensions)
        }

        // Cleanup last timestep layer when map is moved
        const moveStartHandler = () => {
            setRenderLastSelectedDimension(false)
            removeRasterLayerAndSource(map, dataset, newLastSelectedDimensions)
        }
        map.on('movestart', moveStartHandler)

        // Cleanup last timestep layer when new layer is completely loaded
        let onRenderedTimeout = null
        const sourceDataHandler = (e: any) => {
            const newCurrentSourceID = sourceId(
                dataset,
                dimensionsToString(newCurrentSelectedDimensions)
            )
            if (
                e.sourceId === newCurrentSourceID &&
                e.isSourceLoaded &&
                e.tile
            ) {
                // the timeout of 500ms seems necessary to avoid flickering
                // when toggling between cached layers
                onRenderedTimeout = setTimeout(() => {
                    setRenderLastSelectedDimension(false)
                    removeRasterLayerAndSource(
                        map,
                        dataset,
                        newLastSelectedDimensions
                    )
                }, 500)
            }
        }
        map.on('sourcedata', sourceDataHandler)

        return () => {
            if (onRenderedTimeout) {
                clearTimeout(onRenderedTimeout)
            }
            map.off('movestart', moveStartHandler)
            map.off('sourcedata', sourceDataHandler)
        }
    }, [dataset.selectedDimensions])

    return (
        <>
            <Source
                id={sourceId(
                    dataset,
                    dimensionsToString(currentSelectedDimensions)
                )}
                type="raster"
                tiles={[
                    createTileServerURL(
                        dataset,
                        currentSelectedDimensions,
                        user
                    ),
                ]}
                bounds={bounds}
                key={sourceId(
                    dataset,
                    dimensionsToString(currentSelectedDimensions)
                )}
                minzoom={minZoom}
                {...(maxZoom ? { maxzoom: maxZoom } : {})}
            >
                <RasterFillLayer
                    id={layerID(
                        dataset,
                        dimensionsToString(currentSelectedDimensions) +
                            '-background'
                    )}
                    dataset={dataset}
                />
                <Layer
                    id={layerID(
                        dataset,
                        dimensionsToString(currentSelectedDimensions)
                    )}
                    type="raster"
                    paint={{
                        'raster-resampling': 'nearest',
                        'raster-opacity': dataset.opacity / 100,
                    }}
                />
            </Source>
            {renderLastSelectedDimension && (
                <Source
                    id={sourceId(
                        dataset,
                        dimensionsToString(lastSelectedDimensions)
                    )}
                    type="raster"
                    tiles={[
                        createTileServerURL(
                            dataset,
                            lastSelectedDimensions,
                            user
                        ),
                    ]}
                    bounds={bounds}
                    key={sourceId(
                        dataset,
                        dimensionsToString(lastSelectedDimensions)
                    )}
                    minzoom={minZoom}
                    {...(maxZoom ? { maxzoom: maxZoom } : {})}
                >
                    <RasterFillLayer
                        id={layerID(
                            dataset,
                            dimensionsToString(lastSelectedDimensions) +
                                '-background'
                        )}
                        dataset={dataset}
                    />
                    <Layer
                        id={layerID(
                            dataset,
                            dimensionsToString(lastSelectedDimensions)
                        )}
                        type="raster"
                        paint={{
                            'raster-resampling': 'nearest',
                            'raster-opacity': dataset.opacity / 100,
                        }}
                    />
                </Source>
            )}
            {Math.round(mapRef.current?.getZoom()) < minZoom && (
                <Source
                    id={dataset.id + '-warning-extent-source'}
                    type="geojson"
                    data={{
                        type: 'Feature',
                        properties: {},
                        geometry: dataset.extent?.coordinates.length
                            ? dataset.extent
                            : {
                                  type: 'Polygon',
                                  coordinates: [
                                      [
                                          [bounds[0], bounds[1]],
                                          [bounds[2], bounds[1]],
                                          [bounds[2], bounds[3]],
                                          [bounds[0], bounds[3]],
                                          [bounds[0], bounds[1]],
                                      ],
                                  ],
                              },
                    }}
                >
                    <Layer
                        id={dataset.id + '-warning-extent-fill'}
                        type="fill"
                        paint={{
                            'fill-color': theme.palette.warning.light,
                            'fill-opacity': 0.1,
                        }}
                    />
                    <Layer
                        id={dataset.id + '-warning-extent-line'}
                        type="line"
                        paint={{
                            'line-color': theme.palette.warning.light,
                            'line-width': 2,
                            'line-opacity': 0.5,
                        }}
                    />
                </Source>
            )}
        </>
    )
}

function ExtentLayer({
    dataset,
    hovered,
}: {
    dataset: Dataset
    hovered: boolean
}) {
    const theme = useTheme()

    let lineColor = '#888'
    let lineWidth = 1
    if (hovered) {
        lineColor = theme.palette.primary.main
        lineWidth = 3
    }

    if (!dataset.extent) {
        return null
    }

    return (
        <React.Fragment key={dataset.id + '-extent-fragment'}>
            <Source
                id={dataset.id + '-extent-source'}
                type="geojson"
                data={{
                    type: 'Feature',
                    properties: {},
                    geometry: dataset.extent,
                }}
            >
                <Layer
                    id={dataset.id + '-extent-layer'}
                    type="line"
                    paint={{
                        'line-color': lineColor,
                        'line-opacity': 1.0,
                        'line-width': lineWidth,
                    }}
                />
            </Source>
        </React.Fragment>
    )
}

function isMultiPolygonEmpty(multipolygon: MultiPolygon) {
    return multipolygon.coordinates.every((polygon) => {
        return polygon.length === 0
    })
}

function ZoomWarningLayer({
    datasets,
    mapRef,
}: {
    datasets: Dataset[]
    mapRef: React.RefObject<MapRef>
}) {
    const theme = useTheme()

    // Get all datasets that need zoom warning
    const datasetsNeedingZoom = datasets.filter((dataset) => {
        const isFast = isFastTiler(dataset)
        const minZoom = isFast ? 0 : dataset.minZoom || 0
        return Math.round(mapRef.current?.getZoom()) < minZoom
    })

    if (datasetsNeedingZoom.length === 0) return null

    // Extract all polygons from dataset extents
    const getAllPolygons = () => {
        const polygons: { dataset: Dataset; coordinates: number[][] }[] = []

        datasetsNeedingZoom.forEach((dataset) => {
            // Handle cases where extent is missing, has empty coordinates, or bounds are global
            const isMissingExtent =
                dataset.extent == null || isMultiPolygonEmpty(dataset.extent)

            if (isMissingExtent) {
                // If no extent, use bounds or default to global bounds
                let bounds = dataset.bounds
                    ? boundsStringToArray(dataset.bounds)
                    : [-180, -90, 180, 90]

                // Clamp bounds to [-180, -90, 180, 90]
                bounds = [
                    Math.max(-180, Math.min(180, bounds[0])),
                    Math.max(-90, Math.min(90, bounds[1])),
                    Math.max(-180, Math.min(180, bounds[2])),
                    Math.max(-90, Math.min(90, bounds[3])),
                ]

                polygons.push({
                    dataset,
                    coordinates: [
                        [bounds[0], bounds[1]],
                        [bounds[2], bounds[1]],
                        [bounds[2], bounds[3]],
                        [bounds[0], bounds[3]],
                        [bounds[0], bounds[1]],
                    ],
                })
                return
            }

            // Add each polygon's rings
            dataset.extent.coordinates.forEach((poly) => {
                poly.forEach((ring) => {
                    polygons.push({ dataset, coordinates: ring })
                })
            })
        })
        return polygons
    }

    // Group overlapping polygons
    const groupOverlappingPolygons = () => {
        const groups: { dataset: Dataset; coordinates: number[][] }[][] = []
        const polygons = getAllPolygons()

        polygons.forEach((polygon) => {
            let foundGroup = false

            // Calculate polygon bounds for quick overlap check
            const bounds = polygon.coordinates.reduce(
                (acc, point) => ({
                    minX: Math.min(acc.minX, point[0]),
                    minY: Math.min(acc.minY, point[1]),
                    maxX: Math.max(acc.maxX, point[0]),
                    maxY: Math.max(acc.maxY, point[1]),
                }),
                {
                    minX: Infinity,
                    minY: Infinity,
                    maxX: -Infinity,
                    maxY: -Infinity,
                }
            )

            // Check each existing group
            for (const group of groups) {
                // Check if polygon overlaps with any polygon in the group
                const overlapsWithGroup = group.some((groupPolygon) => {
                    const groupBounds = groupPolygon.coordinates.reduce(
                        (acc, point) => ({
                            minX: Math.min(acc.minX, point[0]),
                            minY: Math.min(acc.minY, point[1]),
                            maxX: Math.max(acc.maxX, point[0]),
                            maxY: Math.max(acc.maxY, point[1]),
                        }),
                        {
                            minX: Infinity,
                            minY: Infinity,
                            maxX: -Infinity,
                            maxY: -Infinity,
                        }
                    )

                    return !(
                        bounds.maxX < groupBounds.minX ||
                        bounds.minX > groupBounds.maxX ||
                        bounds.maxY < groupBounds.minY ||
                        bounds.minY > groupBounds.maxY
                    )
                })

                if (overlapsWithGroup) {
                    group.push(polygon)
                    foundGroup = true
                    break
                }
            }

            // If no overlapping group found, create new group
            if (!foundGroup) {
                groups.push([polygon])
            }
        })

        return groups
    }

    const polygonGroups = groupOverlappingPolygons()

    // Calculate appropriate font size for a bounds
    const calculateFontSize = (bounds: {
        minX: number
        minY: number
        maxX: number
        maxY: number
    }) => {
        if (!mapRef.current) return 24

        const sw = mapRef.current.project([bounds.minX, bounds.minY])
        const ne = mapRef.current.project([bounds.maxX, bounds.maxY])
        const width = Math.abs(ne.x - sw.x)
        const height = Math.abs(ne.y - sw.y)
        const minDimension = Math.min(width, height)
        const baseFontSize = 24
        return Math.min(
            baseFontSize,
            Math.max(6, Math.floor(minDimension / 10))
        )
    }

    return (
        <>
            {polygonGroups.map((group, groupIndex) => {
                // Calculate combined bounds for the group
                let groupBounds = group.reduce(
                    (acc, { coordinates }) => {
                        coordinates.forEach((point) => {
                            acc.minX = Math.min(acc.minX, point[0])
                            acc.minY = Math.min(acc.minY, point[1])
                            acc.maxX = Math.max(acc.maxX, point[0])
                            acc.maxY = Math.max(acc.maxY, point[1])
                        })
                        return acc
                    },
                    {
                        minX: Infinity,
                        minY: Infinity,
                        maxX: -Infinity,
                        maxY: -Infinity,
                    }
                )

                // Clamp bounds to [-180, -90, 180, 90]
                groupBounds = {
                    minX: Math.max(-180, Math.min(180, groupBounds.minX)),
                    minY: Math.max(-90, Math.min(90, groupBounds.minY)),
                    maxX: Math.max(-180, Math.min(180, groupBounds.maxX)),
                    maxY: Math.max(-90, Math.min(90, groupBounds.maxY)),
                }

                // Calculate center of the group
                const centerLng = (groupBounds.minX + groupBounds.maxX) / 2
                const centerLat = (groupBounds.minY + groupBounds.maxY) / 2

                const fontSize = calculateFontSize(groupBounds)

                return (
                    <Marker
                        key={`zoom-warning-${groupIndex}`}
                        longitude={centerLng}
                        latitude={centerLat}
                        anchor="center"
                    >
                        <div
                            style={{
                                color: theme.palette.warning.dark,
                                fontSize: `${fontSize}px`,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                pointerEvents: 'none',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            ZOOM IN TO RENDER
                        </div>
                    </Marker>
                )
            })}
        </>
    )
}

function VisualizationsLayer({
    currentZoom,
    mapRef,
    tilesBeingLoaded,
}: VisualizationsLayerProps) {
    const { state, dispatch } = useMapContext()

    const datasets = [...state.current.datasets]
    for (const catalogDataset of state.catalog) {
        if (!datasets.some((d) => d.id === catalogDataset.id)) {
            datasets.push(catalogDataset)
        }
    }

    const readyDatasets = datasets.filter((dataset) => {
        return dataset.status === 'ready'
    })

    const hasVizParams = (dataset: Dataset) =>
        dataset.vizParams !== undefined ||
        (dataset.className === 'EarthEngineDataset' &&
            dataset?.earthEngineVisualizations.length > 0) ||
        dataset.className === 'TileServerDataset'
    const visibleDatasets = readyDatasets.filter(
        (dataset) => dataset.isVisible && hasVizParams(dataset)
    )

    const hoveredDatasetId = state.hoveredCardDatasetVersionId
    const datasetsToShowBounds = readyDatasets.filter(
        (dataset) =>
            dataset.id != hoveredDatasetId &&
            (dataset.isVisible || state.current.datasets.includes(dataset))
    )
    const hoveredDataset = datasets.find(
        (dataset) => dataset.id == hoveredDatasetId
    )

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (mapRef.current) {
                const reversedVisibleDatasets = [...visibleDatasets].reverse()

                // First move all regular dataset layers
                reversedVisibleDatasets.forEach((dataset) => {
                    if (dataset.type === 'vector') {
                        // Move all geometry type layers
                        ;['point', 'polygon'].forEach((type) => {
                            const id = `${layerID(dataset)}-${type}`
                            if (mapRef.current?.getLayer(id)) {
                                mapRef.current.moveLayer(id)
                            }
                        })
                    } else {
                        // For raster datasets, first move the custom background layer
                        const backgroundLayerId = layerID(
                            dataset,
                            dimensionsToString(dataset.selectedDimensions) +
                                '-background'
                        )
                        if (mapRef.current?.getLayer(backgroundLayerId)) {
                            mapRef.current.moveLayer(backgroundLayerId)
                        }
                        // Then move the raster layer on top of it
                        mapRef.current.moveLayer(
                            layerID(
                                dataset,
                                dimensionsToString(dataset.selectedDimensions)
                            )
                        )
                    }
                })

                // Then move all warning extent layers to the top
                reversedVisibleDatasets.forEach((dataset) => {
                    const warningFillId = `${dataset.id}-warning-extent-fill`
                    const warningLineId = `${dataset.id}-warning-extent-line`
                    if (mapRef.current?.getLayer(warningFillId)) {
                        mapRef.current.moveLayer(warningFillId)
                    }
                    if (mapRef.current?.getLayer(warningLineId)) {
                        mapRef.current.moveLayer(warningLineId)
                    }
                })

                // Finally move loading layer to the very front if it exists
                if (mapRef.current.getLayer(TILES_BEING_LOADED_LAYER_ID)) {
                    mapRef.current.moveLayer(TILES_BEING_LOADED_LAYER_ID)
                }
            }
        }, 100)

        return () => clearInterval(interval)
    }, [visibleDatasets, mapRef])

    return (
        <>
            <CommentThreadLayer />
            {visibleDatasets.map((dataset: Dataset) => (
                <React.Fragment key={dataset.id + '-fragment'}>
                    {dataset.type === 'vector' ? (
                        <VectorDatasetLayer
                            key={dataset.id + '-vector'}
                            dataset={dataset}
                            mapRef={mapRef}
                        />
                    ) : (
                        <RasterDatasetLayer
                            key={dataset.id + '-raster'}
                            dataset={dataset}
                            mapRef={mapRef}
                        />
                    )}
                </React.Fragment>
            ))}
            {datasetsToShowBounds.map((dataset: Dataset) => (
                <ExtentLayer
                    key={dataset.id + '-extent'}
                    dataset={dataset}
                    hovered={false}
                />
            ))}
            {hoveredDataset && (
                <ExtentLayer
                    key={hoveredDataset.id + '-extent'}
                    dataset={hoveredDataset}
                    hovered={true}
                />
            )}
            <TileLoadingLayer tilesBeingLoaded={tilesBeingLoaded} />
            <ZoomWarningLayer datasets={visibleDatasets} mapRef={mapRef} />
            {state.clickedLngLat && (
                <Marker
                    longitude={state.clickedLngLat[0]}
                    latitude={state.clickedLngLat[1]}
                    anchor="bottom"
                    onClick={(
                        e: MarkerEvent<mapboxgl.Marker, globalThis.MouseEvent>
                    ) => {
                        e.originalEvent.stopPropagation()
                        // Allow clicking to remove
                        dispatch({ type: 'SET_CLICKED_LAT_LNG', lngLat: null })
                    }}
                >
                    <div style={{ transform: 'translateY(8px)' }}>
                        <Place
                            sx={{
                                color: 'primary.main',
                                fontSize: 32,
                                filter: 'drop-shadow(0 0 2px black)',
                            }}
                        />
                    </div>
                </Marker>
            )}
        </>
    )
}

export default VisualizationsLayer
