import {
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Stack,
    Tooltip,
    useTheme,
} from '@mui/material'
import DatasetCard from '../DatasetCard/DatasetCard'
import * as React from 'react'
import { Dataset } from '../../types/dataset'
import { useMapContext } from '../../context/map/mapContext'
import { Add, DragHandle, DragIndicator, Remove } from '@mui/icons-material'
import { useCallback } from 'react'
import { debounce } from 'lodash'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'

type DatasetListProps = {
    datasets: Dataset[]
    flyToDatasetBounds: (dataset: Dataset) => void
    showVizSettings: boolean
    showSelector: boolean
    showRemoveFromMap: boolean
    disableNonActive: boolean
    reorderable?: boolean
}

function DatasetList({
    datasets,
    flyToDatasetBounds,
    showVizSettings,
    showSelector,
    showRemoveFromMap,
    disableNonActive,
    reorderable,
}: DatasetListProps) {
    const theme = useTheme()
    const { dispatch, updateLayerOrder } = useMapContext()

    const handleDragEnd = (result) => {
        if (!result.destination) return

        const items = Array.from(datasets)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        // Update the local state immediately
        dispatch({
            type: 'UPDATE_LAYER_ORDER',
            datasets: items,
        })

        // Then persist to the database
        const reorderedEntries = items.map((dataset, index) => ({
            layerOrder: index,
            datasetVersionId: dataset.id,
        }))
        updateLayerOrder(reorderedEntries)
    }

    const debouncedMouseEnter = useCallback(
        debounce((datasetVersionId) => {
            dispatch({
                type: 'HOVER_CARD',
                datasetVersionId: datasetVersionId,
            })
        }, 100),
        []
    )

    const debouncedMouseLeave = useCallback(
        debounce(() => {
            dispatch({
                type: 'HOVER_CARD',
                datasetVersionId: null,
            })
        }, 100),
        []
    )

    const DatasetItems = datasets.map((dataset, index) => {
        const listItem = (
            <ListItem
                disablePadding
                id={index === 0 ? 'firstDataset' : undefined}
                disabled={disableNonActive && !dataset.isVisible}
            >
                <ListItemButton
                    sx={{
                        borderRadius: '8px',
                        paddingLeft: '8px',
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                    onMouseEnter={() => debouncedMouseEnter(dataset.id)}
                    onMouseLeave={() => debouncedMouseLeave()}
                >
                    <DatasetCard
                        dataset={dataset}
                        flyToDatasetBounds={flyToDatasetBounds}
                        showVizSettings={showVizSettings}
                        showRemoveFromMap={showRemoveFromMap}
                        showAddToMap={
                            showSelector && dataset.status === 'ready'
                        }
                    />
                </ListItemButton>
            </ListItem>
        )

        return reorderable ? (
            <Draggable key={dataset.id} draggableId={dataset.id} index={index}>
                {(provided, snapshot) => {
                    const draggableListItem = React.cloneElement(listItem, {
                        sx: {
                            ...listItem.props.sx,
                            opacity: snapshot.isDragging ? 0.6 : 1,
                        },
                    })
                    return (
                        <Stack
                            direction="row"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                        >
                            <div {...provided.dragHandleProps}>
                                <DragIndicator
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        marginTop: '8px',
                                    }}
                                />
                            </div>
                            {draggableListItem}
                        </Stack>
                    )
                }}
            </Draggable>
        ) : (
            <React.Fragment key={dataset.id}>{listItem}</React.Fragment>
        )
    })

    const ListContent = reorderable ? (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="dataset-list">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {DatasetItems}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    ) : (
        DatasetItems
    )

    return (
        <List
            id={datasets.length === 0 ? 'firstDataset' : undefined}
            disablePadding
        >
            {ListContent}
        </List>
    )
}

export default DatasetList
