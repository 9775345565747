import { Activity, ActivityState, ActivityType } from './activityTypes'
import { v4 as uuid } from 'uuid'

export const initialActivityState: ActivityState = {
    activities: [],
}

type ActivityAction = {
    action: ActivityType
    userId: string
    datasetVersionId: string
    payload?: Activity['payload']
}

export const activityReducer = (
    state: ActivityState,
    action: ActivityAction
): ActivityState => {
    const newActivity: Activity = {
        id: uuid(),
        action: action.action,
        userId: action.userId,
        datasetVersionId: action.datasetVersionId,
        timestamp: new Date().toISOString(),
        payload: action.payload,
    }

    return {
        activities: [newActivity, ...state.activities],
    }
}
