import React from 'react'
import {
    createClient,
    Session,
    AuthChangeEvent,
    SupabaseClient,
} from '@supabase/supabase-js'
import { fetchEarthscaleUser, fetchUserProfiles } from '../../api/user'
import { EarthscaleUser, UserProfile } from '../../types/user'
import Login from '../../pages/Login/Login'
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen'
import { SupabaseContext } from './supabaseContext'
import * as Sentry from '@sentry/browser'

// How many seconds before jwt expires it should be refreshed
const refreshAuthTokenIfExpiresInSeconds = 120
const checkAuthTokenIntervalSeconds = 60

interface CurrentUserState {
    user: EarthscaleUser
    userProfile: UserProfile
}

async function fetchUserData(): Promise<CurrentUserState | null> {
    try {
        const user = await fetchEarthscaleUser(supabaseClient)

        const userProfiles = await fetchUserProfiles(supabaseClient, [user.id])
        if (!userProfiles || userProfiles.length === 0) {
            Sentry.captureEvent({
                level: 'error',
                message: 'User profile not found',
                extra: {
                    user_id: user.id,
                },
            })
            return null
        }
        return { user, userProfile: userProfiles[0] }
    } catch (error) {
        Sentry.captureEvent({
            level: 'error',
            message: 'Error fetching user data',
            extra: {
                error,
            },
        })
        return null
    }
}

const url = new URL(window.location.href)
const supabaseUrl = url.hostname.startsWith('app.proxy')
    ? import.meta.env.EARTHSCALE_SUPABASE_PROXY_URL
    : import.meta.env.EARTHSCALE_SUPABASE_URL

const supabaseClient = createClient(
    supabaseUrl,
    import.meta.env.EARTHSCALE_SUPABASE_ANON_KEY,
    {
        auth: {
            autoRefreshToken: true,
        },
    }
)

const SupabaseProvider = ({ children }) => {
    const loginMapRef = React.useRef(null)

    // this is set when we have a login via supabase
    const [currentUserId, setCurrentUserId] = React.useState<string | null>(
        null
    )
    // this is the dependent state that is set after fetching user info
    // from the database
    const [currentUserState, setCurrentUserState] =
        React.useState<CurrentUserState | null>(null)

    const logout = async () => {
        // Then sign out from Supabase
        await supabaseClient.auth.signOut()
        setCurrentUserState(null)
        setCurrentUserId(null)
    }

    React.useEffect(() => {
        if (currentUserId == null) {
            setCurrentUserState(null)
            return
        }

        const refreshUserData = async () => {
            const userData = await fetchUserData()
            setCurrentUserState(userData)
        }
        refreshUserData()
    }, [currentUserId])

    // Handle Supabase authentication
    React.useEffect(() => {
        const {
            data: { subscription },
        } = supabaseClient.auth.onAuthStateChange(
            (event: AuthChangeEvent, session: Session | null) => {
                Sentry.setUser({
                    id: session?.user?.id,
                    email: session?.user?.email,
                })
                // supabase does not allow us to use the client in this callback,
                // so we have to use an indirection to avoid a deadlock
                // https://supabase.com/docs/reference/javascript/auth-onauthstatechange
                setCurrentUserId(session?.user?.id)
            }
        )

        return () => {
            subscription?.unsubscribe()
        }
    }, [supabaseClient])

    async function refreshAuthToken() {
        // For Supabase, use the existing refresh logic
        const { data, error } = await supabaseClient.auth.refreshSession()

        if (error) {
            console.error('Error refreshing session:', error)
            logout()
            return null
        }
        // We've seen issues where an old JWT token is used after the database
        // is reset. We won't be able to fetch the user in this case, so we'll
        // just log out the user.
        const userResponse = await supabaseClient.auth.getUser()
        if (userResponse.error || !userResponse.data) {
            logout()
            return null
        }
        return data.session.access_token
    }

    // This auto-refreshes the auth token earlier than supabase
    React.useEffect(() => {
        if (!currentUserId) return // Don't refresh if not logged in

        const refreshSession = async () => {
            // getSession only refreshes the token if it is already expired
            const {
                data: { session },
            } = await supabaseClient.auth.getSession()
            if (session == null) {
                return
            }
            const nowSeconds = Date.now() / 1000
            const refreshAtDeadline =
                nowSeconds + refreshAuthTokenIfExpiresInSeconds

            const jwtExpiresAt = session?.expires_at
            let needsRefresh =
                jwtExpiresAt == null || jwtExpiresAt <= refreshAtDeadline
            if (!needsRefresh) {
                return
            }
            await refreshAuthToken()
        }

        const interval = setInterval(() => {
            refreshSession()
        }, 1000 * checkAuthTokenIntervalSeconds)

        return () => clearInterval(interval)
    }, [currentUserId])

    if (currentUserId == null) {
        return <Login supabaseClient={supabaseClient} mapRef={loginMapRef} />
    } else if (currentUserState == null) {
        return <LoadingScreen />
    } else {
        return (
            <SupabaseContext.Provider
                value={{
                    client: supabaseClient,
                    user: currentUserState.user,
                    userProfile: currentUserState.userProfile,
                    logout: logout,
                    loginMapRef: loginMapRef,
                }}
            >
                {children}
            </SupabaseContext.Provider>
        )
    }
}

export default SupabaseProvider
