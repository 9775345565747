import React, { useEffect, useState } from 'react'
import {
    addComment,
    updateComment,
    updateThreadTitle,
} from '../../api/comments'
import { Comment, CommentThread } from '../../types/comments'
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
    Avatar,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useCommentContext } from '../../context/comment/commentContext'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { MAX_COMMENT_LENGTH, MAX_COMMENT_TITLE_LENGTH } from '../../constants'
import { formatDateTimeForDisplay } from '../../utils'
import { UserAvatar } from '../UserAvatar/UserAvatar'

// heuristic to determine a good number of rows
function getCommentEditRows(content: string) {
    return Math.min(
        50,
        Math.max(content.split('\n').length, content.length / 80)
    )
}

const Comment = ({
    comment,
    editingComment,
    setEditingComment,
    submitEditingComment,
    isEditable,
}: {
    comment: Comment
    editingComment: Comment | null
    setEditingComment: (comment: Comment) => void
    submitEditingComment: () => void
    isEditable: boolean
}) => {
    // If no user data is available, show a fallback
    const userDisplayName =
        comment.user?.name || comment.user?.email || 'Unknown User'

    return (
        <Stack spacing={1}>
            <Stack spacing={1} direction="row" alignItems="center">
                {comment.user ? (
                    <UserAvatar userProfile={comment.user} size={16} />
                ) : (
                    <Avatar sx={{ width: 16, height: 16 }}>?</Avatar>
                )}
                <Typography variant="caption">{userDisplayName}</Typography>
                <Typography variant="caption" color="text.secondary">
                    {formatDateTimeForDisplay(comment.createdAt)}
                </Typography>
                {
                    // if updated is preset, show edited with tooltip
                    comment.updatedAt &&
                    comment.updatedAt.getTime() !==
                    comment.createdAt.getTime() && (
                        <Tooltip
                            title={formatDateTimeForDisplay(
                                comment.updatedAt
                            )}
                        >
                            <Typography
                                component="span"
                                variant="caption"
                                sx={{
                                    marginLeft: '0.5em',
                                    color: 'text.secondary',
                                }}
                            >
                                Edited
                            </Typography>
                        </Tooltip>
                    )
                }
            </Stack>
            {isEditable && editingComment?.id === comment.id ? (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        submitEditingComment()
                    }}
                >
                    <TextField
                        fullWidth
                        multiline
                        rows={getCommentEditRows(editingComment.content)}
                        value={editingComment.content}
                        onChange={(e) =>
                            setEditingComment({
                                ...editingComment,
                                content: e.target.value,
                            })
                        }
                        variant="outlined"
                        inputProps={{
                            maxLength: MAX_COMMENT_LENGTH,
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                                e.preventDefault()
                                setEditingComment(null)
                            }
                            if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                e.preventDefault()
                                submitEditingComment()
                            }
                        }}
                        helperText="Press ⌘+Enter to submit"
                        size="small"
                        autoFocus
                    />
                </form>
            ) : (
                <Typography
                    onClick={() => {
                        if (isEditable) {
                            setEditingComment(comment)
                        }
                    }}
                    sx={{
                        cursor: isEditable ? 'pointer' : 'default',
                        '&:hover': {
                            backgroundColor:
                                isEditable && editingComment?.id === comment.id
                                    ? 'rgba(255, 255, 255, 0.1)'
                                    : 'transparent',
                            borderRadius: '4px',
                            transition: 'background-color 0.2s',
                        },
                        px: 1,
                        paddingLeft: 0,
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                    }}
                >
                    {comment.content}
                </Typography>
            )}
        </Stack>
    )
}

const NewReplyBox = ({
    threadId,
    onCommentAdded,
}: {
    threadId: string
    onCommentAdded: (newComment: Comment) => void
}) => {
    const [comment, setComment] = useState('')
    const { client, userProfile } = useSupabaseContext()
    const emptyCommentText = 'Add a comment...'

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!comment.trim()) return

        try {
            const newComment = await addComment(
                threadId,
                comment,
                client,
                userProfile
            )
            setComment('') // Clear input after successful submission
            onCommentAdded(newComment)
        } catch (error) {
            console.error('Failed to add comment:', error)
        }
    }

    return (
        <Box sx={{ px: 2, pt: 2, pb: 1 }}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        multiline
                        rows={getCommentEditRows(comment)}
                        placeholder={emptyCommentText}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        onKeyDown={(e) => {
                            if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                e.preventDefault()
                                handleSubmit(e)
                            }
                        }}
                        inputProps={{
                            maxLength: MAX_COMMENT_LENGTH,
                        }}
                        variant="outlined"
                        size="small"
                        helperText="Press ⌘+Enter to submit"
                        autoFocus
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!comment.trim()}
                            size="small"
                            sx={{ minWidth: '80px' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Stack>
            </form>
        </Box>
    )
}

const CommentThreadBox = () => {
    const { state, dispatch, selectThread } = useCommentContext()
    const { client, userProfile } = useSupabaseContext()
    const { threads, selectedThreadId } = state
    const [editingComment, setEditingComment] =
        useState<Comment | null>(null)
    const [isEditingTitle, setIsEditingTitle] = useState(false)
    const [title, setTitle] = useState('')
    const [selectedThread, setSelectedThread] = useState<CommentThread | null>(
        null
    )

    useEffect(() => {
        if (!selectedThreadId) {
            setSelectedThread(null)
            setIsEditingTitle(false)
            setTitle('')
            return
        }
        let searchResult = threads.find(
            (thread) => thread.id === selectedThreadId
        )
        if (!searchResult) {
            setSelectedThread(null)
            setIsEditingTitle(false)
            setTitle('')
            return
        }
        const isThreadChanged = searchResult.id !== selectedThread?.id
        if (isThreadChanged) {
            setSelectedThread(searchResult)
            setIsEditingTitle(false)
            setTitle(searchResult.title)
        } else {
            setSelectedThread(searchResult)
            if (!isEditingTitle) {
                setTitle(searchResult.title)
            }
        }
    }, [selectedThreadId, threads])

    const handleTitleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (selectedThread) {
            try {
                const updatedThread = {
                    ...selectedThread,
                    title: title.trim(),
                }
                dispatch({ type: 'UPDATE_THREAD', thread: updatedThread })
                setIsEditingTitle(false)
                await updateThreadTitle(
                    selectedThreadId,
                    updatedThread.title,
                    client,
                    userProfile
                )
            } catch (error) {
                console.error('Failed to update thread name:', error)
            }
        }
    }

    const handleClose = () => {
        selectThread(null)
        setIsEditingTitle(false)
        setTitle('')
    }

    const submitEditingComment = async () => {
        if (!editingComment) return

        try {
            const updatedComment = {
                ...editingComment,
                content: editingComment.content.trim(),
            }
            setEditingComment(null)
            dispatch({
                type: 'UPDATE_COMMENT',
                comment: updatedComment,
            })
            await updateComment(
                updatedComment.id,
                updatedComment.content,
                client,
                userProfile
            )
        } catch (error) {
            console.error('Failed to update comment:', error)
        }
    }

    return (
        selectedThreadId && (
            <Paper
                sx={{
                    position: 'absolute',
                    right: 16,
                    width: {
                        xs: '300px',
                        '@media (min-width:1000px)': {
                            width: '400px',
                        },
                    },
                    zIndex: 1000,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        {isEditingTitle ? (
                            <form onSubmit={handleTitleSubmit}>
                                <TextField
                                    size="small"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Escape') {
                                            e.preventDefault()
                                            setIsEditingTitle(false)
                                        }
                                        if (e.key === 'Enter') {
                                            handleTitleSubmit(e)
                                        }
                                    }}
                                    inputProps={{
                                        maxLength: MAX_COMMENT_TITLE_LENGTH,
                                    }}
                                    autoFocus
                                    multiline
                                    placeholder="Enter thread name"
                                    sx={{ width: '100%' }}
                                />
                            </form>
                        ) : (
                            <Typography
                                variant="h6"
                                onClick={() => {
                                    if (selectedThread?.user?.id === userProfile.id) {
                                        setIsEditingTitle(true)
                                    }
                                }}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: '4px',
                                        transition: 'background-color 0.2s',
                                    },
                                    px: 1,
                                    wordBreak: 'break-word',
                                }}
                            >
                                {selectedThread?.title || 'Comment thread'}
                            </Typography>
                        )}
                        <Typography
                            color="text.secondary"
                            variant="caption"
                            sx={{ px: 1 }}
                        >
                            X, Y:{' '}
                            {selectedThread?.location?.coordinates
                                ?.map((coord) => coord.toFixed(6))
                                .join(', ')}
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Divider />
                <Box
                    sx={{
                        p: 2,
                        maxHeight: 'calc(70vh)',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    }}
                >
                    {selectedThread?.comments.map((comment, index) => (
                        <React.Fragment key={comment.id}>
                            <Comment
                                comment={comment}
                                editingComment={editingComment}
                                setEditingComment={setEditingComment}
                                submitEditingComment={submitEditingComment}
                                isEditable={comment.user?.id === userProfile.id}
                            />
                            {index < selectedThread.comments.length - 1 && (
                                <Divider sx={{ my: 2 }} />
                            )}
                        </React.Fragment>
                    ))}
                </Box>
                <Divider />
                <NewReplyBox
                    threadId={selectedThreadId}
                    onCommentAdded={(newComment: Comment) =>
                        dispatch({
                            type: 'ADD_COMMENT',
                            comment: newComment,
                        })
                    }
                />
            </Paper>
        )
    )
}

export default CommentThreadBox
