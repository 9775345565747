import * as React from 'react'
import { Box, Button, Typography } from '@mui/material'
import AuthLayout from '../../components/AuthLayout/AuthLayout'

function ConfirmSignup() {
    const mapRef = React.useRef(null)
    const params = new URLSearchParams(window.location.search)
    const paramName = 'confirmation-url'
    const confirmationUrl = params.get(paramName)
    const [error, setError] = React.useState<string | null>(null)

    React.useEffect(() => {
        document.title = 'Confirm Signup - Earthscale'

        // Validate the URL parameter
        if (!confirmationUrl) {
            setError(`Missing '${paramName}' parameter`)
        } else if (!confirmationUrl.startsWith('https://')) {
            setError(`Invalid '${paramName}' parameter: must use HTTPS`)
        } else if (!URL.canParse(confirmationUrl)) {
            setError(`Invalid '${paramName}' parameter: malformed URL`)
        } else {
            setError(null)
        }
    }, [confirmationUrl])

    const handleConfirm = () => {
        if (confirmationUrl && !error) {
            window.location.href = confirmationUrl
        }
    }

    return (
        <AuthLayout mapRef={mapRef}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h5"
                    align={'center'}
                    sx={{
                        marginTop: '14px',
                    }}
                >
                    Confirm Your Account
                </Typography>
                {error && (
                    <Typography
                        variant="body2"
                        align={'center'}
                        color="error"
                        sx={{ mt: 1 }}
                    >
                        ERROR: {error}
                    </Typography>
                )}
                {!error && (
                    <>
                        <Button
                            variant="contained"
                            onClick={handleConfirm}
                            fullWidth
                            disabled={!!error}
                        >
                            Confirm
                        </Button>
                        <Typography variant="body1" align={'center'}>
                            Click above to confirm your email address and
                            complete signup.
                        </Typography>
                    </>
                )}
            </Box>
        </AuthLayout>
    )
}

export default ConfirmSignup
