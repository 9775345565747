import * as React from 'react'
import Box from '@mui/material/Box'
import { Map as MapGLMap } from 'react-map-gl'
import MapboxSatellite from '../MapView/MapboxSatellite'
import { Divider, Paper, useTheme } from '@mui/material'
import logo_light from '../../assets/earthscale-logo-light.svg'
import styles from './AuthLayout.module.css'

const startLocations = [
    {
        latitude: 38.6559,
        longitude: -101.9236,
        zoom: 12,
    },
    {
        latitude: 35.615,
        longitude: 139.653,
        zoom: 12,
    },
    {
        latitude: 46.44,
        longitude: 7.747,
        zoom: 12,
    },
    {
        latitude: 25.636,
        longitude: 10.077,
        zoom: 12,
    },
    {
        latitude: 64.46217212812809,
        longitude: 10.74625177968295,
        zoom: 12,
    },
    {
        latitude: 3.7440868431601615,
        longitude: -60.2883514844536,
        zoom: 12,
    },
    {
        latitude: 22.559,
        longitude: 32.314,
        zoom: 12,
    },
]

const randomStartLocation =
    startLocations[Math.floor(Math.random() * startLocations.length)]

function wrapLongitude(longitude: number): number {
    if (longitude > 180) {
        return longitude - 360
    }
    if (longitude < -180) {
        return longitude + 360
    }
    return longitude
}

function wrapLatitude(latitude: number): number {
    if (latitude > 90) {
        return latitude - 180
    }
    if (latitude < -90) {
        return latitude + 180
    }
    return latitude
}

interface AuthLayoutProps {
    children: React.ReactNode
    mapRef: React.RefObject<any>
}

function AuthLayout({ children, mapRef }: AuthLayoutProps) {
    const theme = useTheme()

    // Move login map
    const distancePerSecond = 0.001
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (mapRef.current === null) {
                return
            }
            const center = mapRef.current.getCenter()
            center.lng = wrapLongitude(center.lng + distancePerSecond)
            center.lat = wrapLatitude(center.lat + distancePerSecond)
            mapRef.current.easeTo({
                center,
                essential: true,
                duration: 1000,
                easing: (n) => n,
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [mapRef])

    return (
        <Box className={styles.container}>
            <MapGLMap
                initialViewState={randomStartLocation}
                ref={mapRef}
                interactive={false}
                attributionControl={true}
                mapboxAccessToken={import.meta.env.EARTHSCALE_MAPBOX_TOKEN}
                logoPosition={'bottom-left'}
            >
                <MapboxSatellite />
            </MapGLMap>

            <Paper className={styles.authBox}>
                <img
                    src={logo_light as string}
                    alt="Logo"
                    className={styles.logo}
                />
                <Divider
                    sx={{ width: '100%', color: theme.palette.primary.light }}
                />
                {children}
            </Paper>
        </Box>
    )
}

export default AuthLayout
