import * as React from 'react'
import { useEffect } from 'react'
import { Box, Menu, Slider, Tooltip, Typography } from '@mui/material'
import raster from '../../assets/icons/raster.svg'
import DatasetCardStyle from './DatasetCard.module.css'
import { Dataset } from '../../types/dataset'
import { useMapContext } from '../../context/map/mapContext'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'

type DatasetTypeIconProps = {
    dataset: Dataset
}

export default function DatasetTypeIcon({ dataset }: DatasetTypeIconProps) {
    const { updateVizParams } = useMapContext()
    const [contextMenu, setContextMenu] = React.useState<{
        x: number
        y: number
    } | null>(null)

    const backgroundColor =
        dataset.vizParams && 'color' in dataset.vizParams
            ? dataset.vizParams.color
            : 'white'

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setContextMenu({ x: event.clientX, y: event.clientY })
    }

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
        setContextMenu(null)
    }

    const [localFillLayerEnabled, setLocalFillLayerEnabled] = React.useState(
        dataset.vizParams?.transparencyFillLayerEnabled ?? false
    )
    const [localFillLayerOpacity, setLocalFillLayerOpacity] = React.useState(
        dataset.vizParams?.transparencyFillLayerOpacity ?? 0.5
    )

    useEffect(() => {
        if (
            localFillLayerEnabled ===
                dataset.vizParams?.transparencyFillLayerEnabled &&
            localFillLayerOpacity ===
                dataset.vizParams?.transparencyFillLayerOpacity
        ) {
            return
        }
        const timer = setTimeout(() => {
            if (dataset?.vizParams == null) {
                return
            }
            updateVizParams(
                dataset,
                {
                    ...dataset.vizParams,
                    transparencyFillLayerEnabled: localFillLayerEnabled,
                    transparencyFillLayerOpacity: localFillLayerOpacity,
                },
                dataset.vizType
            )
        }, 500)
        return () => clearTimeout(timer)
    }, [localFillLayerEnabled, localFillLayerOpacity])

    const handleChangeFillLayerOpacity = (
        e: Event,
        newValue: number | number[]
    ) => {
        e.stopPropagation()
        const value = newValue as number
        setLocalFillLayerOpacity(value)
    }

    const handleToggleFillLayer = (e: React.MouseEvent) => {
        e.stopPropagation()
        setLocalFillLayerEnabled(!localFillLayerEnabled)
        updateVizParams(
            dataset,
            {
                ...dataset.vizParams,
                transparencyFillLayerEnabled: localFillLayerEnabled,
                transparencyFillLayerOpacity: localFillLayerOpacity,
            },
            dataset.vizType
        )
    }

    if (dataset.type === 'raster') {
        return (
            <Tooltip
                placement="top"
                title="Click to toggle fill layer. Right-click to adjust opacity."
            >
                <Box
                    onClick={handleToggleFillLayer}
                    onContextMenu={handleContextMenu}
                    className={`${DatasetCardStyle.typeIcon} ${
                        localFillLayerEnabled
                            ? ''
                            : DatasetCardStyle.disabledOverlay
                    }`}
                >
                    <img
                        src={raster as string}
                        style={{
                            width: '16px',
                            height: '16px',
                        }}
                        alt="Raster"
                    />
                    {dataset.rasterOverviewsStartZoom != null && (
                        <RocketLaunchIcon
                            sx={{
                                width: '13px',
                                height: '13px',
                                position: 'absolute',
                                top: '8px',
                                left: '8px',
                                filter: 'drop-shadow(0 0 3px black) drop-shadow(0 0 3px black)',
                                zIndex: 0,
                            }}
                        />
                    )}
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.y, left: contextMenu.x }
                                : undefined
                        }
                        PaperProps={{
                            sx: {
                                px: 1,
                                py: 0,
                                width: 200,
                            },
                            onClick: (e: React.MouseEvent) =>
                                e.stopPropagation(),
                        }}
                    >
                        <Typography variant="caption" color="textSecondary">
                            Fill Layer Opacity
                        </Typography>
                        <Slider
                            size="small"
                            value={localFillLayerOpacity}
                            onChange={handleChangeFillLayerOpacity}
                            min={0}
                            max={1}
                            step={0.01}
                            aria-label="Opacity"
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) =>
                                `${Math.round(value * 100)}%`
                            }
                            onClick={(e: React.MouseEvent) =>
                                e.stopPropagation()
                            }
                        />
                    </Menu>
                </Box>
            </Tooltip>
        )
    }

    return (
        <Box className={DatasetCardStyle.typeIcon} sx={{ backgroundColor }} />
    )
}
