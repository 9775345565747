import { SupabaseClient } from '@supabase/supabase-js'
import { EarthscaleUser, UserProfile } from '../types/user'

export async function getUserID(
    supabaseClient: SupabaseClient
): Promise<string | null> {
    // First try to get the user from Supabase auth
    const {
        data: { user },
        error,
    } = await supabaseClient.auth.getUser()

    if (!error && user) {
        return user.id
    }

    if (error) {
        throw error
    }

    return null
}

export async function getOrgId(
    supabaseClient: SupabaseClient
): Promise<string | null> {
    const userID = await getUserID(supabaseClient)
    if (!userID) {
        throw new Error('User ID not found')
    }

    const orgIdQuery = supabaseClient
        .from('users')
        .select('org_id')
        .eq('id', userID)
    const { data, error } = await orgIdQuery
    if (error) {
        throw error
    }
    return data[0].org_id
}

export async function fetchEarthscaleUser(
    supabaseClient: SupabaseClient
): Promise<EarthscaleUser> {
    const userID = await getUserID(supabaseClient)
    if (!userID) {
        throw new Error('User ID not found')
    }
    const userQuery = supabaseClient
        .from('users')
        .select(
            `
            id,
            email,
            org_id,
            default_map_id,
            api_keys (
                key
            ),
            onboarding_completed
        `
        )
        .eq('id', userID)
        .single()

    const { data, error } = await userQuery
    if (error) {
        throw error
    }

    const { data: org, error: orgError } = await supabaseClient
        .from('organizations')
        .select('name')
        .eq('id', data.org_id)
        .single()
    if (orgError) {
        throw orgError
    }

    const user: EarthscaleUser = {
        id: data.id,
        email: data.email,
        org_id: data.org_id,
        org_name: org.name,
        default_map_id: data.default_map_id,
        api_keys: data.api_keys.map((api_key) => api_key.key),
        onboarding_completed: data.onboarding_completed,
    }
    return user
}

export async function fetchUserProfiles(
    supabaseClient: SupabaseClient,
    userIds: string[]
): Promise<UserProfile[]> {
    const userProfilesQuery = supabaseClient
        .from('user_profiles')
        .select('*')
        .in('id', userIds)

    const { data, error } = await userProfilesQuery
    if (error) {
        throw error
    }
    const profiles: UserProfile[] = data.map((profile) => ({
        id: profile.id,
        org_id: profile.org_id,
        created_at: new Date(profile.created_at),
        name: profile.name,
        email: profile.email,
        picture_url: profile.picture_url,
    }))

    return profiles
}

export async function updateOnboardingCompleted(
    supabaseClient: SupabaseClient,
    onboardingCompleted: boolean
) {
    const userID = await getUserID(supabaseClient)
    const { error } = await supabaseClient
        .from('users')
        .update({ onboarding_completed: onboardingCompleted })
        .eq('id', userID)
    if (error) {
        throw error
    }
}
