import * as React from 'react'
import { useState } from 'react'
import { Dataset } from '../../types/dataset'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { useActivityContext } from '../../context/activity/activityContext'
type QGISInstructionsDialogProps = {
    open: boolean
    onClose: () => void
    dataset: Dataset
    tileUrl: string
}

export default function QGISInstructionsDialog({
    open,
    onClose,
    dataset,
    tileUrl,
}: QGISInstructionsDialogProps) {
    const [copyUrlOpen, setCopyUrlOpen] = useState(false)
    const { trackCopyTileUrl } = useActivityContext()
    const isRaster = dataset.type === 'raster'
    const minZoom = dataset.rasterOverviewsStartZoom ? 0 : dataset.minZoom
    const maxZoom = dataset.rasterOverviewsStartZoom
        ? dataset.rasterOverviewsStartZoom
        : dataset.maxZoom
    const handleCloseUrl = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return
        setCopyUrlOpen(false)
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>Open in QGIS</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        To visualize this layer in QGIS:
                    </Typography>
                    <Typography component="div">
                        <ol>
                            <li>Open QGIS</li>
                            <li>
                                In the Browser panel, right-click on "
                                {isRaster ? 'XYZ Tiles' : 'Vector Tiles'}" and
                                select "New {isRaster ? '' : 'Generic'}{' '}
                                Connection..."
                            </li>
                            <li>
                                Enter a name for the connection (e.g. "
                                {dataset.name}")
                            </li>
                            <li>
                                Copy and paste this URL into the URL field:
                                <Box
                                    sx={{
                                        p: 1,
                                        my: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        maxWidth: '70%',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontFamily: 'monospace',
                                            overflowX: 'auto',
                                            flex: 1,
                                        }}
                                    >
                                        {tileUrl}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                tileUrl
                                            )
                                            setCopyUrlOpen(true)
                                            trackCopyTileUrl(dataset.id)
                                        }}
                                    >
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                </Box>
                            </li>
                            <li>
                                {minZoom !== null && (
                                    <>
                                        Set Min. Zoom Level to{' '}
                                        <strong>{minZoom}</strong>
                                    </>
                                )}
                                {minZoom !== null &&
                                    maxZoom !== null &&
                                    ' and '}
                                {maxZoom !== null && (
                                    <>
                                        Set Max. Zoom Level to{' '}
                                        <strong>{maxZoom}</strong>
                                    </>
                                )}
                            </li>
                            <li>Click "OK" to save the connection</li>
                            <li>
                                Double-click the new connection to add it to
                                your map
                            </li>
                        </ol>

                        <Typography variant="body1">
                            If you change the visualization parameters in
                            Earthscale, update the URL in QGIS to reflect the
                            new parameters.
                        </Typography>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={copyUrlOpen}
                autoHideDuration={3000}
                onClose={handleCloseUrl}
            >
                <Alert
                    onClose={handleCloseUrl}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Successfully copied URL to clipboard
                </Alert>
            </Snackbar>
        </>
    )
}
