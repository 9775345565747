import * as React from 'react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import 'swagger-ui-react/swagger-ui.css'
import { createTheme, ThemeProvider } from '@mui/material'
import 'highlight.js/styles/atom-one-dark.css'

import { themeOptions } from './theme'

import Workspace from './pages/Workspace/Workspace'
import SupabaseProvider from './context/supabase/SupabaseProvider'
import BackendProvider from './context/backend/BackendProvider'
import { GlobalCSS } from '../GlobalCSS'
import * as Sentry from '@sentry/react'
import { ErrorEvent, EventHint } from '@sentry/react'
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useLocation,
    useParams,
} from 'react-router-dom'
import MapProvider from './context/map/MapProvider'
import { useSupabaseContext } from './context/supabase/supabaseContext'
import CacheManager from './context/cache'
import ChangePassword from './pages/ChangePassword/ChangePassword'
import CommentProvider from './context/comment/CommentProvider'
import { supabaseIntegration } from '@supabase/sentry-js-integration'
import { SupabaseClient } from '@supabase/supabase-js'
import { ActivityProvider } from './context/activity/ActivityProvider'
import ConfirmSignup from './pages/ConfirmSignup/ConfirmSignup'
import ConfirmMagicLink from './pages/ConfirmMagicLink/ConfirmMagicLink'
import ConfirmPasswordReset from './pages/ConfirmPasswordReset/ConfirmPasswordReset'

CacheManager.init(process.env.VERCEL_GIT_COMMIT_SHA)

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: import.meta.env.EARTHSCALE_ENVIRONMENT,
    release: process.env.VERCEL_GIT_COMMIT_SHA,
    integrations: [
        Sentry.httpClientIntegration({
            failedRequestStatusCodes: [400, 599],
        }),
        supabaseIntegration(SupabaseClient, Sentry, {
            tracing: true,
            breadcrumbs: true,
            errors: true,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
    ],
    beforeSend: (event: ErrorEvent, hint: EventHint) => {
        // log exception if dev
        if (import.meta.env.DEV) {
            if (hint.originalException) {
                console.error(hint.originalException)
            }
            return null
        }
        return event
    },
    tracesSampleRate: 1.0, // This captures 100% of transactions
    tracePropagationTargets: [/^https:\/\/.*\.earthscale\.ai/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This captures 100% of sessions
    replaysOnErrorSampleRate: 1.0, // This captures 100% of sessions with errors (if not already sampling 100% of sessions)
})

const theme = createTheme(themeOptions)

function MapRoute() {
    const { id } = useParams()
    const { search } = useLocation()
    const { user } = useSupabaseContext()
    const fallbackMapId = id || user?.default_map_id || undefined

    const queryParams = new URLSearchParams(search)
    const bboxParam = queryParams.get('bbox')
    const visible = queryParams.get('visible')?.split(',') || []

    let initialViewState = undefined
    if (bboxParam || visible.length > 0) {
        const bbox = bboxParam?.split(',').map(Number) as [
            number,
            number,
            number,
            number,
        ]

        initialViewState = {
            bbox,
            visible,
        }
    }

    return (
        <MapProvider
            fallbackMapId={fallbackMapId}
            initialViewState={initialViewState}
        >
            <CommentProvider>
                <Workspace />
            </CommentProvider>
        </MapProvider>
    )
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
    return (
        <SupabaseProvider>
            <BackendProvider>
                <ActivityProvider>{children}</ActivityProvider>
            </BackendProvider>
        </SupabaseProvider>
    )
}

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/map/:id"
                    element={
                        <ProtectedRoute>
                            <MapRoute />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <MapRoute />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/user/change-password"
                    element={
                        <ProtectedRoute>
                            <ChangePassword />
                        </ProtectedRoute>
                    }
                />
                <Route path="/confirm-signup" element={<ConfirmSignup />} />
                <Route
                    path="/confirm-magic-link"
                    element={<ConfirmMagicLink />}
                />
                <Route
                    path="/confirm-password-reset"
                    element={<ConfirmPasswordReset />}
                />
            </Routes>
        </BrowserRouter>
    )
}

export function renderToDom(container) {
    createRoot(container).render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <GlobalCSS />
                <App />
            </ThemeProvider>
        </StrictMode>
    )
}
