import * as React from 'react'
import { Box, Button, Typography } from '@mui/material'
import AuthLayout from '../../components/AuthLayout/AuthLayout'

function ConfirmPasswordReset() {
    const mapRef = React.useRef(null)
    const [error, setError] = React.useState<string | null>(null)

    const params = new URLSearchParams(window.location.search)
    const paramName = 'reset-url'
    const resetUrl = params.get(paramName)

    React.useEffect(() => {
        document.title = 'Reset Password - Earthscale'
        if (!resetUrl) {
            setError(`Missing '${paramName}' parameter`)
        } else if (!resetUrl.startsWith('https://')) {
            setError(`Invalid '${paramName}' parameter: must use HTTPS`)
        } else if (!URL.canParse(resetUrl)) {
            setError(`Invalid '${paramName}' parameter: malformed URL`)
        } else {
            setError(null)
        }
    }, [resetUrl])

    const handleConfirm = () => {
        if (resetUrl && !error) {
            window.location.href = resetUrl
        }
    }

    return (
        <AuthLayout mapRef={mapRef}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h5"
                    align={'center'}
                    sx={{
                        marginTop: '14px',
                    }}
                >
                    Reset Your Password
                </Typography>
                {error && (
                    <Typography
                        variant="body2"
                        align={'center'}
                        color="error"
                        sx={{ mt: 1 }}
                    >
                        ERROR: {error}
                    </Typography>
                )}
                {!error && (
                    <>
                        <Button
                            variant="contained"
                            onClick={handleConfirm}
                            fullWidth
                            disabled={!!error}
                        >
                            Reset Password
                        </Button>
                        <Typography variant="body1" align={'center'}>
                            This link only works once. If you need to reset your
                            password again, please request a new password reset
                            link.
                        </Typography>
                    </>
                )}
            </Box>
        </AuthLayout>
    )
}

export default ConfirmPasswordReset
