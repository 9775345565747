import React from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMapContext } from '../../context/map/mapContext'
import { useBackendContext } from '../../context/backend/backendContext'
import { Dataset } from '../../types/dataset'
import {
    DatasetClass,
    DatasetConfig,
    ImageDatasetConfig,
    ZarrDatasetConfig,
    VectorDatasetConfig,
    GroupBy,
} from '../../types/datasetRegistration'
import { inferDatasetClass } from '../../utils/datasetClass'

const HELP_TEXTS = {
    filename_date_pattern: `Pattern to extract dates from filenames (optional). Examples:

• For "data_20230115.tif" use "%Y%m%d"
• For "2023-01-15_data.tif" use "%Y-%m-%d"`,

    groupby: `Controls how images are grouped (optional):

• one_plane: All images in single plane
• time: Group by exact timestamp
• solar_day: Group by solar day
• id: Load separately
• time.year: Group by year
• time.month: Group by month
• time.day: Group by day`,

    bands: `List of bands to load (optional). If not specified, all bands will be loaded.

Examples:
• "red,green,blue" for RGB imagery
• "B4,B3,B2" for Landsat/Sentinel bands`,

    filename_band_pattern: `Pattern to extract band names from filenames (optional). Use key:value pairs where key is a pattern and value is the band name.

Examples:
• "*_B[0-9]": "band_1" - Maps filenames ending with B followed by a number to "band_1"
• "*.blue.tif": "blue" - Maps filenames containing "blue.tif" to "blue" band`,
}

interface MetadataStepProps {
    url: string
    nameFromUrl: string
    onBackClick: () => void
    onClose: () => void
    onDatasetAdded: (datasetVersionId: string) => void
    datasetVersionId: string | null
    viewMode: 'map' | 'catalog'
}

interface ImageDatasetFieldsProps {
    config: Partial<ImageDatasetConfig>
    setConfig: (config: Partial<ImageDatasetConfig>) => void
}

function ImageDatasetFields({ config, setConfig }: ImageDatasetFieldsProps) {
    return (
        <>
            <FormControl fullWidth sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Filename Date Pattern"
                        value={config.filename_date_pattern}
                        onChange={(e) =>
                            setConfig({
                                ...config,
                                filename_date_pattern: e.target.value,
                            })
                        }
                        fullWidth
                    />
                    <Tooltip title={HELP_TEXTS.filename_date_pattern}>
                        <IconButton size="small" sx={{ ml: 1 }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Filename Band Pattern"
                        placeholder="pattern:band_name,pattern2:band_name2"
                        value={
                            config.filename_band_pattern
                                ? Object.entries(config.filename_band_pattern)
                                      .map(
                                          ([pattern, band]) =>
                                              `${pattern}:${band}`
                                      )
                                      .join(',')
                                : ''
                        }
                        onChange={(e) => {
                            const patterns = e.target.value
                                .split(',')
                                .map((pair) => pair.trim())
                                .filter((pair) => pair.includes(':'))
                                .reduce(
                                    (acc, pair) => {
                                        const [pattern, band] = pair
                                            .split(':')
                                            .map((s) => s.trim())
                                        if (pattern && band) {
                                            acc[pattern] = band
                                        }
                                        return acc
                                    },
                                    {} as Record<string, string>
                                )
                            setConfig({
                                ...config,
                                filename_band_pattern:
                                    Object.keys(patterns).length > 0
                                        ? patterns
                                        : undefined,
                            })
                        }}
                        fullWidth
                    />
                    <Tooltip title={HELP_TEXTS.filename_band_pattern}>
                        <IconButton size="small" sx={{ ml: 1 }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InputLabel>Group By</InputLabel>
                    <Select
                        value={config.groupby || 'one_plane'}
                        onChange={(e) =>
                            setConfig({
                                ...config,
                                groupby: e.target.value as GroupBy,
                            })
                        }
                        fullWidth
                    >
                        <MenuItem value="one_plane">One Plane</MenuItem>
                        <MenuItem value="time">Time</MenuItem>
                        <MenuItem value="solar_day">Solar Day</MenuItem>
                        <MenuItem value="id">ID</MenuItem>
                        <MenuItem value="time.year">Year</MenuItem>
                        <MenuItem value="time.month">Month</MenuItem>
                        <MenuItem value="time.day">Day</MenuItem>
                    </Select>
                    <Tooltip title={HELP_TEXTS.groupby}>
                        <IconButton size="small" sx={{ ml: 1 }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Bands (comma-separated)"
                        value={config.bands?.join(',')}
                        onChange={(e) =>
                            setConfig({
                                ...config,
                                bands: e.target.value
                                    .split(',')
                                    .map((b) => b.trim())
                                    .filter((b) => b),
                            })
                        }
                        fullWidth
                    />
                    <Tooltip title={HELP_TEXTS.bands}>
                        <IconButton size="small" sx={{ ml: 1 }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </FormControl>
        </>
    )
}

export default function MetadataStep({
    url,
    nameFromUrl,
    onBackClick,
    onClose,
    onDatasetAdded,
    datasetVersionId,
    viewMode,
}: MetadataStepProps) {
    const backendClient = useBackendContext()
    const { state: mapState, addDatasetToMap } = useMapContext()
    const [name, setName] = React.useState<string>(nameFromUrl)
    const [addingDataset, setAddingDataset] = React.useState<boolean>(false)
    const [firstTry, setFirstTry] = React.useState<boolean>(true)
    const [error, setError] = React.useState<string | null>(null)
    const [showAdvanced, setShowAdvanced] = React.useState(false)

    // Advanced options state
    const [datasetClass, setDatasetClass] = React.useState<DatasetClass | null>(
        inferDatasetClass(url)
    )
    const [config, setConfig] = React.useState<
        Partial<ImageDatasetConfig | ZarrDatasetConfig | VectorDatasetConfig>
    >({})

    const [dataset, setDataset] = React.useState<Dataset | null>(null)

    React.useEffect(() => {
        const dataset = mapState.catalog.find(
            (dataset) => dataset.id === datasetVersionId
        )
        if (dataset) {
            setDataset(dataset)
        }
    }, [mapState.catalog, datasetVersionId])

    const handleAdd = async () => {
        setAddingDataset(true)
        try {
            const response = await backendClient.addDataset(
                url,
                name,
                datasetClass as DatasetClass,
                config as DatasetConfig
            )
            onDatasetAdded(response.dataset_version_id)
        } catch (error) {
            setAddingDataset(false)
            setFirstTry(false)
            setError(error.message)
        }
    }

    React.useEffect(() => {
        if (dataset?.status === 'ready' && dataset?.vizParams) {
            setAddingDataset(false)
            if (viewMode === 'map') {
                addDatasetToMap(dataset, true)
            }
            onClose()
        }
    }, [dataset?.status, onClose, dataset?.vizParams])

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleAdd()
        }
    }

    const renderTypeSpecificFields = () => {
        switch (datasetClass) {
            case 'ImageDataset': {
                const imageConfig = config as Partial<ImageDatasetConfig>
                return (
                    <ImageDatasetFields
                        config={imageConfig}
                        setConfig={(newConfig) => setConfig(newConfig)}
                    />
                )
            }
            // Add other dataset type specific fields once we have them
        }
    }

    return (
        <>
            <Stack direction={'column'} spacing={2} sx={{ p: '10px' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="dataset-name"
                    label="Dataset Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={addingDataset}
                />

                <Button
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    endIcon={
                        <ExpandMoreIcon
                            sx={{
                                transform: showAdvanced
                                    ? 'rotate(180deg)'
                                    : 'none',
                                transition: 'transform 0.2s',
                            }}
                        />
                    }
                    sx={{
                        alignSelf: 'flex-start',
                        textTransform: 'none',
                        fontSize: '0.875rem',
                        color: 'text.secondary',
                    }}
                >
                    Advanced Options
                </Button>

                <Collapse in={showAdvanced}>
                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel>Dataset Type</InputLabel>
                            <Select
                                value={datasetClass || ''}
                                onChange={(e) =>
                                    setDatasetClass(
                                        e.target.value as DatasetClass
                                    )
                                }
                            >
                                <MenuItem value="ZarrDataset">
                                    Zarr Dataset
                                </MenuItem>
                                <MenuItem value="ImageDataset">
                                    Image Dataset
                                </MenuItem>
                                <MenuItem value="VectorDataset">
                                    Vector Dataset
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {renderTypeSpecificFields()}
                    </Stack>
                </Collapse>

                {error && <Typography color={'error'}>{error}</Typography>}
                {dataset?.status === 'processing' && (
                    <Typography>
                        Processing dataset... <CircularProgress size={20} />
                    </Typography>
                )}
                {dataset?.status === 'processing_failed' && (
                    <Typography color="error">
                        Dataset processing failed: {dataset.error}
                    </Typography>
                )}
            </Stack>

            <Stack direction={'row'} spacing={2} sx={{ pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={addingDataset}
                    onClick={onBackClick}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    alignContent={'center'}
                >
                    {addingDataset && <CircularProgress size={20} />}
                    <Button onClick={handleAdd} disabled={addingDataset}>
                        {firstTry ? 'Register' : 'Try again'}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}
