import { SupabaseClient } from '@supabase/supabase-js'

export const logActivityToDb = async (
    client: SupabaseClient,
    datasetVersionId: string | null,
    action: string,
    userId: string,
    payload: any
) => {
    try {
        await client.from('activity_log').insert({
            dataset_version_id: datasetVersionId,
            action: action,
            user_id: userId,
            payload,
        })
    } catch (error) {
        console.error('Failed to log activity:', error)
        throw error
    }
}
