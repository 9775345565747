import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { Dataset } from '../../types/dataset'
import Box from '@mui/material/Box'
import { useMapContext } from '../../context/map/mapContext'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import eyeOpen from '../../assets/icons/eye-open.svg'
import { useActivityContext } from '../../context/activity/activityContext'

type VisibilityButtonProps = {
    dataset: Dataset
    className?: string
}

function VisibilityButton({ dataset, className }: VisibilityButtonProps) {
    const { dispatch } = useMapContext()
    const { trackVisibilityToggle } = useActivityContext()
    const toggleVisibility = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch({
            type: 'TOGGLE_DATASET_VISIBILITY',
            datasetVersionId: dataset.id,
        })
        trackVisibilityToggle(dataset.id)
    }

    return (
        <Box>
            <Tooltip title={dataset.isVisible ? 'Hide (v)' : 'Show (v)'} arrow>
                {dataset.isVisible ? (
                    <IconButton onClick={toggleVisibility}>
                        <img
                            src={eyeOpen as string}
                            alt="Hide"
                            className={className}
                            style={{ width: '18px', height: '18px' }}
                        />
                    </IconButton>
                ) : (
                    <IconButton onClick={toggleVisibility}>
                        <VisibilityOffIcon
                            className={className}
                            style={{
                                width: '18px',
                                height: '18px',
                                opacity: 0.5,
                            }}
                        />
                    </IconButton>
                )}
            </Tooltip>
        </Box>
    )
}

export default VisibilityButton
