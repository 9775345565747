import React, { useState } from 'react'
import { Marker } from 'react-map-gl'
import { Tooltip } from '@mui/material'
import { useCommentContext } from '../../context/comment/commentContext'
import { Feature, Point } from 'geojson'
import ChatIcon from '@mui/icons-material/Chat'

const CommentThreadLayer = () => {
    const { state, dispatch } = useCommentContext()
    const { selectedThreadId } = state
    const { threads, draftThread } = state
    const [hoveredThreadId, setHoveredThreadId] = useState<string | null>(null)

    // Convert threads to GeoJSON format
    const features: Feature<Point>[] = threads.map((thread) => ({
        type: 'Feature',
        geometry: thread.location,
        properties: {
            id: thread.id,
            title: thread.title,
            createdAt: thread.createdAt,
            updatedAt: thread.updatedAt,
        },
    }))

    return (
        <>
            {draftThread?.location && (
                <Marker
                    longitude={draftThread.location.lng}
                    latitude={draftThread.location.lat}
                >
                    <ChatIcon
                        style={{
                            color: '#90CAF9',
                            fontSize: '24px',
                            // ensure the tip of the bubble is set to the location
                            transform: 'translate(50%, -30%)',
                        }}
                    />
                </Marker>
            )}
            {features.map((feature) => (
                <Marker
                    key={feature.properties.id}
                    longitude={feature.geometry.coordinates[0]}
                    latitude={feature.geometry.coordinates[1]}
                >
                    <div
                        onMouseEnter={() =>
                            setHoveredThreadId(feature.properties.id)
                        }
                        onMouseLeave={() => setHoveredThreadId(null)}
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch({
                                type: 'SET_SELECTED_THREAD',
                                threadId: feature.properties.id,
                            })
                        }}
                    >
                        <Tooltip
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -15],
                                        },
                                    },
                                ],
                            }}
                            title={feature.properties.title}
                            placement="top"
                        >
                            <ChatIcon
                                style={{
                                    // blue if selected, grey if not
                                    color:
                                        selectedThreadId ===
                                        feature.properties.id
                                            ? '#90CAF9'
                                            : '#B0BEC5',

                                    cursor: 'grab',
                                    // enlarge if hovered
                                    fontSize:
                                        hoveredThreadId ===
                                        feature.properties.id
                                            ? '28px'
                                            : '24px',
                                    // ensure the tip of the bubble is set to the location
                                    transform: 'translate(50%, -30%)',
                                    filter:
                                        hoveredThreadId ===
                                        feature.properties.id
                                            ? 'drop-shadow(0px 0px 6px rgba(0,0,0,0.5))'
                                            : 'none',
                                }}
                            ></ChatIcon>
                        </Tooltip>
                        {/* glowy circle in the background if hovered */}
                        {hoveredThreadId === feature.properties.id && (
                            <div
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    backgroundColor: '#90CAF9',
                                    top: -15,
                                    left: 15,
                                    right: 0,
                                    bottom: 0,
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '50%',
                                    // transform: 'translate(-50%, -50%)',
                                    opacity: 0.5,
                                    zIndex: -1,
                                    filter: 'blur(10px)',
                                }}
                            ></div>
                        )}
                    </div>
                </Marker>
            ))}
        </>
    )
}

export default CommentThreadLayer
