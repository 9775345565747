import { createContext, useContext } from 'react'
import { SupabaseClient } from '@supabase/supabase-js'
import { EarthscaleUser, UserProfile } from '../../types/user'

type SupabaseContextType = {
    client: SupabaseClient
    user: EarthscaleUser
    userProfile: UserProfile
    logout: () => void
    loginMapRef: React.MutableRefObject<any>
}

export const SupabaseContext = createContext<SupabaseContextType>(null)

export const useSupabaseContext = () => useContext(SupabaseContext)
