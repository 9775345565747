import { Dataset } from '../types/dataset'
import {
    DEFAULT_DATA_REGION,
    GCP_MULTI_REGION_MAPPING,
    TILER_DEPLOYMENT_REGIONS,
} from '../constants'
import {
    PseudocolorRasterVizParams,
    MultibandRasterVizParams,
    BinnedRasterVizParams,
} from 'types/viz.js'
import { isFastTiler } from '../utils'
import { PixelInfoResponse } from '../types/pixelInfo'
import { SupabaseClient } from '@supabase/supabase-js'

function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
}

function getRegionFromDataset(dataset: Dataset): string {
    const environment = import.meta.env.EARTHSCALE_ENVIRONMENT
    // On localhost, we don't have regions
    let region = ''
    // On preview staging, we've only got our default deployment
    if (environment == 'preview' || environment === 'staging') {
        region = DEFAULT_DATA_REGION
    }
    // On production, we check whether we've got a regional deployment that fits, and
    // fall back to the default deployment if not
    if (environment === 'production') {
        let dataRegion = dataset.dataRegion
        // In the case of multi-regional buckets, we need to know which region to use
        if (GCP_MULTI_REGION_MAPPING[dataRegion]) {
            dataRegion = GCP_MULTI_REGION_MAPPING[dataRegion]
        }
        const weGotADeployment = TILER_DEPLOYMENT_REGIONS.includes(dataRegion)
        if (weGotADeployment) {
            region = dataRegion
        } else {
            region = DEFAULT_DATA_REGION
        }
    }
    return region
}

function fastTileServerURL(
    dataset: Dataset,
    selectedDimensionsParam: { [key: string]: number },
    user: { api_keys: string[] }
) {
    const params = new URLSearchParams()
    params.set('api_key', user.api_keys[0])

    if (dataset?.vizType === 'continuous_singleband_raster') {
        // Single-band pseudocolor visualization
        const vizParams = dataset.vizParams as PseudocolorRasterVizParams
        const band = vizParams.band
        const minMaxes = vizParams.minMaxesPerBand[band]
        const rescale = `${minMaxes[0]},${minMaxes[1]}`

        params.set('variables', band)
        params.set('rescale', rescale)
        params.set('colormap_name', vizParams.colorRamp)
        params.set(
            'reverse_colormap',
            (vizParams.reverseColormap ?? false).toString()
        )
    } else if (dataset?.vizType === 'continuous_multiband_raster') {
        // RGB visualization
        const vizParams = dataset.vizParams as MultibandRasterVizParams
        const bands = [vizParams.red, vizParams.green, vizParams.blue]

        // Set variables for RGB bands
        bands.forEach((band) => params.append('variables', band))

        // Set rescale values for each band
        const rescaleValues = bands.map((band) => {
            const minMax = vizParams.minMaxesPerBand[band]
            return `${minMax[0]},${minMax[1]}`
        })
        rescaleValues.forEach((value) => params.append('rescale', value))
    } else if (dataset?.vizType === 'binned_raster') {
        const vizParams = dataset.vizParams as BinnedRasterVizParams
        const band = vizParams.band
        const bins = vizParams.bins
        params.set('variables', band)
        bins.forEach(([value, color]) => {
            params.append('color_bin', `${value}:${color}`)
        })
    } else {
        throw new Error('Unsupported visualization type for fast tiler')
    }

    // Add dimension parameters
    Object.entries(selectedDimensionsParam)
        .map(([dim, index]) => `${dim}:${index}`)
        .forEach((dimidx) => {
            params.append('dimidx', dimidx)
        })

    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }

    const url =
        import.meta.env.EARTHSCALE_FAST_TILER_URL.replace(/\/$/, '') +
        region +
        `/tiles/raster/` +
        dataset.cacheKey +
        `/{z}/{x}/{y}.webp` +
        '?' +
        params.toString()
    return url
}

function createTileServerURL(
    dataset: Dataset,
    selectedDimensionsParam: { [key: string]: number },
    user?: { api_keys: string[] }
) {
    const isFast = isFastTiler(dataset)
    if (isFast) {
        return fastTileServerURL(dataset, selectedDimensionsParam, user)
    }

    const selectedDimensions = Object.entries(selectedDimensionsParam)
        .map(([dim, index]) => `${encodeURIComponent(dim)}=${index}`)
        .join('&')
    const dimensionAddition =
        selectedDimensions.length > 0 ? '&' + selectedDimensions : ''

    let vizId: string | null = null

    if (
        dataset.className == 'EarthEngineDataset' &&
        dataset.earthEngineVisualizations != null &&
        dataset.earthEngineVisualizations.length > 0
    ) {
        // ensure idx is in range
        const selectedEarthEngineVisualizationIdx = Math.min(
            Math.max(dataset.selectedEarthEngineVisualizationIndex, 0),
            dataset.earthEngineVisualizations.length - 1
        )
        vizId =
            dataset.earthEngineVisualizations[
                selectedEarthEngineVisualizationIdx
            ].id
    } else {
        vizId = dataset.vizId
    }

    const vizParamsAddition = vizId ? '&viz_id=' + vizId : ''

    const keyAddition =
        user && user.api_keys.length > 0
            ? '&key=' + encodeURIComponent(user.api_keys[0])
            : ''

    const extension = dataset.type == 'raster' ? 'png' : 'mvt'
    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }
    const url =
        import.meta.env.EARTHSCALE_TILER_URL +
        region +
        '/tiles/{z}/{x}/{y}.' +
        extension +
        '?dataset=' +
        dataset.id +
        dimensionAddition +
        vizParamsAddition +
        keyAddition
    return url
}

async function getPixelInfo(
    dataset: Dataset,
    lat: number,
    lon: number,
    supabaseClient: SupabaseClient,
    zoom: number,
    options?: {
        timeSeriesMode?: boolean
        band?: string
    }
): Promise<PixelInfoResponse | null> {
    const params = new URLSearchParams({
        dataset: dataset.id,
        lat: String(lat),
        lon: String(lon),
        zoom: String(Math.round(zoom)),
    })

    if (options?.timeSeriesMode) {
        params.append('time_series', 'true')
        // TODO: this isn't great, as if a user has a dimension called
        // 'time_series_band', the request will give a 400
        params.append('time_series_band', options.band)
    }

    // Add dimensions except time when in time series mode
    if (Object.keys(dataset.selectedDimensions).length > 0) {
        Object.entries(dataset.selectedDimensions).forEach(([dim, index]) => {
            if (!(options?.timeSeriesMode && dim === 'time')) {
                params.append(dim, String(index))
            }
        })
    }

    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }

    const { data, error } = await supabaseClient.auth.getSession()
    const accessToken = data?.session?.access_token
    if (!accessToken) {
        throw new Error('Not logged in, cannot fetch pixel info')
    }

    const response = await fetch(
        `${import.meta.env.EARTHSCALE_TILER_URL}${region}/tiles/pixel-info?${params}`,
        { headers: { Authorization: accessToken } }
    )

    if (!response.ok && response.status !== 204) {
        throw new Error(`Failed to fetch pixel info: ${response.status}`)
    }

    if (response.status === 204) {
        return null
    }

    const pixelInfoJSON = await response.json()
    return pixelInfoJSON as PixelInfoResponse
}

export { createTileServerURL, getPixelInfo }
