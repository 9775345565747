import { Dataset } from '../../types/dataset'
import { useMapContext } from '../../context/map/mapContext'
import { Add, Remove } from '@mui/icons-material'
import { IconButton, Tooltip, Box } from '@mui/material'
import React, { useState } from 'react'
import { InfoAlert } from '../Alerts/Alerts'

const AddToMapButton = ({ dataset }: { dataset: Dataset }) => {
    const { state, addDatasetToMap, removeDatasetFromMap } = useMapContext()
    const [showNotification, setShowNotification] = useState(false)

    const datasetOnMap = state.current.datasets
        .map((dataset) => dataset.id)
        .includes(dataset.id)
    const tooltipText = datasetOnMap
        ? 'Remove dataset from map'
        : 'Add dataset to map'

    const handleButtonClick = () => {
        if (datasetOnMap) {
            removeDatasetFromMap(dataset.id)
        } else {
            addDatasetToMap(dataset, true)
            setShowNotification(true)
        }
    }

    const handleCloseNotification = () => {
        setShowNotification(false)
    }

    return (
        <>
            <Tooltip title={tooltipText}>
                <IconButton
                    onClick={(e) => {
                        handleButtonClick()
                        e.stopPropagation()
                    }}
                >
                    {datasetOnMap ? <Remove /> : <Add />}
                </IconButton>
            </Tooltip>

            <InfoAlert
                open={showNotification}
                message={
                    <Box>
                        Dataset "{dataset.name}" added to map "{state.current?.metadata.name}". Press (Tab) to toggle between map and catalog view.
                    </Box>
                }
                onClose={handleCloseNotification}
            />
        </>
    )
}

export default AddToMapButton
