import React from 'react'
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material'
import AddStep from './AddStep'
import AccessStep from './AccessStep'
import MetadataStep from './MetadataStep'
import VisualizationStep from './VisualizationStep.js'

type Step = 'URL' | 'Access' | 'Metadata' | 'Visualization'

const steps: Step[] = ['URL', 'Access', 'Metadata', 'Visualization']

function getFileNameFromURL(url: string): string {
    const parsedUrl = URL.parse(url)
    if (parsedUrl == null) {
        return ''
    }
    if (parsedUrl.pathname == null) {
        return ''
    }
    const parts = parsedUrl.pathname.split('/')
    if (parts.length === 0) {
        return ''
    }
    const lastPart = parts[parts.length - 1]
    return lastPart
}

function prettifyFileName(fileName: string): string {
    const withoutPlaceholder = fileName.replace(/\{[^}]+\}/, '')
    const name = withoutPlaceholder.split('.')[0]
    const pascalCaseToSpaces = name.replace(/([A-Z])/g, ' $1')
    const noUnderscores = pascalCaseToSpaces.replace(/[-_]/g, ' ').trim()
    return noUnderscores.charAt(0).toUpperCase() + noUnderscores.slice(1)
}

function getStepComponent(
    step: Step,
    url: string,
    onUrlChange: (url: string) => void,
    datasetName: string,
    setFileName: (fileName: string) => void,
    handleNext: () => void,
    handleBack: () => void,
    handleClose: () => void,
    handleDatasetAdded: (datasetVersionId: string) => void,
    datasetVersionId: string | null,
    viewMode: 'map' | 'catalog'
) {
    switch (step) {
        case 'URL':
            return (
                <AddStep
                    url={url}
                    onUrlChange={onUrlChange}
                    onNextClick={handleNext}
                />
            )
        case 'Access':
            return (
                <AccessStep
                    url={url}
                    setFileName={setFileName}
                    onNextClick={handleNext}
                    onBackClick={handleBack}
                />
            )
        case 'Metadata':
            return (
                <MetadataStep
                    url={url}
                    nameFromUrl={datasetName}
                    onBackClick={handleBack}
                    onClose={handleNext}
                    onDatasetAdded={handleDatasetAdded}
                    datasetVersionId={datasetVersionId}
                    viewMode={viewMode}
                />
            )
        case 'Visualization':
            if (!datasetVersionId) {
                throw new Error('Dataset version ID is required')
            }
            return (
                <VisualizationStep
                    datasetId={datasetVersionId}
                    onClose={handleClose}
                />
            )
        default:
            return null
    }
}

type AddDatasetStepperProps = {
    initialUrl: string
    onClose: () => void
    viewMode: 'map' | 'catalog'
}

const AddDatasetStepper: React.FC<AddDatasetStepperProps> = ({
    initialUrl,
    onClose,
    viewMode,
}) => {
    const initialStep = initialUrl ? 1 : 0
    const [activeStep, setActiveStep] = React.useState(initialStep)
    const [url, setUrl] = React.useState<string>(initialUrl)
    const [datasetName, setDatasetName] = React.useState<string>(
        prettifyFileName(initialUrl)
    )
    React.useEffect(() => {
        setDatasetName(prettifyFileName(url))
    }, [url])

    const [datasetVersionId, setDatasetVersionId] = React.useState<
        string | null
    >(null)

    const isStepOptional = (step: number) => {
        return step === 1
    }

    const handleDatasetAdded = (datasetVersionId: string) => {
        setDatasetVersionId(datasetVersionId)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const labelProps: {
                        optional?: React.ReactNode
                    } = {}
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        )
                    }
                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <Box sx={{ mt: 2, mb: 1, width: '100%' }}>
                {getStepComponent(
                    steps[activeStep],
                    url,
                    setUrl,
                    datasetName,
                    (fileNameFromUrl: string) => {
                        setDatasetName(prettifyFileName(fileNameFromUrl))
                    },
                    () => {
                        setActiveStep(
                            Math.min(activeStep + 1, steps.length - 1)
                        )
                    },
                    () => {
                        // Skip access step if we're on the last step
                        setActiveStep(0)
                    },
                    onClose,
                    handleDatasetAdded,
                    datasetVersionId,
                    viewMode
                )}
            </Box>
        </Box>
    )
}

export default AddDatasetStepper
