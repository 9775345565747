import { Map } from '../../types/map.js'
import { Dataset, VizType } from '../../types/dataset.js'
import { produce } from 'immer'
import { VizParams } from '../../types/viz.js'
import { datasetCopyHydratedProperties } from '../../api/dataset'
import { deepClone } from '@mui/x-data-grid/internals'

// Schema version of the map state, we can use this to migrate state saved to local
// storage. Or in case we forget to migrate cleanly, we can at least invalidate
// state on the user side
export const MAP_SCHEMA_VERSION = '0.3'

export type ViewState = {
    bbox: [number, number, number, number]
    visible: string[] // shortened dataset IDs
}

export type MapState = {
    current: Map | null
    clickedLngLat: [number, number] | null
    detailsDatasetVersionId: string | null
    hoveredCardDatasetVersionId: string | null
    selectedDatasetVersionId: string | null
    // This catalog objects holds all datasets (revisions), which are not on the
    // current map
    catalog: Dataset[]
    initialViewState: ViewState | null
    globeView: boolean
    viewportBounds: [number, number, number, number] | null
    currentZoom: number
    viewMode: 'map' | 'catalog'
}

export const initialMapState: MapState = {
    current: null,
    clickedLngLat: null,
    detailsDatasetVersionId: null,
    hoveredCardDatasetVersionId: null,
    selectedDatasetVersionId: null,
    catalog: [],
    initialViewState: null,
    viewportBounds: null,
    globeView: false,
    currentZoom: 0,
    viewMode: 'map',
}

export type MapAction =
    | { type: 'SET_CURRENT'; map: Map }
    | { type: 'DELETE_MAP'; mapId: string }
    | { type: 'ADD_DATASET_TO_MAP'; dataset: Dataset; isVisible: boolean }
    | { type: 'REMOVE_DATASET_FROM_MAP'; datasetVersionId: string }
    | { type: 'UPDATE_MAP_METADATA'; name: string }
    | { type: 'TOGGLE_DATASET_VISIBILITY'; datasetVersionId: string }
    | { type: 'TOGGLE_DATASET_EDITING'; datasetVersionId: string }
    | {
          type: 'SET_SELECTED_DATE_INDEX'
          datasetVersionId: string
          dateIndex: number
      }
    | { type: 'SET_CLICKED_LAT_LNG'; lngLat: [number, number] }
    | {
          type: 'UPDATE_VIZ_PARAMS'
          datasetVersionId: string
          vizId: string
          vizType: VizType
          vizParams: VizParams
      }
    | { type: 'SET_DETAILS_DATASET'; datasetVersionId: string }
    | {
          type: 'UPDATE_DATASET_OPACITY'
          datasetVersionId: string
          opacity: number
      }
    | { type: 'HOVER_CARD'; datasetVersionId: string }
    | { type: 'SET_CATALOG'; datasets: Dataset[] }
    | { type: 'HIDE_ALL_CATALOG_DATASETS' }
    | { type: 'UPDATE_LAYER_ORDER'; datasets: Dataset[] }
    | { type: 'DELETE_DATASET_FROM_CATALOG'; datasetVersionId: string }
    | {
          type: 'SELECT_EARTH_ENGINE_VISUALIZATION'
          datasetVersionId: string
          idx: number
      }
    | {
          type: 'SET_SELECTED_DIMENSIONS'
          datasetVersionId: string
          dimensions: { [key: string]: number }
      }
    | { type: 'TOGGLE_GLOBE_VIEW' }
    | { type: 'SET_VIEWPORT_BOUNDS'; bounds: [number, number, number, number] }
    | { type: 'SET_CURRENT_ZOOM'; zoom: number }
    | { type: 'SET_SELECTED_DATASET'; datasetVersionId: string }
    | { type: 'CLEAR_MAP' }
    | { type: 'SET_VIEW_MODE'; viewMode: 'map' | 'catalog' }

function updateDataset(
    state: MapState,
    datasetVersionId: string,
    update: (mapDataset: Dataset | null, catalogDataset: Dataset | null) => void
): MapState {
    return produce(state, (draftState) => {
        const mapDataset = draftState.current?.datasets.find(
            (d) => d.id === datasetVersionId
        )
        const catalogDataset = draftState.catalog.find(
            (d) => d.id === datasetVersionId
        )
        if (!mapDataset && !catalogDataset) {
            console.error(
                `Could not find dataset with version id ${datasetVersionId} on the map
                or in the catalog to update`
            )
            return
        }
        update(mapDataset, catalogDataset)
    })
}

export const mapReducer = (state: MapState, action: MapAction): MapState => {
    switch (action.type) {
        case 'SET_CURRENT': {
            return produce(state, (draftState) => {
                if (
                    !draftState.current ||
                    draftState.current.metadata.id !== action.map.metadata.id
                ) {
                    draftState.current = action.map
                    return
                }
                const mapDatasetsById = draftState.current.datasets.reduce(
                    (acc, dataset) => {
                        acc[dataset.datasetId] = dataset
                        return acc
                    },
                    {}
                )
                // map is the same, but maybe the datasets have changed
                for (const newDataset of action.map.datasets) {
                    const oldDataset = mapDatasetsById[newDataset.datasetId]
                    if (oldDataset) {
                        continue
                    }
                    draftState.current.datasets.push(newDataset)
                }
            })
        }

        case 'DELETE_MAP': {
            return produce(state, (draftState) => {
                if (
                    draftState.current &&
                    draftState.current.metadata.id === action.mapId
                ) {
                    draftState.current = null
                }
            })
        }

        case 'ADD_DATASET_TO_MAP': {
            console.log('Adding dataset')
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }

                // already on map
                if (
                    draftState.current.datasets.some(
                        (dataset) => dataset.id === action.dataset.id
                    )
                ) {
                    console.log('Already on the map')
                    return
                }
                const datasetCopy = deepClone(action.dataset)
                datasetCopy.isVisible = action.isVisible
                // Also set to visible in the catalog
                const catalogDataset = draftState.catalog.find(
                    (d) => d.id === datasetCopy.id
                )
                if (catalogDataset) {
                    catalogDataset.isVisible = action.isVisible
                }

                draftState.current.datasets.push(datasetCopy)
            })
        }

        case 'UPDATE_LAYER_ORDER': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                draftState.current.datasets = action.datasets
            })
        }

        case 'REMOVE_DATASET_FROM_MAP': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                const datasetToRemove = draftState.current.datasets.find(
                    (dataset) => dataset.id === action.datasetVersionId
                )
                if (!datasetToRemove) {
                    console.error(
                        `Could not find dataset with version id ${action.datasetVersionId} to remove`
                    )
                    return
                }
                datasetToRemove.isVisible = false
                draftState.current.datasets =
                    draftState.current.datasets.filter(
                        (dataset) => dataset.id !== action.datasetVersionId
                    )
                // Also hide the dataset in the catalog
                const catalogDataset = draftState.catalog.find(
                    (d) => d.id === action.datasetVersionId
                )
                if (catalogDataset) {
                    catalogDataset.isVisible = false
                }
            })
        }

        case 'UPDATE_MAP_METADATA': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                draftState.current.metadata.name = action.name
            })
        }

        case 'TOGGLE_DATASET_VISIBILITY': {
            return updateDataset(
                state,
                action.datasetVersionId,
                (mapDataset, catalogDataset) => {
                    if (mapDataset) mapDataset.isVisible = !mapDataset.isVisible
                    if (catalogDataset) {
                        catalogDataset.isVisible = !catalogDataset.isVisible
                    }
                }
            )
        }

        case 'TOGGLE_DATASET_EDITING': {
            return produce(state, (draftState) => {
                // We don't need to update the `isBeingEdited` property on the catalog
                if (draftState.current) {
                    for (const dataset of draftState.current.datasets) {
                        if (dataset.id === action.datasetVersionId) {
                            dataset.isBeingEdited = !dataset.isBeingEdited
                            return
                        }
                    }
                }
                console.error(
                    `Could not find dataset with version id ${action.datasetVersionId} to toggle editing`
                )
            })
        }

        case 'SET_SELECTED_DIMENSIONS': {
            return updateDataset(
                state,
                action.datasetVersionId,
                (mapDataset, catalogDataset) => {
                    if (mapDataset) {
                        mapDataset.selectedDimensions = action.dimensions
                    }
                    if (catalogDataset) {
                        catalogDataset.selectedDimensions = action.dimensions
                    }
                }
            )
        }

        case 'SET_CLICKED_LAT_LNG': {
            return produce(state, (draftState) => {
                draftState.clickedLngLat = action.lngLat
                draftState.detailsDatasetVersionId = null
            })
        }

        case 'UPDATE_VIZ_PARAMS': {
            return updateDataset(
                state,
                action.datasetVersionId,
                (mapDataset, catalogDataset) => {
                    if (mapDataset) {
                        mapDataset.vizParams = action.vizParams
                        mapDataset.vizId = action.vizId
                        mapDataset.vizType = action.vizType
                    }
                    if (catalogDataset) {
                        catalogDataset.vizParams = action.vizParams
                        catalogDataset.vizId = action.vizId
                        catalogDataset.vizType = action.vizType
                    }
                }
            )
        }

        case 'SET_DETAILS_DATASET': {
            return produce(state, (draftState) => {
                draftState.detailsDatasetVersionId = action.datasetVersionId
                draftState.clickedLngLat = null
            })
        }

        case 'UPDATE_DATASET_OPACITY': {
            return updateDataset(
                state,
                action.datasetVersionId,
                (mapDataset, catalogDataset) => {
                    if (mapDataset) {
                        mapDataset.opacity = action.opacity
                    }
                    if (catalogDataset) {
                        catalogDataset.opacity = action.opacity
                    }
                }
            )
        }

        case 'HOVER_CARD': {
            return produce(state, (draftState) => {
                draftState.hoveredCardDatasetVersionId = action.datasetVersionId
            })
        }

        case 'SET_CATALOG': {
            return produce(state, (draftState) => {
                const catalogDatasetsById = draftState.catalog.reduce(
                    (acc, dataset) => {
                        acc[dataset.datasetId] = dataset
                        return acc
                    },
                    {}
                )
                const mapDatasetsById =
                    draftState.current?.datasets.reduce((acc, dataset) => {
                        acc[dataset.datasetId] = dataset
                        return acc
                    }, {}) || {}

                const updatedCatalog = []
                for (const newDataset of action.datasets) {
                    const mapDataset = mapDatasetsById[newDataset.datasetId]
                    const catalogDataset =
                        catalogDatasetsById[newDataset.datasetId]

                    if (mapDataset) {
                        if (mapDataset.id !== newDataset.id) {
                            if (newDataset.updatedAt > mapDataset.updatedAt) {
                                mapDataset.latestDatasetVersionId =
                                    newDataset.id
                            }
                        }
                    }

                    // same dataset version
                    if (catalogDataset) {
                        if (catalogDataset.id !== newDataset.id) {
                            catalogDataset.isNewVersionAvailable =
                                newDataset.updatedAt > catalogDataset.updatedAt
                        }
                        // update the new version with the hydrated properties
                        datasetCopyHydratedProperties(
                            catalogDataset,
                            deepClone(newDataset)
                        )
                    }

                    updatedCatalog.push(newDataset)
                }
                draftState.catalog = updatedCatalog
            })
        }

        case 'HIDE_ALL_CATALOG_DATASETS': {
            return produce(state, (draftState) => {
                for (const dataset of draftState.catalog) {
                    const mapDataset = draftState.current?.datasets.find(
                        (d) => d.id === dataset.id
                    )
                    // if its on the map, we don't need to hide it
                    if (mapDataset) {
                        continue
                    }
                    dataset.isVisible = false
                }
            })
        }

        case 'DELETE_DATASET_FROM_CATALOG': {
            return produce(state, (draftState) => {
                draftState.catalog = draftState.catalog.filter(
                    (dataset) => dataset.id !== action.datasetVersionId
                )
            })
        }

        case 'SELECT_EARTH_ENGINE_VISUALIZATION': {
            return updateDataset(
                state,
                action.datasetVersionId,
                (mapDataset, catalogDataset) => {
                    if (mapDataset) {
                        if (
                            !mapDataset.earthEngineVisualizations ||
                            mapDataset.earthEngineVisualizations.length <=
                                action.idx ||
                            action.idx < 0
                        ) {
                            console.error(
                                `Could not find Earth Engine visualization with index ${action.idx} for dataset with version id ${action.datasetVersionId}`
                            )
                            return
                        }
                        mapDataset.selectedEarthEngineVisualizationIndex =
                            action.idx
                    }
                    if (catalogDataset) {
                        if (
                            !catalogDataset.earthEngineVisualizations ||
                            catalogDataset.earthEngineVisualizations.length <=
                                action.idx ||
                            action.idx < 0
                        ) {
                            console.error(
                                `Could not find Earth Engine visualization with index ${action.idx} for dataset with version id ${action.datasetVersionId}`
                            )
                            return
                        }
                        catalogDataset.selectedEarthEngineVisualizationIndex =
                            action.idx
                    }
                }
            )
        }

        case 'TOGGLE_GLOBE_VIEW': {
            return produce(state, (draftState) => {
                draftState.globeView = !draftState.globeView
            })
        }

        case 'SET_VIEWPORT_BOUNDS': {
            return produce(state, (draftState) => {
                draftState.viewportBounds = action.bounds
            })
        }

        case 'SET_CURRENT_ZOOM': {
            return produce(state, (draftState) => {
                draftState.currentZoom = action.zoom
            })
        }

        case 'SET_SELECTED_DATASET': {
            return produce(state, (draftState) => {
                draftState.selectedDatasetVersionId = action.datasetVersionId
            })
        }

        case 'CLEAR_MAP': {
            return {
                ...state,
                current: {
                    ...state.current,
                    datasets: [],
                },
            }
        }

        case 'SET_VIEW_MODE': {
            return produce(state, (draftState) => {
                draftState.viewMode = action.viewMode
            })
        }

        default:
            return state
    }
}
