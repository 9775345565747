import * as React from 'react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import Stack from '@mui/material/Stack'
import SidebarStyle from './Sidebar.module.css'
import {
    Alert,
    Box,
    Divider,
    Paper,
    Snackbar,
    ToggleButtonGroup,
    ToggleButton,
    Chip,
} from '@mui/material'
import { Dataset } from '../../types/dataset'
import MapBox from './MapBox'
import { useMapContext } from '../../context/map/mapContext'
import Header from './Header'
import { Map, ViewList } from '@mui/icons-material'
import { MapRef } from 'react-map-gl'
import { useGlobalDragDrop } from '../../hooks/useGlobalDragDrop'
import { useBackendContext } from '../../context/backend/backendContext'
import { useActivityContext } from '../../context/activity/activityContext'
import {
    sortCatalogDatasets,
    handleFileDropAsync,
    filterDatasets,
    isLatLngString,
} from './utils'
import DatasetAdder from './DatasetAdder'
import DatasetSearchBar from './DatasetSearchBar'
import DatasetsDisplay from './DatasetsDisplay'
import { isTextInputActive } from '../../utils'

type SidebarProps = {
    flyToDatasetBounds: (dataset: Dataset) => void
    mapRef: React.RefObject<MapRef>
    filterDatasetsInView?: (
        bounds: [number, number, number, number]
    ) => Dataset[]
}

function Sidebar({ flyToDatasetBounds, mapRef }: SidebarProps) {
    const { state, dispatch, changeMap, setViewMode } = useMapContext()
    const [searchTerm, setSearchTerm] = useState('')
    const [error, setError] = useState<string>(null)
    const [pastedDatasetUrl, setPastedDatasetUrl] = useState<string>('')
    const { trackUploadLocalGeojson } = useActivityContext()
    const backendClient = useBackendContext()

    const [datasetAddDialogOpen, setDatasetAddDialogOpen] =
        React.useState(false)
    const [filterToView, setFilterToView] = useState(false)
    const [showOnlyVisible, setShowOnlyVisible] = useState(false)

    const mapDatasets = filterDatasets(
        state.current.datasets,
        searchTerm,
        filterToView,
        state.viewportBounds
    )
    const catalogDatasets = sortCatalogDatasets(
        filterDatasets(
            state.catalog,
            searchTerm,
            filterToView,
            state.viewportBounds
        ).filter((dataset) => !showOnlyVisible || dataset.isVisible)
    )
    const recentlyUpdatedCatalogDatasets = catalogDatasets.filter((dataset) => {
        return (
            // Updated in last 5 minutes
            new Date(dataset.updatedAt) > new Date(Date.now() - 5 * 60 * 1000)
        )
    })

    const isDragging = useGlobalDragDrop({
        onDrop: (file: File) =>
            handleFileDropAsync(
                state,
                backendClient,
                changeMap,
                setError,
                trackUploadLocalGeojson,
                file
            ),
        acceptedTypes: ['application/json', 'application/geo+json'],
    })

    const datasetBeingEdited = mapDatasets.find(
        (dataset) => dataset.isBeingEdited
    )

    React.useEffect(() => {
        const datasetBeingEdited = mapDatasets.find((d) => d.isBeingEdited)
        if (datasetBeingEdited) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape' && datasetBeingEdited) {
                    dispatch({
                        type: 'TOGGLE_DATASET_EDITING',
                        datasetVersionId: datasetBeingEdited.id,
                    })
                }
            }
            window.addEventListener('keydown', handleKeyDown)
            return () => window.removeEventListener('keydown', handleKeyDown)
        }
    }, [mapDatasets])

    // Values empirically determined
    const maxHeight = `calc(100vh - 16px)`
    const datasetListMaxHeightMap = `calc(100vh - 335px)`
    const datasetListMaxHeightCatalog = `calc(100vh - 316px)`

    const addButtonVariant =
        state.current.datasets.length > 0 ? 'outlined' : 'contained'

    const handleViewModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newViewMode: 'map' | 'catalog'
    ) => {
        if (newViewMode !== null) {
            setViewMode(newViewMode)
        }
    }

    React.useEffect(() => {
        const handlePaste = (event: ClipboardEvent) => {
            const target = event.target as HTMLElement
            if (
                target.tagName === 'INPUT' ||
                target.tagName === 'TEXTAREA' ||
                target.isContentEditable
            ) {
                return
            }
            event.clipboardData.items[0].getAsString((text) => {
                const trimmedText = text.trim()
                const coords = isLatLngString(trimmedText)

                if (coords) {
                    // If coordinates are valid, fly to them

                    dispatch({
                        type: 'SET_CLICKED_LAT_LNG',
                        lngLat: [coords[1], coords[0]],
                    })
                    mapRef.current?.flyTo({
                        center: [coords[1], coords[0]], // MapBox uses [lng, lat]
                        zoom: 20,
                    })
                } else {
                    // Otherwise treat as dataset URL as before
                    setPastedDatasetUrl(trimmedText)
                    setDatasetAddDialogOpen(true)
                }
            })
        }
        window.addEventListener('paste', handlePaste)
        return () => window.removeEventListener('paste', handlePaste)
    }, [])

    const handleDatasetAddDialogClose = () => {
        setDatasetAddDialogOpen(false)
        setPastedDatasetUrl('')
    }

    // Update the Tab key handler to use the global state
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if Tab key was pressed and no text input is active
            if (event.key === 'Tab' && !isTextInputActive()) {
                // Prevent default tab behavior
                event.preventDefault()

                // Toggle between map and catalog views
                setViewMode(state.viewMode === 'map' ? 'catalog' : 'map')
            }
        }

        // Add event listener
        window.addEventListener('keydown', handleKeyDown)

        // Clean up
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [state.viewMode, setViewMode])

    return (
        <>
            {ReactDOM.createPortal(
                <Box
                    className={SidebarStyle.dragAndDropArea}
                    sx={{
                        backgroundColor: isDragging
                            ? 'rgb(255, 255, 255, 0.2)'
                            : 'transparent',
                    }}
                />,
                document.body
            )}
            <Paper
                className={SidebarStyle.sidebar}
                sx={{
                    maxHeight: maxHeight,
                }}
            >
                <Stack direction="column" spacing={1}>
                    <Header />

                    {/* View mode toggle - update to use state.viewMode */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingX: 1,
                        }}
                    >
                        <ToggleButtonGroup
                            id="viewModeToggle"
                            value={state.viewMode}
                            exclusive
                            onChange={handleViewModeChange}
                            aria-label="view mode"
                            size="small"
                            fullWidth
                        >
                            <ToggleButton value="map" aria-label="map view">
                                <Map sx={{ mr: 1 }} />
                                Map
                            </ToggleButton>
                            <ToggleButton
                                value="catalog"
                                aria-label="catalog view"
                            >
                                <ViewList sx={{ mr: 1 }} />
                                Catalog
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    <DatasetSearchBar
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        filterToView={filterToView}
                        setFilterToView={setFilterToView}
                    />
                    {state.viewMode === 'map' && <MapBox />}
                    {state.viewMode === 'catalog' && (
                        <Box className={SidebarStyle.chipBox}>
                            {!showOnlyVisible && (
                                <Chip
                                    label="Visible"
                                    onClick={() => setShowOnlyVisible(true)}
                                    className={SidebarStyle.chip}
                                    size="small"
                                />
                            )}
                            {showOnlyVisible && (
                                <Chip
                                    label="Visible"
                                    onDelete={() => setShowOnlyVisible(false)}
                                    className={SidebarStyle.chip}
                                    size="small"
                                    color="primary"
                                />
                            )}
                        </Box>
                    )}
                    <Divider />
                    <DatasetsDisplay
                        viewMode={state.viewMode}
                        datasetBeingEdited={datasetBeingEdited}
                        mapDatasets={mapDatasets}
                        catalogDatasets={catalogDatasets}
                        recentlyUpdatedCatalogDatasets={
                            recentlyUpdatedCatalogDatasets
                        }
                        flyToDatasetBounds={flyToDatasetBounds}
                        datasetListMaxHeightCatalog={
                            datasetListMaxHeightCatalog
                        }
                        datasetListMaxHeightMap={datasetListMaxHeightMap}
                    />
                    <DatasetAdder
                        addButtonVariant={addButtonVariant}
                        setDatasetAddDialogOpen={setDatasetAddDialogOpen}
                        datasetAddDialogOpen={datasetAddDialogOpen}
                        handleDatasetAddDialogClose={
                            handleDatasetAddDialogClose
                        }
                        pastedDatasetUrl={pastedDatasetUrl}
                        viewMode={state.viewMode}
                    />
                </Stack>
                <Snackbar
                    open={error !== null}
                    autoHideDuration={5000}
                    onClose={() => setError(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    sx={{ width: '400px' }}
                >
                    <Alert
                        onClose={() => setError(null)}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                        key={error}
                    >
                        {error}
                    </Alert>
                </Snackbar>
            </Paper>
        </>
    )
}

export default Sidebar
