import { createContext, useContext } from 'react'
import { ActivityState } from './activityTypes'

type ActivityContextType = {
    state: ActivityState
    trackVisibilityToggle: (datasetVersionId: string) => void
    trackGetPixelInfo: (isTimeSeries: boolean) => void
    trackCopyTileUrl: (datasetVersionId: string) => void
    trackUploadLocalGeojson: () => void
}

export const ActivityContext = createContext<ActivityContextType | undefined>(
    undefined
)

export const useActivityContext = () => {
    const context = useContext(ActivityContext)
    if (!context) {
        throw new Error(
            'useActivityContext must be used within an ActivityProvider'
        )
    }
    return context
}
