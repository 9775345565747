import React from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Dataset } from '../../types/dataset'


function BandSelector({
    band,
    dataset,
    onBandChange,
}: {
    band?: string
    dataset: Dataset
    onBandChange: (band: string) => void
}) {
    return (
        <FormControl
            size="small"
            sx={{
                mb: 0,
                ml: 1,
                mr: 1,
                mt: 1,
                width: 'calc(100% - 16px)',
            }}
        >
            <InputLabel>Band</InputLabel>
            <Select
                value={band}
                onChange={(e) => onBandChange(e.target.value)}
                label="Band"
                error={!band}
                sx={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-all',
                }}
            >
                {dataset.bands?.map((band) => (
                    <MenuItem
                        key={band}
                        value={band}
                        sx={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-all',
                        }}
                    >
                        {band}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}


function DimensionSelections({ dataset }: { dataset: Dataset }) {
    if (!dataset.dimensionInfo) return null

    // Filter out time dimensions and band dimensions
    const otherDimensions = dataset.dimensionInfo.dimensions.filter(
        (dim) => dim.name !== 'time'
    )

    if (otherDimensions.length === 0) return null

    return (
        <Box sx={{ mt: 0, ml: 2, mb: 0, mr: 2 }}>
            <Table
                size="small"
                sx={{
                    '& td': { borderBottom: 'none', py: 0.5 },
                    tableLayout: 'auto',
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            colSpan={2}
                            sx={{
                                color: '#999',
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                pl: 0,
                                borderBottom: '1px solid #444',
                            }}
                        >
                            Selected Dimensions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {otherDimensions.map((dim) => {
                        const selectedIndex =
                            dataset.selectedDimensions[dim.name] ?? 0
                        const value = dim.values[selectedIndex]
                        return (
                            <TableRow key={dim.name}>
                                <TableCell
                                    sx={{
                                        color: '#999',
                                        fontWeight: 500,
                                        fontSize: '0.75rem',
                                        pl: 0,
                                        textAlign: 'left',
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    {dim.name}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: '#999',
                                        fontSize: '0.75rem',
                                        textAlign: 'right',
                                        pr: 0,
                                    }}
                                >
                                    {value?.toString()}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Box>
    )
}

export { DimensionSelections, BandSelector }
