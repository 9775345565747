import { Box, useTheme } from '@mui/material'
import { Line } from 'react-chartjs-2'
import {
    ActiveElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    ChartType,
    Legend,
    LinearScale,
    LineElement,
    Point,
    PointElement,
    Title,
    Tooltip,
    TooltipPositionerFunction,
} from 'chart.js'
import React from 'react'
import { TimePixelInfo } from '../../types/pixelInfo'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)
Tooltip.positioners.mouse = function (elements: readonly ActiveElement[], eventPosition: Point) {
    // put the tooltip just above the mouse position
    return {
        x: eventPosition.x,
        y: eventPosition.y - 10,
    }
};
declare module 'chart.js' {
    interface TooltipPositionerMap {
        mouse: TooltipPositionerFunction<ChartType>;
    }
}


export function TimeSeriesChart({
    pixelInfo,
    band,
}: {
    pixelInfo: TimePixelInfo
    band: string
}) {
    const theme = useTheme()
    if (!pixelInfo.dimension || !pixelInfo.value) return null


    const largeChart = pixelInfo.value.length > 15

    const chartData = {
        labels: pixelInfo.dimension,
        datasets: [
            {
                label: band,
                data: pixelInfo.value,
                borderColor: theme.palette.success.main,
                backgroundColor: theme.palette.success.main,
                pointRadius: largeChart ? 0 : 3,
                pointHoverRadius: largeChart ? 2 : 8,
                tension: 0.1,
                borderWidth: largeChart ? 1 : 2,
            },
        ],
    }

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        layout: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 8,
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                displayColors: false,
                backgroundColor: theme.palette.background.paper,
                titleColor: theme.palette.text.primary,
                bodyColor: theme.palette.text.primary,
                borderColor: theme.palette.divider,
                borderWidth: 1,
                padding: 8,
                position: 'mouse',
                titleFont: {
                    family: theme.typography.body1.fontFamily,
                    size: 10,
                    weight: (theme.typography.body1.fontWeight?.toString() ||
                        '400') as 'normal',
                },
                bodyFont: {
                    family: theme.typography.body1.fontFamily,
                    size: 10,
                    weight: (theme.typography.body1.fontWeight?.toString() ||
                        '400') as 'normal',
                },
                callbacks: {
                    title: (items) => '',
                    label: (item) => {
                        const value = item.raw as number
                        return `${item.label}: ${Number.isInteger(value) ? value.toString() : value.toFixed(4)}`
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        family: theme.typography.body1.fontFamily,
                        size: 10,
                    },
                    maxRotation: 90,
                    minRotation: 70,
                },
                grid: {
                    color: theme.palette.divider,
                },
            },
            y: {
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        family: theme.typography.body1.fontFamily,
                        size: 10,
                    },
                },
                grid: {
                    color: theme.palette.divider,
                },
            },
        },
    }

    return (
        <Box
            sx={{
                height: 200,
                width: '100%',
                mt: 1,
                px: 0,
                '& canvas': {
                    maxWidth: '100%',
                },
            }}
        >
            <Line data={chartData} options={options} />
        </Box>
    )
}
