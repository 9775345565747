import React, { useReducer } from 'react'
import { ActivityContext } from './activityContext'
import { activityReducer, initialActivityState } from './activityReducer'
import { useSupabaseContext } from '../supabase/supabaseContext'
import { logActivityToDb } from '../../api/activity'

export const ActivityProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const [state, dispatch] = useReducer(activityReducer, initialActivityState)
    const { user, client } = useSupabaseContext()

    const logActivity = async (action, datasetVersionId, payload) => {
        const userId = user.id
        dispatch({ action, userId, datasetVersionId, payload })

        try {
            await logActivityToDb(
                client,
                datasetVersionId,
                action,
                userId,
                payload
            )
        } catch (error) {
            console.error('Failed to log activity:', error)
        }
    }

    const trackVisibilityToggle = (datasetVersionId: string) => {
        logActivity('TOGGLE_DATASET_VISIBILITY', datasetVersionId, {})
    }

    const trackGetPixelInfo = (isTimeSeries: boolean) => {
        logActivity('GET_PIXEL_INFO', null, { isTimeSeries })
    }

    const trackCopyTileUrl = (datasetVersionId: string) => {
        logActivity('COPY_TILE_URL', datasetVersionId, {})
    }

    const trackUploadLocalGeojson = () => {
        logActivity('UPLOAD_LOCAL_GEOJSON', null, {})
    }

    return (
        <ActivityContext.Provider
            value={{
                state,
                trackVisibilityToggle,
                trackGetPixelInfo,
                trackCopyTileUrl,
                trackUploadLocalGeojson,
            }}
        >
            {children}
        </ActivityContext.Provider>
    )
}
