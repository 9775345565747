import { RealtimeChannel, SupabaseClient } from '@supabase/supabase-js'
import {
    Dataset,
    DatasetDomain,
    DatasetProcessingStatus,
    DatasetType,
    VizType,
    DimensionInfo,
} from '../types/dataset'
import { v4 as uuid4 } from 'uuid'
import {
    MultibandRasterVizParams,
    PseudocolorRasterVizParams,
    Viz,
    VizParams,
    EarthEngineVisualization,
    EarthEngineVizParams,
} from '../types/viz'
import { Geometry, MultiPolygon } from 'geojson'
import { BackendClient } from './backend'

type _DatasetMetadata = {
    value_map?: { [key: string]: number | number[] }
    bands?: string[]
    min_zoom: number
    max_zoom?: number | null
    min_maxes_per_band?: {
        [key: string]: [number | undefined, number | undefined]
    }
    attributes?: { name: string; value: string }[] | null
    thumbnail_url?: string
}

type DBDataset = {
    id: string
    dataset_id: string
    name: string
    created_at: string
    updated_at: string
    type: DatasetType
    is_deleted: boolean
    extent: MultiPolygon | null
    bounds: string
    definition: { [key: string]: string }
    dimension_info: DimensionInfo | null
    class_name: string
    metadata: _DatasetMetadata
    status: DatasetProcessingStatus
    domain: DatasetDomain
    org_id: string
    user_id: string
    error: string | null
    traceback: string | null
    source: string
    is_public: boolean
    viz_params?: DBVizParams[]
    thumbnail_url?: string | null
    data_region?: string | null
    earth_engine_visualizations?: DBEarthEngineVisualization[]
    cache_key?: string | null
    raster_overviews_start_zoom?: number | null
    cache_keys?: {
        raster_overviews?: { start_zoom: number }
    }
}

type DBEarthEngineVisualization = {
    id: string
    name: string
    earth_engine_viz_params: EarthEngineVizParams
    dataset_version_id: string
    created_at: Date
    updated_at: Date
    deleted_at: Date
}

type DBVizParams = {
    id: string
    type: VizType
    params: VizParams
    dataset_version_id: string
    created_at: string
    dataset_name: string
}

const PROCESSING_STATES = ['processing', 'not_started']

function ensureVizParamsAreValid(dataset: Dataset): Dataset {
    const paramTypesToCheck = [
        'continuous_singleband_raster',
        'continuous_multiband_raster',
    ]
    if (!paramTypesToCheck.includes(dataset.vizType)) {
        return dataset
    }

    let params: PseudocolorRasterVizParams | MultibandRasterVizParams
    if (dataset.vizType === 'continuous_singleband_raster') {
        params = dataset.vizParams as PseudocolorRasterVizParams
    } else if (dataset.vizType === 'continuous_multiband_raster') {
        params = dataset.vizParams as MultibandRasterVizParams
    }

    // TODO: this is a temporary hack to ensure that the min/max values are always present in the viz params.
    // we've since updated the backend to always return min/max values, but this is a stopgap measure to ensure
    // that old viz params are still compatible with the new backend without a migration
    // https://github.com/earthscale/earthscale/pull/239
    const currentMinMaxesPerBand = {
        ...Object.fromEntries(
            Object.entries(dataset.minMaxesPerBand).map(
                ([band, [min, max]]) => [
                    band,
                    [min ?? 0, max ?? 1] as [number, number],
                ]
            )
        ),
        ...Object.fromEntries(
            Object.entries(params.minMaxesPerBand).map(([band, [min, max]]) => [
                band,
                [min ?? 0, max ?? 1] as [number, number],
            ])
        ),
    }
    dataset.vizParams.minMaxesPerBand = currentMinMaxesPerBand

    return dataset
}

async function insertVizParams(
    supabaseClient: SupabaseClient,
    datasetVersionId: string,
    datasetName: string,
    vizId: string,
    vizType: VizType,
    vizParams: VizParams
): Promise<void> {
    const row = {
        id: vizId,
        type: vizType,
        params: vizParams,
        dataset_version_id: datasetVersionId,
        dataset_name: datasetName,
        created_at: new Date().toISOString(),
    }

    const { data, error } = await supabaseClient
        .from('viz_params')
        .insert([row])

    if (error) {
        console.error(error)
    }
}

function datasetCopyHydratedProperties(from: Dataset, to: Dataset): void {
    to.isVisible = from.isVisible
    to.selectedDimensions = from.selectedDimensions
    to.isBeingEdited = from.isBeingEdited
    to.cardIsHovered = from.cardIsHovered
}

function convertDBEarthEngineVisualizationToEarthEngineVisualization(
    dbEarthEngineVisualization: DBEarthEngineVisualization
): EarthEngineVisualization {
    return {
        id: dbEarthEngineVisualization.id,
        name: dbEarthEngineVisualization.name,
        earthEngineVizParams:
            dbEarthEngineVisualization.earth_engine_viz_params,
    }
}

function convertDimensions(dbDataset: DBDataset): DimensionInfo {
    let updatedDimensionInfo: DimensionInfo = dbDataset.dimension_info
    const timeDimension = updatedDimensionInfo?.dimensions?.find(
        (dimension) => dimension.name === 'time'
    )
    const hasDates = timeDimension?.values !== undefined

    if (dbDataset.class_name === 'EarthEngineDataset' && hasDates) {
        const dims = updatedDimensionInfo?.dimensions
        updatedDimensionInfo.dimensions = dims.filter(
            (dim) => dim.name !== 'time'
        )
    }

    // If there are dates, we need to convert them to Date objects
    if (hasDates) {
        updatedDimensionInfo.dimensions = updatedDimensionInfo.dimensions?.map(
            (dim) => {
                if (dim.name === 'time') {
                    return {
                        ...dim,
                        values: dim.values.map((value: number) => {
                            return new Date(value)
                        }),
                    }
                }
                return dim
            }
        )
    }

    return updatedDimensionInfo
}

async function convertDBDatasetToDataset(
    dbDataset: DBDataset,
    viz: Viz | null
): Promise<Dataset> {
    let dimensionInfo = null
    let initSelectedDimensions: { [key: string]: number } = {}
    if (dbDataset.dimension_info) {
        dimensionInfo = convertDimensions(dbDataset)
        initSelectedDimensions = {
            ...Object.fromEntries(
                dimensionInfo.dimensions.map((dim) => [dim.name, 0])
            ),
        }
    }

    const dataset: Dataset = {
        id: dbDataset.id,
        datasetId: dbDataset.dataset_id,
        name: dbDataset.name,
        createdAt: dbDataset.created_at,
        updatedAt: dbDataset.updated_at,
        definition: dbDataset.definition,
        dimensionInfo: dimensionInfo,
        extent: dbDataset.extent,
        bounds: dbDataset.bounds,
        isDeleted: dbDataset.is_deleted,
        domain: dbDataset.domain,
        type: dbDataset.type,
        status: dbDataset.status,
        className: dbDataset.class_name,
        error: dbDataset.error,
        traceback: dbDataset.traceback,
        dataRegion: dbDataset?.data_region,
        source: dbDataset.source as
            | 'internal_catalog'
            | 'google_drive'
            | 'local',
        isPublic: dbDataset.is_public,
        bands: dbDataset.metadata.bands,
        minMaxesPerBand: dbDataset.metadata.min_maxes_per_band,
        minZoom: dbDataset.metadata.min_zoom || 0,
        maxZoom: dbDataset.metadata.max_zoom || null,
        earthEngineVisualizations: dbDataset.earth_engine_visualizations?.map(
            convertDBEarthEngineVisualizationToEarthEngineVisualization
        ),
        selectedEarthEngineVisualizationIndex: -1,
        vizParams: viz?.vizParams,
        vizType: viz?.vizType,
        vizId: viz?.id,
        isVisible: false,
        selectedDimensions: initSelectedDimensions,
        isBeingEdited: false,
        cardIsHovered: false,
        opacity: 100,
        thumbnailUrl: dbDataset.metadata?.thumbnail_url,
        attributes:
            dbDataset.metadata?.attributes?.reduce<{ [key: string]: string }>(
                (acc, attr: { name: string; value: string }) => {
                    acc[attr.name] = attr.value
                    return acc
                },
                {}
            ) ?? {},
        cacheKey: dbDataset.cache_key,
        rasterOverviewsStartZoom:
            dbDataset.cache_keys?.raster_overviews?.start_zoom,
    }
    if (dataset.rasterOverviewsStartZoom != null) {
        dataset.minZoom = 0
        dataset.maxZoom = dataset.rasterOverviewsStartZoom
    }

    if (dataset.earthEngineVisualizations?.length) {
        dataset.selectedEarthEngineVisualizationIndex = 0
    }
    if (!dataset.minMaxesPerBand) {
        dataset.minMaxesPerBand = {}
        dataset.bands?.forEach((band) => {
            dataset.minMaxesPerBand[band] = [undefined, undefined]
        })
    }

    const validatedDataset = ensureVizParamsAreValid(dataset)
    return validatedDataset
}

async function fetchDatasets(
    supabaseClient: SupabaseClient,
    datasetVersionIds?: string[] | null
): Promise<Dataset[]> {
    const latestDatasetsQuery = supabaseClient
        .from('datasets_latest')
        .select(
            '*, \
            cache_keys(raster_overviews(start_zoom)), \
            viz_params(*), \
            earth_engine_visualizations(*)\
            '
        )
        .order('updated_at', { ascending: false })

    if (datasetVersionIds) {
        latestDatasetsQuery.in('id', datasetVersionIds)
    }

    const { data, error } = await latestDatasetsQuery
    if (error) {
        console.error(error)
        return
    }

    // Convert DBDataset to Dataset
    const datasets = data.map(async (dataset: DBDataset) => {
        const latestDBVizParams = dataset.viz_params.sort((a, b) => {
            return (
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
        })

        // Visualizations may not exist if it comes from a tileserver.
        let latestViz: Viz | null = null
        if (latestDBVizParams.length > 0) {
            latestViz = {
                id: latestDBVizParams[0].id,
                vizType: latestDBVizParams[0].type,
                vizParams: latestDBVizParams[0].params,
            }
        }

        return convertDBDatasetToDataset(dataset, latestViz)
    })

    return Promise.all(datasets).then((results) =>
        results.filter((dataset) => dataset !== undefined)
    )
}

async function subscribeToDatasetChanges(
    supabaseClient: SupabaseClient,
    callback: (payload: any) => void
): Promise<RealtimeChannel> {
    const channel = supabaseClient
        .channel('dataset-changes')
        .on(
            'postgres_changes',
            {
                event: '*',
                schema: 'public',
                table: 'dataset_versions',
            },
            callback
        )
        .on(
            'postgres_changes',
            {
                event: '*',
                schema: 'public',
                table: 'viz_params',
            },
            callback
        )
        .subscribe()
    return channel
}

async function deleteDataset(
    supabaseClient: SupabaseClient,
    datasetVersionId: string
) {
    // Deletion works by creating a copy of the dataset with a new ID and setting
    // is_deleted to true
    const newDatasetVersionId = uuid4()
    // First, fetching the dataset
    const { data, error: error1 } = await supabaseClient
        .from('dataset_versions')
        .select('*')
        .eq('id', datasetVersionId)
    if (error1) {
        console.error(error1)
        return
    }
    const currentVersion = data[0]
    // Then, inserting the new dataset, make sure that updated_at is set to the current
    // time
    const { data: insertData, error: error2 } = await supabaseClient
        .from('dataset_versions')
        .insert({
            ...currentVersion,
            id: newDatasetVersionId,
            is_deleted: true,
            updated_at: new Date().toISOString(),
        })
    if (error2) {
        console.error(error2)
        return
    }
}

// FIXME: Check whether this is still required
function getDefaultRasterVizParams(
    dataset: Dataset,
    vizType: VizType
): VizParams {
    let vizParams: VizParams
    const band = dataset.bands[0]
    switch (vizType) {
        case 'continuous_singleband_raster':
            vizParams = {
                minMaxesPerBand: dataset.minMaxesPerBand,
                band: band,
                colorRamp: 'viridis',
            }
            return vizParams
        case 'continuous_multiband_raster':
            const redBand = dataset.bands[0]
            const greenBand =
                dataset.bands.length > 1 ? dataset.bands[1] : dataset.bands[0]
            const blueBand =
                dataset.bands.length > 2 ? dataset.bands[2] : dataset.bands[0]
            vizParams = {
                minMaxesPerBand: dataset.minMaxesPerBand,
                red: redBand,
                green: greenBand,
                blue: blueBand,
            }
            return vizParams
        case 'binned_raster':
            const minMax = dataset.minMaxesPerBand[band]
            let bins = []
            if (minMax[0] != null && minMax[1] != 0) {
                bins = [
                    [minMax[0] + (minMax[1] - minMax[0]) / 2, '#ff0000'],
                    [minMax[1], '#0000ff'],
                ]
            } else {
                bins = [
                    [1, '#ff0000'],
                    [256, '#0000ff'],
                ]
            }
            vizParams = {
                band: band,
                bins: bins,
            }
            return vizParams
    }
}

export type { DBDataset, DBVizParams }
export {
    convertDBDatasetToDataset,
    datasetCopyHydratedProperties,
    subscribeToDatasetChanges,
    fetchDatasets,
    insertVizParams,
    deleteDataset,
    PROCESSING_STATES,
    getDefaultRasterVizParams,
}
