import { SupabaseClient } from "@supabase/supabase-js"
import { getPixelInfo } from "../../api/tiler"
import { MapState } from "../../context/map/mapReducer"
import { Dataset } from "../../types/dataset"
import { boundsStringToArray } from "../../utils"

const datasetHasTimeSeries = (dataset: Dataset): boolean => {
    return (
        dataset.type == 'raster' &&
        dataset.dimensionInfo?.dimensions.some((dim) => dim.name === 'time')
    )
}

const fetchDatasetInfo = async (
    dataset: Dataset,
    isTimeSeriesMode: boolean,
    selectedBands: Map<string, string>,
    mapState: MapState,
    client: SupabaseClient
) => {

    try {
        const isTimeSeries = datasetHasTimeSeries(dataset)
        const timeSeriesBand = selectedBands.get(dataset.id)

        if (
            isTimeSeries &&
            isTimeSeriesMode &&
            (timeSeriesBand == null || timeSeriesBand === '')
        ) {
            return {
                datasetId: dataset.id,
                info: null,
                band: null,
            }
        }

        const info = await getPixelInfo(
            dataset,
            mapState.clickedLngLat[1],
            mapState.clickedLngLat[0],
            client,
            mapState.currentZoom,
            {
                timeSeriesMode: isTimeSeriesMode,
                band: isTimeSeriesMode ? timeSeriesBand : null,
            }
        )
        return {
            datasetId: dataset.id,
            info,
            band: isTimeSeriesMode ? timeSeriesBand : null,
        }
    } catch (error) {
        const dataType =
            dataset.type === 'raster'
                ? isTimeSeriesMode
                    ? 'time series'
                    : 'pixel'
                : 'feature'
        console.error(
            `Error fetching ${dataType} info for dataset ${dataset.id}:`,
            error
        )
        return { datasetId: dataset.id, info: null, band: null }
    }
}


const fetchDatasetsInfo = async (
    datasets: Dataset[],
    isTimeSeriesMode: boolean,
    selectedBands: Map<string, string>,
    mapState: MapState,
    client: SupabaseClient,
) => {
    const results = await Promise.all(
        datasets.map(
            dataset => fetchDatasetInfo(dataset, isTimeSeriesMode, selectedBands, mapState, client)
        )
    )

    const processedResults = Object.fromEntries(
        results.map((result) => [
            result.datasetId,
            {
                loading: false,
                data: result.info,
                band: result.band,
            },
        ])
    )

    return processedResults
}

type Coords = {
    lat: number
    lng: number
}


const getFilteredDatasets = (coords: Coords | null, mapState: MapState) => {
    if (!coords) return []
    let datasets: Dataset[] = [];
    if (mapState.viewMode === 'map') {
        datasets = mapState.current.datasets.filter(
            (dataset) => dataset.source !== 'local'
        )
    } else {
        datasets = mapState.catalog.filter(
            (dataset) => dataset.isVisible
        )
    }

    const datasetsInBounds = datasets.filter((dataset) => {
        const [xmin, ymin, xmax, ymax] = boundsStringToArray(dataset.bounds)
        return (
            coords.lng >= xmin &&
            coords.lng <= xmax &&
            coords.lat >= ymin &&
            coords.lat <= ymax
        )
    })
    return datasetsInBounds
}

function getDefaultTimeSeriesBand(dataset: Dataset) {
    if (!datasetHasTimeSeries(dataset)) {
        return null
    }
    if (dataset?.bands?.length > 0) {
        return dataset.bands[0]
    } else if (dataset?.vizParams?.band !== undefined) {
        return dataset.vizParams.band
    }
    return null
}

function getMissingTimeSeriesBandSelections(datasetsInBounds: Dataset[], selectedBands: Map<string, string>) {
    const additionalSelection = new Map<string, string>()
    for (const dataset of datasetsInBounds) {
        if (datasetHasTimeSeries(dataset)) {
            const timeSeriesBand = selectedBands.get(dataset.id)
            if (timeSeriesBand == null || timeSeriesBand === '') {
                if (dataset.bands.length > 0) {
                    additionalSelection.set(dataset.id, dataset.bands[0])
                }
            }
        }
    }
    return additionalSelection
}

export {
    datasetHasTimeSeries,
    fetchDatasetsInfo,
    getFilteredDatasets,
    getDefaultTimeSeriesBand,
    getMissingTimeSeriesBandSelections
}
