import { Dataset, DatasetType } from '../types/dataset'
import { ChatRequest, ChatResponse, HasReadAccessResponse } from '../types/chat'
import { GeoJsonCrs } from '../types/geojson'
import * as Sentry from '@sentry/react'
import { SupabaseClient } from '@supabase/supabase-js'
import { DatasetConfig } from 'types/datasetRegistration'
import { DatasetClass } from '../types/datasetRegistration'

export class BackendError extends Error {
    constructor(
        message: string,
        public readonly errorClass: string | undefined = undefined
    ) {
        super(message)
        this.name = 'BackendError'
    }
}

export class ExpiredAuthTokenError extends BackendError {
    constructor(message: string = 'Authentication token has expired') {
        super(message, 'ExpiredAuthTokenError')
        this.name = 'ExpiredAuthTokenError'
    }
}

export type DatasetRegistrationResponse = {
    dataset_id: string
    dataset_version_id: string
}

export type GetDatasetVersionResponse = {
    has_overviews: boolean
    overviews_processing_status: {
        status: 'pending' | 'running' | 'success' | 'error'
        started_at: string | null
        updated_at: string
        created_by_user: string | null
    } | null
    uncompressed_size_bytes: number | null
}

export class BackendClient {
    private readonly baseUrl: string
    private readonly client: SupabaseClient

    constructor(baseUrl: string, client: SupabaseClient) {
        this.baseUrl = baseUrl.replace(/\/+$/, '')
        this.client = client
    }

    async request<T>(path: string, options: RequestInit = {}): Promise<T> {
        const {
            data: { session },
        } = await this.client.auth.getSession()
        const accessToken = session?.access_token

        if (!accessToken) {
            throw new BackendError(
                'Not logged in, cannot make backend request.'
            )
        }

        try {
            const response = await fetch(`${this.baseUrl}${path}`, {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    ...options.headers,
                },
            })

            const data = await response.json()

            if (!response.ok) {
                if (response.status == 401 && data?.detail == 'Invalid token') {
                    Sentry.captureEvent({
                        message: 'Invalid token',
                        extra: {
                            response: data,
                        },
                        level: 'warning',
                    })
                    throw new ExpiredAuthTokenError()
                }
                const details = data?.detail?.message
                throw new BackendError(
                    details || 'An error occurred while making the request.',
                    data?.detail?.error_class
                )
            }
            return data
        } catch (error) {
            if (error instanceof ExpiredAuthTokenError) {
                await new Promise((resolve) => setTimeout(resolve, 1000))
                return this.request(path, options)
            }
            throw error
        }
    }

    async addDataset(
        url: string,
        name: string,
        datasetClass: DatasetClass,
        config: DatasetConfig
    ): Promise<DatasetRegistrationResponse> {
        return this.request('/datasets/add', {
            method: 'POST',
            body: JSON.stringify({ url, name, class: datasetClass, config }),
        })
    }

    async getDatasetVersion(
        datasetVersionId: string
    ): Promise<GetDatasetVersionResponse> {
        return this.request(`/dataset-versions/${datasetVersionId}`)
    }

    async startOverviewsGeneration(datasetVersionId: string): Promise<void> {
        return this.request(
            `/dataset-versions/${datasetVersionId}/start-overviews-generation`,
            {
                method: 'POST',
            }
        )
    }

    async geoJsonCrsToProj4(crs: GeoJsonCrs): Promise<string> {
        const response = (await this.request('/utils/geojson_crs_to_proj4', {
            method: 'POST',
            body: JSON.stringify({ crs: crs }),
        })) as { proj4: string }

        return response.proj4
    }

    async hasReadAccess(path: string): Promise<HasReadAccessResponse> {
        const response = (await this.request(
            `/ingest/has-read-access?path=${encodeURIComponent(path)}`
        )) as {
            has_read_access: boolean
            resolved_file_name: string
        }
        return {
            hasReadAccess: response.has_read_access,
            resolvedFileName: response.resolved_file_name,
        }
    }

    async chat(request: ChatRequest): Promise<ChatResponse> {
        const data = (await this.request('/chat', {
            method: 'POST',
            body: JSON.stringify({
                message: request.message,
                chat_id: request.chatId,
                added_dataset_ids: request.addedDatasetIds,
                upvoted_dataset_ids: request.upvotedDatasetIds,
                downvoted_dataset_ids: request.downvotedDatasetIds,
            }),
        })) as {
            dataset_ids: string[]
            explanations: string[]
            response_text: string
        }

        return {
            datasetIds: data.dataset_ids,
            explanations: data.explanations,
            responseText: data.response_text,
        }
    }
}
