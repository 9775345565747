import React from 'react'
import { Stack, Button, Box, Typography, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import SidebarStyle from './Sidebar.module.css'
import AddDatasetStepper from '../AddDatasetStepper/AddDatasetStepper'
import { Close } from '@mui/icons-material'

type DatasetAdderProps = {
    addButtonVariant: 'contained' | 'outlined',
    setDatasetAddDialogOpen: (open: boolean) => void,
    datasetAddDialogOpen: boolean,
    handleDatasetAddDialogClose: () => void,
    pastedDatasetUrl: string,
    viewMode: 'map' | 'catalog',
}

const DatasetAdder: React.FC<DatasetAdderProps> = ({
    addButtonVariant,
    setDatasetAddDialogOpen,
    datasetAddDialogOpen,
    handleDatasetAddDialogClose,
    pastedDatasetUrl,
    viewMode,
}) => {

    const specialKeySymbol = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'

    return (
        <>
            <Dialog
                open={datasetAddDialogOpen}
                onClose={handleDatasetAddDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction={'row'}>
                        <span>Register New Dataset</span>
                        <Box sx={{ flexGrow: '1' }} />
                        <IconButton onClick={handleDatasetAddDialogClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <AddDatasetStepper
                        initialUrl={pastedDatasetUrl}
                        onClose={handleDatasetAddDialogClose}
                        viewMode={viewMode}
                    />
                </DialogContent>
            </Dialog>
            <Stack
                direction={'column'}
                spacing={1}
                className={SidebarStyle.addButton}
            >
                <Button
                    variant={addButtonVariant}
                    fullWidth
                    onClick={() => setDatasetAddDialogOpen(true)}
                    id="addDatasetButton"
                >
                    Register New Dataset
                </Button>
                <Box className={SidebarStyle.addHelpText}>
                    <Typography variant={'body2'}>
                        Try {specialKeySymbol}+V or dragging a file onto
                        the map
                    </Typography>
                </Box>
            </Stack>
        </>
    )
}

export default DatasetAdder
