import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
} from '@mui/material'
import { BandPixelInfo } from '../../types/pixelInfo'
import './PixelInfoView.module.css'
import React from 'react'

export function BandPixelTable({ pixelInfo }: { pixelInfo: BandPixelInfo }) {
    return (
        <TableContainer>
            <Table
                sx={{
                    tableLayout: 'auto',
                }}
                size="small"
                aria-label="raster pixel info table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell >
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: 11,
                                        '@media (min-width:1100px)': {
                                            fontSize: 13,
                                        },
                                    },
                                    fontWeight: 1200,
                                }}
                            >
                                Band
                            </Typography>
                        </TableCell>
                        <TableCell align="right" >
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: 11,
                                        '@media (min-width:1100px)': {
                                            fontSize: 13,
                                        },
                                    },
                                    fontWeight: 1200,
                                }}
                            >
                                Value
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pixelInfo.dimension.map((dimension, index) => (
                        <TableRow
                            key={dimension}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: 10,
                                            '@media (min-width:1100px)': {
                                                fontSize: 12,
                                            },
                                        },
                                        wordBreak: 'break-all',
                                    }}
                                    lang="en"
                                >
                                    {dimension}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Tooltip title={
                                    typeof pixelInfo.value[index] === 'number'
                                        ? pixelInfo.value[index].toString()
                                        : pixelInfo.value[index]
                                }>
                                    <Typography
                                        variant="monospace"
                                        sx={{
                                            fontSize: {
                                                xs: 10,
                                                '@media (min-width:1100px)': {
                                                    fontSize: 12,
                                                },
                                            },
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {typeof pixelInfo.value[index] === 'number'
                                            ? Number.isInteger(pixelInfo.value[index])
                                                ? pixelInfo.value[index].toString()
                                                : Number(pixelInfo.value[index]).toFixed(4)
                                            : pixelInfo.value[index]}
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
