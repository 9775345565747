import { VectorVizParams } from '../../types/viz'
import { Dataset } from '../../types/dataset'
import { NoEncryption } from '@mui/icons-material'


export function dimensionsToString(dimensions: { [key: string]: number }) {
    return Object.entries(dimensions)
        .map(([key, value]) => `${key}:${value}`)
        .join('-')
}

export function layerID(dataset: Dataset, suffix?: string) {
    const baseId =
        dataset.type === 'vector'
            ? `dataset-${dataset.id}-vector-layer-${(dataset.vizParams as VectorVizParams).mode}`
            : `dataset-${dataset.id}-raster-layer${suffix ? `-${suffix}` : ''}`
    return baseId
}

export function sourceId(dataset: Dataset, suffix?: string) {
    const sourceId =
        dataset.type === 'vector'
            ? `dataset-${dataset.id}-vector-source${(dataset.vizParams as VectorVizParams).mode}`
            : `dataset-${dataset.id}-raster-source${suffix ? `-${suffix}` : ''}`
    return sourceId
}
