import {
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Tooltip,
} from '@mui/material'
import { Info } from '@mui/icons-material'
import { VectorPixelInfo } from '../../types/pixelInfo'
import React from 'react'
import './PixelInfoView.module.css'

const SHOW_COMPUTED_PROPERTIES =
    import.meta.env.EARTHSCALE_SHOW_COMPUTED_VECTOR_PROPERTIES === 'true'

export function VectorPixelTable({
    pixelInfo,
}: {
    pixelInfo: VectorPixelInfo
}) {
    const numFeatures = Object.values(pixelInfo.features)[0]?.length || 0

    const shouldShowLength = (geometryType: string | null | undefined) => {
        if (!SHOW_COMPUTED_PROPERTIES) return false
        if (!geometryType) return true
        return !geometryType.toLowerCase().includes('point')
    }

    const shouldShowArea = (geometryType: string | null | undefined) => {
        if (!SHOW_COMPUTED_PROPERTIES) return false
        if (!geometryType) return true
        return geometryType.toLowerCase().includes('polygon')
    }

    const getFilteredProperties = (featureIndex: number) => {
        const geometryType = pixelInfo.features.__earthscale_geometry_type?.[
            featureIndex
        ] as string | null
        return Object.entries(pixelInfo.features).filter(([key, values]) => {
            if (
                values[featureIndex] === null ||
                values[featureIndex] === undefined
            ) {
                return false
            }

            if (key.startsWith('__earthscale_')) {
                if (
                    key === '__earthscale_geometry_length' &&
                    !shouldShowLength(geometryType)
                )
                    return false
                if (
                    key === '__earthscale_geometry_area' &&
                    !shouldShowArea(geometryType)
                )
                    return false
                if (key === '__earthscale_geometry_type') return false
            }
            return true
        })
    }

    const getDisplayName = (
        key: string,
        geometryType: string | null | undefined
    ) => {
        if (key === '__earthscale_geometry_length') {
            return geometryType?.toLowerCase().includes('polygon')
                ? 'Perimeter'
                : 'Length'
        }
        if (key === '__earthscale_geometry_area') {
            return 'Area'
        }
        return key
    }

    const getTooltip = (key: string) => {
        if (key === '__earthscale_geometry_length') {
            return 'm in EPSG:3857'
        }
        if (key === '__earthscale_geometry_area') {
            return 'm² in EPSG:3857'
        }
        return undefined
    }

    return (
        <Box>
            {Array.from({ length: numFeatures }, (_, featureIndex) => (
                <Box key={featureIndex} sx={{ mb: 2 }}>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: 11,
                                '@media (min-width:1000px)': {
                                    fontSize: 13,
                                },
                            },
                            fontWeight: 600,
                            mb: 1,
                            ml: '10px',
                        }}
                    >
                        {`Feature ${featureIndex + 1}: ${pixelInfo.features.__earthscale_geometry_type?.[featureIndex] || ''}`}
                    </Typography>
                    <TableContainer className="table">
                        <Table
                            sx={{
                                minWidth: {
                                    xs: 150,
                                    '@media (min-width:1000px)': {
                                        minWidth: 250,
                                    },
                                },
                            }}
                            size="small"
                            aria-label="vector pixel info table"
                        >
                            <TableBody>
                                {getFilteredProperties(featureIndex).map(
                                    ([key, values]) => (
                                        <TableRow
                                            key={key}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                xs: 10,
                                                                '@media (min-width:1000px)':
                                                                    {
                                                                        fontSize: 12,
                                                                    },
                                                            },
                                                        }}
                                                    >
                                                        {getDisplayName(
                                                            key,
                                                            pixelInfo.features
                                                                .__earthscale_geometry_type?.[
                                                                featureIndex
                                                            ] as string | null
                                                        )}
                                                    </Typography>
                                                    {getTooltip(key) && (
                                                        <Tooltip
                                                            title={getTooltip(
                                                                key
                                                            )}
                                                        >
                                                            <Info
                                                                sx={{
                                                                    fontSize: 14,
                                                                    opacity: 0.7,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: 10,
                                                            '@media (min-width:1000px)':
                                                                {
                                                                    fontSize: 12,
                                                                },
                                                        },
                                                    }}
                                                >
                                                    {typeof values[
                                                        featureIndex
                                                    ] === 'number'
                                                        ? Number.isInteger(
                                                              values[
                                                                  featureIndex
                                                              ]
                                                          )
                                                            ? values[
                                                                  featureIndex
                                                              ].toString()
                                                            : Number(
                                                                  values[
                                                                      featureIndex
                                                                  ]
                                                              ).toFixed(4)
                                                        : values[featureIndex]}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}
        </Box>
    )
}
