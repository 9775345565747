import {
    convertDBDatasetToDataset,
    DBDataset,
    DBVizParams,
    insertVizParams,
} from './dataset'
import { Map as MapType, MapMetadata } from '../types/map'
import { SupabaseClient } from '@supabase/supabase-js'
import { Viz, VizType } from '../types/viz'
import { getLatestVizParamsForDatasetVersion } from './viz'
import { getOrgId } from './user'
import { v4 as uuid } from 'uuid'
import { Dataset } from '../types/dataset'

export async function createMap(
    supabaseClient: SupabaseClient
): Promise<string> {
    const orgId = await getOrgId(supabaseClient)
    const mapId = uuid()
    const { data, error } = await supabaseClient.from('maps').insert({
        id: mapId,
        name: `New map (${new Date().toLocaleString('en-us', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        })})`,
        org_id: orgId,
    })

    if (error) {
        throw error
    }
    return mapId
}

export async function deleteMapFromDatabase(
    supabaseClient: SupabaseClient,
    mapId: string
) {
    const { data, error } = await supabaseClient
        .from('maps')
        .delete()
        .eq('id', mapId)

    if (error) {
        throw error
    }
}

export async function fetchMapsMetadata(
    supabaseClient: SupabaseClient
): Promise<MapMetadata[]> {
    const { data, error } = await supabaseClient.from('maps').select('*')

    if (error) {
        throw error
    }

    return data
}

export async function fetchMapById(
    supabaseClient: SupabaseClient,
    id: string
): Promise<MapType> {
    const { data, error } = await supabaseClient
        .from('maps')
        .select(
            `
            *,
            map_entries(
                *,
                dataset_versions(
                    *,
                    cache_keys(raster_overviews(start_zoom)),
                    earth_engine_visualizations(*)
                ),
                viz_params(*)
            )
        `
        )
        .eq('id', id)
        .single()

    if (error) {
        throw error
    }

    // Sort map entries by layer_order
    data.map_entries.sort((a, b) => a.layer_order - b.layer_order)

    const datasets = (await Promise.all(
        data.map_entries.map(async (entry) => {
            // There's a 1:1 relationship between viz_params and map_entries, thus the type
            // casting
            const dbVizParams = entry.viz_params as DBVizParams
            let viz: Viz | null = null
            if (dbVizParams) {
                viz = {
                    id: dbVizParams.id,
                    vizType: dbVizParams.type,
                    vizParams: dbVizParams.params,
                }
            }
            return await convertDBDatasetToDataset(entry.dataset_versions, viz)
        })
    )) as Dataset[]

    // Find the latest versions for all the datasets
    const { data: latestDatasets, error: latestDatasetsError } =
        await supabaseClient
            .from('datasets_latest')
            .select('id,dataset_id')
            .in(
                'dataset_id',
                datasets.map((d) => d.datasetId)
            )

    if (latestDatasetsError) {
        throw latestDatasetsError
    }

    const latestDatasetsMap = new Map<string, string>()
    latestDatasets.forEach((d) => {
        latestDatasetsMap.set(d.dataset_id, d.id)
    })
    datasets.forEach((d) => {
        const latestVersion = latestDatasetsMap.get(d.datasetId)
        if (latestVersion && d.id !== latestVersion) {
            d.latestDatasetVersionId = latestVersion
        }
    })

    return {
        metadata: {
            id: data.id,
            name: data.name,
        },
        datasets: datasets,
    }
}

type _MapEntry = {
    id: string
    datasets: DBDataset
}

export async function addDatasetToMap(
    supabaseClient: any,
    mapId: string,
    datasetVersionId: string,
    layerOrder: number
) {
    const latestViz = await getLatestVizParamsForDatasetVersion(
        supabaseClient,
        datasetVersionId
    )
    const vizId = latestViz ? latestViz.id : null

    const { _, dataset_error } = await supabaseClient
        .from('map_entries')
        .insert({
            map_id: mapId,
            dataset_version_id: datasetVersionId,
            viz_params_id: vizId,
            layer_order: layerOrder,
        })

    if (dataset_error) {
        throw dataset_error
    }
}

export async function removeDatasetFromMap(
    supabaseClient: any,
    mapId: string,
    datasetVersionId: string
) {
    const { _, error } = await supabaseClient
        .from('map_entries')
        .delete()
        .eq('map_id', mapId)
        .eq('dataset_version_id', datasetVersionId)

    if (error) {
        throw error
    }
}

export async function updateVizParamsForMap(
    supabaseClient: any,
    mapId: string,
    datasetVersionId: string,
    datasetName: string,
    vizId: string,
    vizParams: any,
    vizType: VizType
) {
    await insertVizParams(
        supabaseClient,
        datasetVersionId,
        datasetName,
        vizId,
        vizType,
        vizParams
    )
    // Update the viz params in the map_entries table
    const { _, error } = await supabaseClient
        .from('map_entries')
        .update({
            viz_params_id: vizId,
        })
        .eq('map_id', mapId)
        .eq('dataset_version_id', datasetVersionId)

    if (error) {
        throw error
    }
}

export async function updateLayerOrderForMap(
    supabaseClient: SupabaseClient,
    mapId: string,
    reorderedEntries: {
        layerOrder: number
        datasetVersionId: string
    }[]
) {
    const updates = reorderedEntries.map((entry) => ({
        layer_order: entry.layerOrder,
        map_id: mapId,
        dataset_version_id: entry.datasetVersionId,
    }))

    const { data, error } = await supabaseClient
        .from('map_entries')
        .upsert(updates, { onConflict: 'map_id,dataset_version_id' })

    if (error) {
        throw error
    }

    return data
}
