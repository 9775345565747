import React, { useEffect, useState, useRef } from 'react'
import PixelInfoViewModule from './PixelInfoView.module.css'
import {
    Box,
    Divider,
    IconButton,
    Paper,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    Tooltip,
} from '@mui/material'
import { useMapContext } from '../../context/map/mapContext'
import { Dataset } from '../../types/dataset'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import Lottie from 'react-lottie-player'
import * as loadingSpinner from '../../assets/loadingSpinnner/white-on-transparent.json'
import { Close, Timeline, TableChartOutlined} from '@mui/icons-material'
import {
    PixelInfoResponse,
    VectorPixelInfo,
    TimePixelInfo,
    BandPixelInfo,
} from '../../types/pixelInfo'
import { VectorPixelTable } from './VectorPixelInfo'
import { BandPixelTable } from './BandPixelInfo'
import { TimeSeriesChart } from './TimeSeriesPixelInfo'
import { useActivityContext } from '../../context/activity/activityContext'
import { DimensionSelections, BandSelector } from './Selectors'
import {
    datasetHasTimeSeries,
    fetchDatasetsInfo,
    getDefaultTimeSeriesBand,
    getFilteredDatasets,
    getMissingTimeSeriesBandSelections
} from './utils'


function PixelInfoResultsView({
    data,
    timeSeriesBand,
    isTimeSeries,
}: {
    data: PixelInfoResponse | null
    timeSeriesBand?: string
    isTimeSeries: boolean
}) {
    if (data == null) {
        return (
            <Typography
                variant="body1"
                className={PixelInfoViewModule.content}
            >
                No data found for this pixel.
            </Typography>
        )
    }

    if (data.type === 'vector') {
        return <VectorPixelTable pixelInfo={data as VectorPixelInfo} />
    }
    if (data.type === 'band') {
        return <BandPixelTable pixelInfo={data as BandPixelInfo} />
    }
    if (data.type === 'time' && isTimeSeries) {
        return (
            <TimeSeriesChart
                pixelInfo={data as TimePixelInfo}
                band={timeSeriesBand}
            />
        )
    }
    return null
}

const ShowAllInfoButton = ({
    visibleInfo,
    setVisibleInfo,
    clickedDatasets,
    specifier = "",
}: {
    visibleInfo: {[datasetId: string]: boolean}
    setVisibleInfo: (visibleInfo: {[datasetId: string]: boolean}) => void
    clickedDatasets: Dataset[]
    specifier?: string
}) => {
    return Object.values(visibleInfo).every(v => v === true) &&
        Object.keys(visibleInfo).length !== 0 ? (
           <Typography
               variant="caption"
               className={PixelInfoViewModule.showAllInfoButton}
               onClick={() => {
                   const newVisibility = Object.fromEntries(
                       clickedDatasets.map(dataset => [dataset.id, false])
                   );
                   setVisibleInfo(newVisibility);
               }}
           >
               Hide all {specifier}
           </Typography>
       ) : (
           <Typography
               variant="caption"
               className={PixelInfoViewModule.showAllInfoButton}
               onClick={() => {
                   const newVisibility = Object.fromEntries(
                       clickedDatasets.map(dataset => [dataset.id, true])
                   );
                   setVisibleInfo(newVisibility);
               }}
           >
               Show all {specifier}
           </Typography>
       )
    }

const PixelInfoView = ({
    setActiveDetailPanel,
}: {
    setActiveDetailPanel: (
        panel: 'dataset' | 'pixel' | 'comment' | 'newThread'
    ) => void
}) => {
    const { state: mapState, dispatch } = useMapContext()
    const { client } = useSupabaseContext()
    const { trackGetPixelInfo } = useActivityContext()

    const [allBandsPixelInfo, setAllBandsPixelInfo] = useState<{
        [datasetId: string]: {
            loading: boolean
            data: PixelInfoResponse | null
        }
    }>({})

    const [timeSeriesPixelInfo, setTimeSeriesPixelInfo] = useState<{
        [datasetId: string]: {
            loading: boolean
            data: PixelInfoResponse | null
            band: string
        }
    }>({})

    const [visibleInfo, setVisibleInfo] = useState<{[datasetId: string]: boolean}>({})
    const [visibleTimeSeries, setVisibleTimeSeries] = useState<{[datasetId: string]: boolean}>({})

    const setVisibleTimeSeriesWithTracking = (
        updater: React.SetStateAction<{ [datasetId: string]: boolean }>
    ) => {
        setVisibleTimeSeries(updater)
        trackGetPixelInfo(true)
    }

    const setVisibleInfoWithTracking = (
        updater: React.SetStateAction<{ [datasetId: string]: boolean }>
    ) => {
        setVisibleInfo(updater)
        trackGetPixelInfo(false)
    }

    const [clickedDatasets, setClickedDatasets] = useState<Dataset[]>([])

    const [selectedBands, setSelectedBands] = useState<Map<string, string>>(
        () =>
            new Map(
                clickedDatasets
                    .filter(datasetHasTimeSeries)
                    .map((dataset) => [
                        dataset.id,
                        getDefaultTimeSeriesBand(dataset) ?? '',
                    ])
            )
    )

    const hasSetInitialVisibility = useRef(false);

    // this sets the datasets to fetch and the selected bands
    useEffect(() => {
        if (!mapState.clickedLngLat) return

        const coords = {
            lng: mapState.clickedLngLat[0],
            lat: mapState.clickedLngLat[1],
        }

        const datasets = getFilteredDatasets(coords, mapState)
        const missingTimeSeriesBandSelections =
            getMissingTimeSeriesBandSelections(datasets, selectedBands)
        setSelectedBands(
            new Map([...selectedBands, ...missingTimeSeriesBandSelections])
        )
        setClickedDatasets(datasets)

    }, [mapState.clickedLngLat, mapState.catalog, mapState.current.datasets, mapState.viewMode])

    // Clear the pixel info when the clicked location changes
    useEffect(() => {
        setAllBandsPixelInfo({})
        setTimeSeriesPixelInfo({})
    }, [mapState.clickedLngLat])

    // Fetch new data when the datasets or selected bands change
    useEffect(() => {
        if (clickedDatasets.length === 0) return

        const datasetsToFetch = clickedDatasets.filter(
            (dataset) =>
                (!allBandsPixelInfo[dataset.id] &&
                visibleInfo[dataset.id] === true)
        )

        const timeSeriesDatasetsToFetch = clickedDatasets.filter(
            (dataset) => {
                const id = dataset.id
                return (
                    datasetHasTimeSeries(dataset) &&
                    selectedBands.has(id) &&
                    visibleTimeSeries[id] &&
                    (!timeSeriesPixelInfo[id] ||
                        timeSeriesPixelInfo[id].band !== selectedBands.get(id))
                )
            }
        )

        if (datasetsToFetch.length === 0 && timeSeriesDatasetsToFetch.length === 0) return

        const initLoadingState = Object.fromEntries(
            datasetsToFetch.map((d) => [
                d.id,
                {
                    loading: true,
                    data: null,
                    band: selectedBands.get(d.id) || null,
                },
            ])
        )

        const timeSeriesInitLoadingState = Object.fromEntries(
            timeSeriesDatasetsToFetch.map((d) => [
                d.id,
                {
                    loading: true,
                    data: null,
                    band: selectedBands.get(d.id) || null,
                },
            ])
        )

        // Update only the datasets that are being fetched
        setAllBandsPixelInfo((prev) => ({ ...prev, ...initLoadingState }))
        setTimeSeriesPixelInfo((prev) => ({ ...prev, ...timeSeriesInitLoadingState }))

        const fetchBandsData = async () => {
            const processedResults = await fetchDatasetsInfo(
                datasetsToFetch, false, selectedBands, mapState, client
            )
            setAllBandsPixelInfo((prev) => ({ ...prev, ...processedResults }))
        }
        const fetchTimeSeriesData = async () => {
            const processedResults = await fetchDatasetsInfo(
                timeSeriesDatasetsToFetch, true, selectedBands, mapState, client
            )
            setTimeSeriesPixelInfo((prev) => ({ ...prev, ...processedResults }))
        }

        fetchBandsData()
        fetchTimeSeriesData()
    }, [selectedBands, clickedDatasets, mapState.clickedLngLat, visibleInfo, visibleTimeSeries])

    useEffect(() => {
        if (clickedDatasets.length > 0 && !hasSetInitialVisibility.current) {
            setVisibleInfoWithTracking(prev => {
                const newVisibility = { ...prev };
                clickedDatasets.forEach(dataset => {
                    if (dataset.isVisible) {
                        newVisibility[dataset.id] = true;
                    }
                });
                return newVisibility;
            });

            hasSetInitialVisibility.current = true;
        }
    }, [clickedDatasets]);

    const getToggleValue = (datasetId: string) => {
        const values = []
        if (visibleInfo[datasetId] === true) {
            values.push('pixel')
        }
        if (visibleTimeSeries[datasetId] === true) {
            values.push('timeseries')
        }
        return values
    }

    const handleDisplayToggle = (datasetId: string, newValues: string[]) => {

        setVisibleInfoWithTracking(prev => {
            return {
                ...prev,
                [datasetId]: newValues.includes('pixel')
            };
        });

        setVisibleTimeSeriesWithTracking(prev => {
            return {
                ...prev,
                [datasetId]: newValues.includes('timeseries')
            };
        });
    }

    if (!mapState.clickedLngLat) return null

    return (
        <Paper className={PixelInfoViewModule.pixelInfoView}>
            <Box className={PixelInfoViewModule.header}>
                <Typography variant="h5">Pixel Information</Typography>
                <IconButton
                    onClick={() => {
                        setActiveDetailPanel(null)
                        dispatch({ type: 'SET_CLICKED_LAT_LNG', lngLat: null })
                    }}
                >
                    <Close fontSize="large" />
                </IconButton>
            </Box>
            <Divider sx={{ mb: '16px' }} />

            <Typography
                variant="h5"
                sx={{ px: 1, mb: 0 }}
            >
                {mapState.viewMode === 'map'
                    ? 'Map Datasets'
                    : 'Visible Catalog Datasets'}
            </Typography>
            <Typography variant="body2" className={PixelInfoViewModule.content} sx={{ mb: 2 }}>
                Intersecting ({mapState.clickedLngLat[0].toFixed(6)}, {mapState.clickedLngLat[1].toFixed(6)})
            </Typography>

            <Divider sx={{ mb: 1 }} />

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, px: 1, mb: 0 }}>
                {clickedDatasets.length > 0 && (
                    <ShowAllInfoButton
                        visibleInfo={visibleInfo}
                        setVisibleInfo={setVisibleInfo}
                        clickedDatasets={clickedDatasets}
                    />
                )}

                {clickedDatasets
                    .filter(datasetHasTimeSeries)
                    .length > 0 &&
                    <ShowAllInfoButton
                        visibleInfo={visibleTimeSeries}
                        setVisibleInfo={setVisibleTimeSeriesWithTracking}
                        clickedDatasets={clickedDatasets}
                        specifier="time series"
                    />
                }
            </Box>

            {clickedDatasets.length > 0 ? (
                <Box className={PixelInfoViewModule.infoTables}>
                    {clickedDatasets.map((dataset) => {
                        const isLoading = allBandsPixelInfo[dataset.id]?.loading
                        const data = allBandsPixelInfo[dataset.id]?.data
                        const isTimeSeriesLoading = timeSeriesPixelInfo[dataset.id]?.loading
                        const timeSeriesData = timeSeriesPixelInfo[dataset.id]?.data
                        const isTimeSeries = datasetHasTimeSeries(dataset)
                        const timeSeriesBand = selectedBands.get(dataset.id)
                        const isResultVisible = visibleInfo[dataset.id] === true
                        const isTimeSeriesResultVisible = visibleTimeSeries[dataset.id]
                        const color = isResultVisible ?  '#FFFFFF' : '#AAAAAA'

                        return (
                            <div key={dataset.id}>
                                <Box className={PixelInfoViewModule.datasetContainer}>
                                    <Typography
                                        className={`${PixelInfoViewModule.datasetName} ${PixelInfoViewModule.small}`}
                                        style={{ color: color }}
                                    >
                                        {dataset.name}
                                    </Typography>

                                    <Box className={PixelInfoViewModule.controlsContainer}>
                                        <ToggleButtonGroup
                                            value={getToggleValue(dataset.id)}
                                            onChange={(_, newValues) => handleDisplayToggle(dataset.id, newValues)}
                                            aria-label="display options"
                                            size="small"
                                            className={PixelInfoViewModule.toggleButtonGroup}
                                        >
                                            {isTimeSeries && (
                                                <Tooltip title="Show time series data">
                                                    <ToggleButton value="timeseries" aria-label="time series">
                                                        <Timeline fontSize="small" />
                                                    </ToggleButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Show all bands">
                                                <ToggleButton value="pixel" aria-label="pixel info">
                                                    <TableChartOutlined fontSize="small" />
                                                </ToggleButton>
                                            </Tooltip>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                                {(isResultVisible || isTimeSeriesResultVisible) && (
                                    <DimensionSelections dataset={dataset} />
                                )}
                                {isResultVisible && (
                                    isLoading ? (
                                        <Lottie
                                            loop
                                            animationData={loadingSpinner}
                                            play
                                            style={{
                                                width: 40,
                                                height: 40,
                                            }}
                                        />
                                    ) : (
                                        <PixelInfoResultsView
                                            data={data}
                                            timeSeriesBand={null}
                                            isTimeSeries={false}
                                        />
                                    )
                                )}
                                {isTimeSeriesResultVisible && (
                                    <>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                fontWeight: 600,
                                                marginLeft: '12px',
                                            }}
                                        >
                                            Time Series
                                        </Typography>

                                        {timeSeriesBand != null && (
                                            <BandSelector
                                                band={timeSeriesBand}
                                                dataset={dataset}
                                                onBandChange={(band) => {
                                                    const newSelection = new Map(
                                                        selectedBands
                                                    )
                                                    newSelection.set(dataset.id, band)
                                                    setSelectedBands(newSelection)
                                                }}
                                            />
                                        )}

                                        {isTimeSeriesLoading ? (
                                            <Lottie
                                                loop
                                                animationData={loadingSpinner}
                                                play
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                }}
                                            />
                                        ) : (
                                            <PixelInfoResultsView
                                                data={timeSeriesData}
                                                timeSeriesBand={timeSeriesBand}
                                                isTimeSeries={true}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        )
                    })}
                </Box>
            ) : (
                <Typography
                    variant="body1"
                    className={PixelInfoViewModule.content}
                >
                    {mapState.viewMode === 'map' ? (
                        <>
                            Click a feature or pixel within the bounds of a map
                            dataset to view pixel details. At the moment, we don't
                            support pixel details for local datasets.
                        </>
                    ) : (
                        <>
                            Click a feature or pixel within the bounds of a visible
                            dataset in the catalog to view pixel details.
                        </>
                    )}
                </Typography>
            )}
        </Paper>
    )
}

export default PixelInfoView
